/**
 * Created by Karen_Yeh on 2017/4/12.
 */
module.exports = {
    NOTIFICATION_TIMEOUT: 5000,
    URL_ENCODE_BREAK_LENGTH: 80,
    AJMylzk7x5: "cppCPPKevinandArthur",
    fvBJwxOOqB: "sha512",
    UVQeG6bGXU: "eQ53nnR904",
    URL_PREFIX: "",
    API_HOST: '',
    CPP_WEBPLAYER_SID: 'e45d7c8b',
    localStorage: {
        profileData: '_clt_pl.udk'
    }
};
