import {encode, decode, generateUUID} from "../../utils/dataUtils";

const STORAGE_KEY = {
    token: "_pct",
    tokenType: "_pctt",
    userName: "_pcn",
    lang: "_pcl",
    serverUrl: "_pcsu",
    serverName: "_pcsn",
    serverType: "_pcst",
    serverVersion: "_pcsv",
    uuid: "_pcu",
    lastTerms: "_pcgl",
    closeFooter: "_pcgc",
    loginType: "_pclt",
};

class SessionService {
    generateInfo(data) {
        if(data.hasOwnProperty("access_token")) {
            localStorage.setItem(STORAGE_KEY.token, encode(data.access_token));
            localStorage.setItem(STORAGE_KEY.tokenType, encode(data.token_type));
        }
        if(data.hasOwnProperty("user")) {
            localStorage.setItem(STORAGE_KEY.userName, encode(data.user.firstname + (data.user.lastname && ` ${data.user.lastname}`) ));
            localStorage.setItem(STORAGE_KEY.lang, encode(data.user.locale));
        }
        if(data.hasOwnProperty("account")) {
            localStorage.setItem(STORAGE_KEY.account, encode(data.account));
        }
        if(data.hasOwnProperty("serverUrl")) {
            localStorage.setItem(STORAGE_KEY.serverUrl, encode(data.serverUrl));
        }
        if(data.hasOwnProperty("serverName")) {
            localStorage.setItem(STORAGE_KEY.serverName, encode(data.serverName));
        }
        if(data.hasOwnProperty("serverType")) {
            localStorage.setItem(STORAGE_KEY.serverType, encode(data.serverType));
        }
        // unDestroyed
        if(data.hasOwnProperty("closeFooter")) {
            localStorage.setItem(STORAGE_KEY.closeFooter, encode(data.closeFooter));
        }
        if(data.hasOwnProperty("lang")) {
            localStorage.setItem(STORAGE_KEY.lang, encode(data.lang));
        }
        if(data.hasOwnProperty("lastTerms")) {
            localStorage.setItem(STORAGE_KEY.lastTerms, encode(data.lastTerms));
        }
        if(data.hasOwnProperty("serverVersion")) {  // && data.serverVersion
            localStorage.setItem(STORAGE_KEY.serverVersion, encode(data.serverVersion));
        }
        if(data.hasOwnProperty("loginType")) {
            localStorage.setItem(STORAGE_KEY.loginType, encode(data.loginType));
        }
    }

    getOriginalInfo() {
        return {
            user: {
                firstname: this.getName(),
                locale: this.getLang()
            },
            access_token: this.getToken(),
            token_type: this.getTokenType(),
            serverUrl: this.getServerUrl(),
            serverName: decode(localStorage.getItem(STORAGE_KEY.serverName)),
            serverType: this.getServerType()
        };
    }

    getUnDestroyedInfo(){
        return {
            closeFooter: this.getCloseFooter(),
            lastTerms: this.getLastTerms(),
            lang: this.getLang(),
        }
    }

    getServerUrl() {
        return decode(localStorage.getItem(STORAGE_KEY.serverUrl));
    }

    getServerType() {
        return decode(localStorage.getItem(STORAGE_KEY.serverType));
    }

    getServerVersion() {
        return decode(localStorage.getItem(STORAGE_KEY.serverVersion));
    }

    getName() {
        return decode(localStorage.getItem(STORAGE_KEY.userName));
    }

    getLang() {
        return decode(localStorage.getItem(STORAGE_KEY.lang));
    }

    getToken() {
        return decode(localStorage.getItem(STORAGE_KEY.token));
    }

    getTokenType() {
        return decode(localStorage.getItem(STORAGE_KEY.tokenType));
    }

    getCloseFooter() {
        return decode(localStorage.getItem(STORAGE_KEY.closeFooter));
    }

    getLastTerms() {
        return decode(localStorage.getItem(STORAGE_KEY.lastTerms));
    }

    getLoginType() {
        return decode(localStorage.getItem(STORAGE_KEY.loginType));
    }

    getUUID() {
        let u = localStorage.getItem(STORAGE_KEY.uuid);
        if (u != null) {
            return u;
        }
        u = generateUUID();
        localStorage.setItem(STORAGE_KEY.uuid, u);
        return u;
    }

    // ---------
    destroy() {
        const closeFooter = this.getCloseFooter();
        const lastTerms = this.getLastTerms();
        const lang = this.getLang();
        localStorage.clear();
        this.generateInfo({ closeFooter, lastTerms, lang })
    }

    destroyServerData(){
        localStorage.removeItem(STORAGE_KEY.serverName);
        localStorage.removeItem(STORAGE_KEY.serverType);
        localStorage.removeItem(STORAGE_KEY.serverUrl);
        localStorage.removeItem(STORAGE_KEY.serverVersion);
        localStorage.removeItem(STORAGE_KEY.loginType);
    }
}

const sessionService = new SessionService();

export default sessionService;
