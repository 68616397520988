/**
 * Created by Jeffy_Chuang on 2017/7/24.
 */
import globals from './globals';

export default {
    ...globals,
    MEMBER_API_HOST: 'https://powerplayer-api.cyberlink.com',
    DATA_API_HOST: 'https://api.powercloud.cyberlink.com',
    CL_MEMBER_DOMAIN: 'https://membership.cyberlink.com',
    CL_DOMAIN: 'https://www.cyberlink.com/',
    CPP_DOMAIN: 'https://powerplayer.cyberlink.com',
    SHARE_CPP_DOMAIN: 'https://share.cyberlink.com',
    CSE_DOMAIN:"https://cse.cyberlink.com",
    LAUNCH_URI: {
        desktop: {share: "clpdvdcloudsl://cyberlink.com/pdvd/share/"}
    },
    AP_LOCATION: {
        android: "https://www.cyberlink.com/qr/cpp1-appstore",
        iOS: "https://www.cyberlink.com/qr/cpp1-appstore",
    },
    REACT_APP_CL_DOMAIN:"https://www.cyberlink.com",
    REACT_APP_MEMBERSHIP_DOMAIN:"https://membership.cyberlink.com",
    enableDebug: false
};
