import {combineReducers, applyMiddleware, createStore, compose} from 'redux';
import thunk from "redux-thunk";
import user from "../../services/user/userReducer";
import pincode from "../../services/data/pincodeReducer";
import music from "../../services/data/musicReducer";
import sideBar from "../../services/data/sideBarReducer";
import share from "../../services/data/shareReducer";
import deleteInfo from "../../services/data/deleteReducer";

let store;
store = createStore(
    combineReducers({
        user,
        pincode,
        music,
        sideBar,
        share,
        deleteInfo
    }),
    compose(
        applyMiddleware(
            thunk
        )
    )
);

export default store;
