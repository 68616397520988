import {API_TYPE} from "../../utils/constants";

export const USER_ACTIONS = {
    CHANGE_API_TYPE: `USER_CHANGE_API_TYPE`,
    CONNECTION_ERROR: 'CONNECTION_ERROR',
    SET_INIT_USER_DATA: 'SET_INIT_USER_DATA',
    SET_CLOUD_STATUS: 'SET_CLOUD_STATUS',
    SET_SOCIAL_TOKEN: "SET_SOCIAL_TOKEN",
    SET_SSI_DOMAIN: "SET_SSI_DOMAIN",
    SET_CL_TOKEN: "SET_CL_TOKEN",
    RESET_SSI: "RESET_SSI"
};

const initialState = {
    apiType: API_TYPE.UNKNOWN,
    connectionError: false,
    initUserData: undefined,
    cloudStatus: {},
    socialToken: undefined,
    ssiDomain: undefined,
    clToken: undefined,
};
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case USER_ACTIONS.CHANGE_API_TYPE:
            return {
                ...state,
                apiType: action.newType,
            };
        case USER_ACTIONS.CONNECTION_ERROR:
            return {
                ...state,
                connectionError: action.connectionError
            };
        case USER_ACTIONS.SET_INIT_USER_DATA:
            return {
                ...state,
                initUserData: action.initUserData
            };
        case USER_ACTIONS.SET_CLOUD_STATUS:
            return {
                ...state,
                cloudStatus: action.status
            };
        case USER_ACTIONS.SET_SOCIAL_TOKEN:
            return {
                ...state,
                socialToken: action.socialToken
            };
        case USER_ACTIONS.SET_SSI_DOMAIN:
            return {
                ...state,
                ssiDomain: action.ssiDomain
            };
        case USER_ACTIONS.SET_CL_TOKEN:
            return {
                ...state,
                clToken: action.clToken
            };
        case USER_ACTIONS.RESET_SSI:
            return {
                ...state,
                socialToken: action.undefined,
                ssiDomain: action.undefined,
                clToken: action.undefined
            };
        default:
            return state;
    }
}
