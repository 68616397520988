import React from 'react';
import Loadable from "react-loadable";
import Loading from "../../common/Loading"
import config from "config";
import {MUSIC_PLAYER_TYPE, MEDIA_TYPE} from "../../utils/constants";

const pageConfig = [
    {
        key: "selectSetToken",
        link: `${config.URL_PREFIX}/select/:info`,
        regex: `${config.URL_PREFIX}/select/.*`,
        config: {
            requireSignin: false,
            protocal: {https: true, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.DISABLE
        },
        component: Loadable({
            loader: () => import('../list/SetToken'),
            loading: Loading,
        }),
    },
    {
        key: "aesSelectSetToken",
        link: `${config.URL_PREFIX}/aesselect/:info`,
        regex: `${config.URL_PREFIX}/aesselect/.*`,
        config: {
            requireSignin: false,
            protocal: {https: true, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.DISABLE
        },
        component: Loadable({
            loader: () => import('../list/SetToken'),
            loading: Loading,
        }),
    },
    {
        key: "select",
        link: `${config.URL_PREFIX}/select`,
        regex: `${config.URL_PREFIX}/select`,
        config: {
            requireSignin: false,  // check signin in list component
            protocal: {https: false, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.DISABLE
        },
        component: Loadable({
            loader: () => import('../list/List'),
            loading: Loading,
        }),
    },
    {
        key: "homeSetToken",
        link: `${config.URL_PREFIX}/home/:info`,
        regex: `${config.URL_PREFIX}/home/.*`,
        config: {
            requireSignin: false,
            protocal: {https: true, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.DISABLE
        },
        component: Loadable({
            loader: () => import('../list/SetToken'),
            loading: Loading,
        }),
    },
    {
        key: "home",
        link: `${config.URL_PREFIX}/home`,
        regex: `${config.URL_PREFIX}/home`,
        config: {
            requireSignin: true,
            protocal: {https: true, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {
                title: {string: "Home"},
            },
            sidebar: {
                // hide: true
                title: {key: "general.home-pre"},
                icon: "btn_home",
                activatePattern: "home",
                mobile: true,
            }
        },
        component: Loadable({
            loader: () => import('../home/Home'),
            loading: Loading,
        }),
    },
    {
        key: "movie",
        link: `${config.URL_PREFIX}/movies`,
        regex: `${config.URL_PREFIX}/movies`,
        config: {
            requireSignin: true,
            protocal: {https: true, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {},
            sidebar: {
                title: {key: "general.movies"},
                icon: "icon_movies",
                activatePattern: MEDIA_TYPE.Movie,
                checkPDVD: true
            }
        },
        component: Loadable({
            // loader: () => import('./movies/MovieCollection'),
            loader: () => import('../../components/ContentCollection'),
            loading: Loading,
        }),
    },
    {
        key: "tvshows",
        link: `${config.URL_PREFIX}/tvshows`,
        regex: `${config.URL_PREFIX}/tvshows`,
        config: {
            requireSignin: true,
            protocal: {https: true, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {},
            sidebar: {
                title: {key: "general.tv-shows"},
                icon: "icon_TV",
                activatePattern: MEDIA_TYPE.TV,
                checkPDVD: true
            }
        },
        component: Loadable({
            // loader: () => import('./tvshows/TvCollection'),
            loader: () => import('../../components/ContentCollection'),
            loading: Loading
        }),
    },
    {
        key: "video",
        link: `${config.URL_PREFIX}/videos`,
        regex: `${config.URL_PREFIX}/videos`,
        config: {
            requireSignin: true,
            protocal: {https: true, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {
                title: {string: "Video"},
            },
            sidebar: {
                title: {key: "general.videos"},
                icon: "icon_video",
                activatePattern: MEDIA_TYPE.Video
            }
        },
        component: Loadable({
            // loader: () => import('./videos/VideoCollection'),
            loader: () => import('../../components/ContentCollection'),
            loading: Loading,
        }),
    },
    {
        key: "photo",
        link: `${config.URL_PREFIX}/photos`,
        regex: `${config.URL_PREFIX}/photos`,
        config: {
            requireSignin: true,
            protocal: {https: true, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {
                title: {string: "Photo"},
            },
            sidebar: {
                title: {key: "general.photos"},
                icon: "icon_photo",
                activatePattern: MEDIA_TYPE.Photo
            }
        },
        component: Loadable({
            // loader: () => import('./photos/PhotoCollection'),
            loader: () => import('../../components/ContentCollection'),
            loading: Loading,
        }),
    },
    {
        key: "photoPlay",
        link: `${config.URL_PREFIX}/play/PDVD/Photo/`,
        regex: `${config.URL_PREFIX}/play/PDVD/Photo/`,
        config: {
            requireSignin: true,
            protocal: {https: true, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.BACKGROUND,
        },
        component: Loadable({
            loader: () => import('../photos/PhotoGallery'),
            loading: Loading,
        }),
    },
    {
        key: "photoList",
        link: `${config.URL_PREFIX}/PDVD/Photo/`,
        regex: `${config.URL_PREFIX}/PDVD/Photo/`,
        config: {
            requireSignin: true,
            protocal: {https: true, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {
                title: {string: "Photo"},
            },
            sidebar: {
                hide: true,
            }
        },
        component: Loadable({
            loader: () => import('../photos/PhotoGrid'),
            loading: Loading,
        }),
    },
    {
        key: "music",
        link: `${config.URL_PREFIX}/music`,
        regex: `${config.URL_PREFIX}/music`,
        config: {
            requireSignin: true,
            protocal: {https: true, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {
                title: {string: "Music"},
            },
            sidebar: {
                title: {key: "general.music"},
                icon: "icon_music",
                activatePattern: MEDIA_TYPE.Music
            }
        },
        component: Loadable({
            // loader: () => import('./musics/MusicCollection'),
            loader: () => import('../../components/ContentCollection'),
            loading: Loading,
        }),
    },
    {
        key: "musicAlbumMedia",
        link: [`${config.URL_PREFIX}/PDVD/Music/Album/`, `${config.URL_PREFIX}/PDVD/Music/All/`],
        regex: [`${config.URL_PREFIX}/PDVD/Music/Album/`, `${config.URL_PREFIX}/PDVD/Music/All/`],
        config: {
            requireSignin: true,
            protocal: {https: false, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {
                title: {string: "Music"},
            },
            sidebar: {
                hide: true
            }
        },
        component: Loadable({
            loader: () => import('../musics/MusicDetail'),
            loading: Loading,
        }),
    },
    {
        key: "musicAlbumCloud",
        link: [`${config.URL_PREFIX}/PDVD/Music/:artist/:album/:song`, `${config.URL_PREFIX}/PDVD/Music/:artist/:album`],
        regex: [`${config.URL_PREFIX}/PDVD/Music/.*/.*/.*`, `${config.URL_PREFIX}/PDVD/Music/.*/.*`],
        config: {
            requireSignin: true,
            protocal: {https: true, http: false},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {
                title: {string: "Music"},
            },
            sidebar: {
                hide: true
            }
        },
        component: Loadable({
            loader: () => import('../musics/MusicDetail'),
            loading: Loading,
        }),
    },
    {
        key: "template",
        link: `${config.URL_PREFIX}/templates`,
        regex: `${config.URL_PREFIX}/templates`,
        config: {
            requireSignin: true,
            protocal: {https: true, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {
                title: {string: "Templates"},
            },
            sidebar: {
                title: {key: "general.templates", string: "Templates"},
                icon: "icon_movies",
                activatePattern: "templates"
            }
        },
        component: Loadable({
            // loader: () => import('./musics/MusicCollection'),
            loader: () => import('../../components/ContentCollection'),
            loading: Loading,
        }),
    },
    {
        key: "projects",
        link: `${config.URL_PREFIX}/projects`,
        regex: `${config.URL_PREFIX}/projects`,
        config: {
            requireSignin: true,
            protocal: {https: true, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {
                title: {string: "Projects"},
            },
            sidebar: {
                title: {key: "general.projects", string: "Projects"},
                icon: "icon_TV",
                activatePattern: "projects"
            }
        },
        component: Loadable({
            // loader: () => import('./musics/MusicCollection'),
            loader: () => import('../../components/ContentCollection'),
            loading: Loading,
        }),
    },
    {
        key: "sso",
        link: `${config.URL_PREFIX}/sso/:token`,
        regex: `${config.URL_PREFIX}/sso/*`,
        config: {
            requireSignin: false,
            protocal: {https: true, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.DISABLE,
        },
        component: Loadable({loader: () => import('../user/SSOSignin'), loading: Loading})
    },
    {
        key: "sharePlayGallery",
        link: [`${config.URL_PREFIX}/pdvd/share/gallery/:sharedId/:revisionId`,
            `${config.URL_PREFIX}/pdvd/share/gallery/:sharedId/`],
        regex: `${config.URL_PREFIX}/pdvd/share/gallery/`,
        config: {
            unBlockMobile: true,
            requireSignin: false,
            protocal: {https: true, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.DISABLE,
        },
        component: Loadable({
            loader: () => import('../photos/PhotoGallery'),
            loading: Loading,
        }),
    },
    {
        key: "sharePlay",
        link: [`${config.URL_PREFIX}/play/pdvd/share/:sharedId/:revisionId/:episodeNumber`,
            `${config.URL_PREFIX}/play/pdvd/share/:sharedId/:revisionId`, `${config.URL_PREFIX}/play/pdvd/share/:sharedId`],
        regex: [`${config.URL_PREFIX}/play/pdvd/share/.*/.*/.*`, `${config.URL_PREFIX}/play/pdvd/share/.*`,
            `${config.URL_PREFIX}/play/pdvd/share/.*/.*`],
        config: {
            unBlockMobile: true,
            requireSignin: false,
            protocal: {https: true, http: false},
            musicPlayer: MUSIC_PLAYER_TYPE.DISABLE,
        },
        component: Loadable({
            loader: () => import('../share/SharePlay'),
            loading: Loading,
        }),
    },
    {
        key: "shareContent",
        link: `${config.URL_PREFIX}/pdvd/share/:sharedId/:revisionId`,
        regex: `${config.URL_PREFIX}/pdvd/share/.*/.*`,
        config: {
            unBlockMobile: true,
            requireSignin: false,
            protocal: {https: true, http: false},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
        },
        component: Loadable({
            loader: () => import('../share/ShareContent'),
            loading: Loading,
        }),
    },
    {
        key: "share",
        link: `${config.URL_PREFIX}/pdvd/share/:sharedId`,
        regex: `${config.URL_PREFIX}/pdvd/share/.*`,
        config: {
            unBlockMobile: true,
            requireSignin: false,
            protocal: {https: true, http: false},
            musicPlayer: MUSIC_PLAYER_TYPE.DISABLE,
        },
        component: Loadable({
            loader: () => import('../share/Share'),
            loading: Loading,
        }),
    },
    {
        key: "play",
        link: `${config.URL_PREFIX}/play/PDVD/:mediaType/:path`,
        regex: `${config.URL_PREFIX}/play/PDVD/.*/.*`,
        config: {
            requireSignin: true,
            protocal: {https: true, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.DISABLE,
        },
        component: Loadable({
            loader: () => import('../play/Play'),
            loading: Loading,
        }),
    },
    {
        key: "search",
        link: `${config.URL_PREFIX}/PDVD/Search/:items`,
        regex: `${config.URL_PREFIX}/PDVD/Search/.*`,
        config: {
            requireSignin: true,
            protocal: {https: true, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {
                title: {string: "Search"},
            },
            sidebar: {
                hide: true
            }
        },
        component: Loadable({
            loader: () => import('../media/Search'),
            loading: Loading,
        }),
    },
    {
        key: "PDVD",
        link: [`${config.URL_PREFIX}/PDVD/.collection/:mediaType/`, `${config.URL_PREFIX}/PDVD/:mediaType/`],
        regex: `${config.URL_PREFIX}/PDVD/.*`,
        config: {
            requireSignin: true,
            protocal: {https: true, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {
                title: {string: "browse"},
            },
            sidebar: {
                hide: true
            }
        },
        component: Loadable({
            loader: () => import('../media/BrowseMedia'),
            loading: Loading,
        }),
    },
    {
        key: "appsignin",
        link: `${config.URL_PREFIX}/signinapp/:lang/:sid/:uuid`,
        regex: `${config.URL_PREFIX}/signinapp/.*/.*/.*`,
        config: {
            unBlockMobile: true,
            requireSignin: false,
            protocal: {https: true, http: false},
            musicPlayer: MUSIC_PLAYER_TYPE.DISABLE,
        },
        component: Loadable({loader: () => import('../user/SigninApp'), loading: Loading})
    },
    {
        key: "signin",
        link: `${config.URL_PREFIX}/signin`,
        regex: `${config.URL_PREFIX}/signin`,
        config: {
            requireSignin: false,
            protocal: {https: true, http: false},
            musicPlayer: MUSIC_PLAYER_TYPE.DISABLE,
        },
        component: Loadable({loader: () => import('../user/Signin'), loading: Loading})
    },
    {
        key: "tvsignin",
        link: `${config.URL_PREFIX}/tv`,
        regex: `${config.URL_PREFIX}/tv`,
        config: {
            unBlockMobile: true,
            requireSignin: false,
            protocal: {https: true, http: false},
            musicPlayer: MUSIC_PLAYER_TYPE.DISABLE,
        },
        component: Loadable({loader: () => import('../user/SigninTV'), loading: Loading})
    },
    {
        key: "signoutSetToken",
        link: `${config.URL_PREFIX}/signout/:info`,
        regex: `${config.URL_PREFIX}/signout/.*`,
        config: {
            requireSignin: false,
            protocal: {https: true, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.DISABLE,
        },
        component: Loadable({loader: () => import('../user/Signout'), loading: Loading})
    },
    {
        key: "signout",
        link: `${config.URL_PREFIX}/signout`,
        regex: `${config.URL_PREFIX}/signout`,
        config: {
            requireSignin: false,
            protocal: {https: true, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.DISABLE,
        },
        component: Loadable({loader: () => import('../user/Signout'), loading: Loading})
    },
    {
        key: "forgetPassword",
        link: `${config.URL_PREFIX}/forget-password`,
        regex: `${config.URL_PREFIX}/forget-password`,
        config: {
            unBlockMobile: true,
            requireSignin: false,
            protocal: {https: true, http: false},
            musicPlayer: MUSIC_PLAYER_TYPE.DISABLE,
        },
        component: Loadable({loader: () => import('../user/ForgotPassword'), loading: Loading})
    },
];

export default pageConfig;
