import pageConfigCPP from "./PageConfig/cpp";
import pageConfigPMS from "./PageConfig/pms";
import pageConfigCLCloud from "./PageConfig/clCloud";
import {BUILD} from "../utils/constants";

const CONFIG = {
    cpp: pageConfigCPP,
    pms: pageConfigPMS,
    clCloud: pageConfigCLCloud
};

const pageConfig = CONFIG[BUILD];
export default pageConfig;

export const getConfigByUrl = url => {
    for (let i in pageConfig) {
        if (Array.isArray(pageConfig[i].regex)) {
            for (let j in pageConfig[i].regex) {
                if (url.match(pageConfig[i].regex[j])) {
                    return pageConfig[i].config;
                }
            }
        } else {
            if (url.match(pageConfig[i].regex)) {
                return pageConfig[i].config;
            }
        }
    }
    return pageConfig[1].config;
};
