import { DELETE_FROM } from "../../utils/constants";

export const DELETE_ACTION = {
    DELETE: 'DELETE_ACTION_DELETE',
    CLEAR: 'DELETE_ACTION_CLEAR'
};

const initialState = {
    [DELETE_FROM.HOME]: '',
    [DELETE_FROM.SONG]: '',
    [DELETE_FROM.ALBUM]: '',
    [DELETE_FROM.DETAIL]: '',
    [DELETE_FROM.PLAYER]: '',
    [DELETE_FROM.SEARCH]: '',
    [DELETE_FROM.EPISODE]: '',
    [DELETE_FROM.PHOTO_GRID]: '',
    [DELETE_FROM.CONTENT_FOLDER]: '',
    [DELETE_FROM.CONTENT_COLLECTION]: ''
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case DELETE_ACTION.DELETE:
            return {
                ...state,
                [action.deleteFrom]: action.url
            };
        case DELETE_ACTION.CLEAR:
            return {
                ...state,
                [action.deleteFrom]: ''
            };
        default:
            return state;
    }
}