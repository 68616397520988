import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { withRouter, Redirect, Route, Switch } from 'react-router';
import Container from '@material-ui/core/Container';
import logger from "logger";
import config from "config";
import dataApiService from "../services/data/dataApiService";
import sessionService from '../services/user/sessionService';
import sessionCacheService from "../services/user/sessionCacheService";
import pageConfig, { getConfigByUrl } from "pageConfig";
import { init, listSharedPath } from "../services/data/cloudDataService";
import {useCheckExpired, useThemeMediaQuery} from "../components/componentUtils";
import { uploadConnectPlatform } from "../services/data/mediaDataService";
import { USER_ACTIONS } from "../services/user/userReducer";
import { SHARE_ACTIONS } from "../services/data/shareReducer";
import {API_TYPE, BUILD, BUILD_TYPE, MUSIC_PLAYER_TYPE} from "../utils/constants";
import {getPrefixPath} from "../utils/dataUtils";
import {getSocialToken, socialLogin} from "../services/user/userService";

const SecureRoute = ({ component: Component, pageConfig, ...rest }) => {
    if(!pageConfig.config.protocal.http && location.protocol === "http:") {
        window.location.href = `https://${location.hostname}${location.pathname}`;
    }
    if(!pageConfig.config.protocal.https && location.protocol === "https:") {
        window.location.href = `http://${location.hostname}${location.pathname}`;
    }
    if(pageConfig.config.requireSignin) {
        return <Route {...rest} render={(props) => (
            sessionService.getToken("main") || location.protocol === "http:"
                ? <Component {...props} />
                : <Redirect to={{pathname: `${config.URL_PREFIX}/signin`, state: { from: props.location }}} />
        )} />
    }
    return <Route path={pageConfig.link} component={Component}/>;
};

const Main = props => {
    const dispatch = useDispatch();
    const currentPlay = useSelector(state => state.music.queue[0]);
    const [pageStatus, setPageStatus] = useState({sidebar: false, header: false, player: false});
    const apiType = useSelector(state => state.user.apiType);
    const socialToken = useSelector(state => state.user.socialToken);
    const ssiDomain = useSelector(state => state.user.ssiDomain);
    const clToken = useSelector(state => state.user.clToken);
    const {theme, mobile} = useThemeMediaQuery("sm");
    const {checkExpired} = useCheckExpired();

    // dataPush serverType
    const getServerType = () => {
        if (location.pathname.indexOf("/pdvd/share/") !== -1) return "Share";
        else if (sessionService.getServerType() === "API_TYPE_CLOUD") return "Cloud";
        else if (sessionService.getServerType() === "API_TYPE_MEDIA_SERVER") return "PMS";
        return "Unkwown";
    };
    useEffect(() => {
        dataLayer.push({"serverType": getServerType()});
    }, [getServerType()]);


    useEffect(()=>{
        function handleError(e){
            // const fileName = e.error.stack.match(/(?!at) \w+ (?=\()/gi)[0].trim()+'.js';
            const fileName = e.filename.replace(location.origin+"/", "");
            const errorDetail = {fileName, line: e.lineno, column: e.colno, version: parseInt(__VERSION__)};
            // console.log(e);
            // console.log(errorDetail);
            dataLayer.push({"event": "jsError", "action": e.message, "label": JSON.stringify(errorDetail)});
        }
        window.addEventListener("error", handleError);
        return () => window.removeEventListener("error", handleError);
    }, []);


    useEffect(() => {
        const pageConfig = getConfigByUrl(props.location.pathname);
        if(pageConfig.requireSignin && !sessionService.getToken()) {
            logger.log(`Redirect to signin page because requireSignin=${pageConfig.requireSignin} and serverType=${sessionService.getServerType()}`);
            window.location.href = `https://${location.hostname}/signin`;
        }
        // handle for clcloud ?
        if(pageConfig.requireSignin && !sessionService.getServerType()) {
            logger.log(`Redirect to select page because requireSignin=${pageConfig.requireSignin} and serverType=${sessionService.getServerType()}`);
            window.location.href = `http://${location.hostname}/select`;
        }
        async function handleCloud() {
            dataApiService.setType(API_TYPE.CLOUD);
            if(!dataApiService.apiList) {
                await init();
            }
            dispatch({type: USER_ACTIONS.CHANGE_API_TYPE, newType: API_TYPE.CLOUD});
            // if Cloud, call shared list API and store in redux
            storeSharedList();

            // check expired, if expired then need to disable share function
            const expiredResult = await checkExpired();
            dispatch({ type: USER_ACTIONS.SET_CLOUD_STATUS, status: expiredResult });
        }
        async function handleMedia(baseUrl) {
            dataApiService.setType(API_TYPE.MEDIA_SERVER);
            dataApiService.setBaseUrl(baseUrl || sessionService.getServerUrl());
            dispatch({type: USER_ACTIONS.CHANGE_API_TYPE, newType: API_TYPE.MEDIA_SERVER});
        }

        if(pageConfig.requireSignin && apiType === API_TYPE.UNKNOWN) {
            if(sessionService.getServerType() === API_TYPE.MEDIA_SERVER) {
                handleMedia();
            }else if(sessionService.getServerType() === API_TYPE.CLOUD) {
                handleCloud();
            }
        }
        // PMS cases: no serverType, need to check build type
        else if (BUILD === BUILD_TYPE.pms) {
            // handleMedia("http://192.168.4.48:31298");  // Test
            handleMedia(location.origin);
        }
        setPageStatus({sidebar: !!pageConfig.sidebar, header: !!pageConfig.header, player: pageConfig.musicPlayer});

        if (props.location.pathname === "/home") {
            sessionCacheService.remove("scroll");
        }
    }, [props.location.pathname, apiType]);

    useEffect(() => {
        if(apiType === API_TYPE.MEDIA_SERVER && !sessionCacheService.get('uploadConnectPlatform')){
            const result = uploadConnectPlatform();
            if (result && result.status === "success") {
                sessionCacheService.set('uploadConnectPlatform', '1');
            }
        }
    }, [apiType]);

    useEffect(() => {
        if (!!clToken || sessionService.getToken() || !socialToken) {
            return;
        }
        const interval = setInterval(() => {
            handleInterval();
        }, 2000);

        return () => {
            setTimeout(function () {
                window.ssiWindow.close()
            },0)
            clearInterval(interval);
        };
    }, [socialToken, clToken, sessionService.getToken()]);

    async function handleInterval() {
        const response = await socialLogin(socialToken, ssiDomain);
        const { cltoken } = response;
        if (cltoken !== null) {
            dispatch({type: USER_ACTIONS.SET_CL_TOKEN, clToken:cltoken});
            // sessionService.generateInfo({ clToken });
        }
    }

    function hasPlayer() {
        return currentPlay && pageStatus.player === MUSIC_PLAYER_TYPE.ENABLE;
    }

    async function storeSharedList() {
        const products = (BUILD === BUILD_TYPE.clCloud)? ["DirectorSuite", "PowerDVD"]: undefined;
        const sharedList = await listSharedPath({ accessToken: sessionService.getToken(), products });
        console.log("SharedList from API", sharedList);
        dispatch({type: SHARE_ACTIONS.SET, sharedList});
    }

    return (
        <Container maxWidth={false} style={{marginTop: pageStatus.header ? theme.constant[mobile? "headerHeightSm": "headerHeight"] : 0, padding: hasPlayer() ? "0 0 106px 0" : 0}}>
            <Switch>
                {pageConfig.map((item, index) => {
                    if (item.hasOwnProperty("subItems")) {
                        return item.subItems.map((sub, sindex) => {
                            return <SecureRoute key={"subroute-" + index + sindex} path={item.link + "/" + sub.link.replace(" ", "")} component={sub.component} pageConfig={sub} />
                        })
                    } else {
                        return <SecureRoute key={"route-" + index} path={item.link} component={item.component} pageConfig={item} />
                    }
                })}
                <Redirect from="*" to={getPrefixPath("/home")}/>
            </Switch>
        </Container>
    );
};

export default withRouter(Main);

// <Container maxWidth="xl" style={{padding: 0, marginLeft: showSidebar ? 240 : 0, marginTop: showHeader ? 80 : 0, width: showSidebar ? "calc(100vw - 260px)" : "auto"}}>
// <Container maxWidth="xl" style={{padding: 20, marginTop: showHeader ? 84 : 0}}>
