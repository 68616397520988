import React from "react";
import {injectIntl, FormattedMessage} from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";

@injectIntl
export default class Loading extends React.Component {
    render() {
        const {error, retry, timedout, pastDelay} = this.props;
        if (error) {
            const request = new XMLHttpRequest();
            request.open('GET', error.request, true);
            request.onreadystatechange = function(){
                if (request.readyState === 4){
                    if (request.status === 404) {
                        location.reload();
                    }
                }
            };
            request.send();
            // When the loader has errored
            return <CircularProgress color="inherit" size={30} style={{margin: "40px"}} />;
        } else if (timedout) {
            // When the loader has taken longer than the timeout
            return <div><button onClick={retry}>Retry</button></div>;
        } else if (pastDelay) {
            // When the loader has taken longer than the delay
            return <CircularProgress color="inherit" size={30} style={{margin: "40px"}} />;
        } else {
            // When the loader has just started
            return null;
        }
    }
}
