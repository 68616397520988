import React, {useEffect, useState} from 'react';
import Input from '@material-ui/core/Input';
import {useSingleCodeInput} from "./componentUtils";
import {withStyles} from "@material-ui/core";
// import classNames from "classnames";

const PincodeInput = props => {
    const {classes, num, pincode, setPinCode} = props;
    const [codeHooks, setCodeHooks] = useState([]);
    const [codesRef, setCodesRef] = useState([]);
    const [currentFocus, setCurrentFocus] = useState(0);
    const [type, setType] = useState(Array(num).fill("tel"));

    useEffect(()=> setPinCode(codeHooks.map(e => e.value).join("")), [codeHooks.values()]);

    useEffect(() => {
        let timeout;
        if(pincode === undefined) {
            codeHooks.map(c => {c.onChange({target: {value: ""}})});
            setCurrentFocus(0);
            codesRef[0].select();
        }else {
            if(currentFocus<num-1 && codeHooks[currentFocus].value) {
                setCurrentFocus(prev=>prev+1);
                codesRef[currentFocus+1].select();
            }
            setType(prev => prev.map((e,i)=>(i===currentFocus)?"tel":e));
            if(pincode[currentFocus])
                timeout = setTimeout(() => setType(prev => prev.map((e,i)=>(i===currentFocus)?"password":e)), 500);
        }
        // return () => { setType(Array(num).fill("password")); clearTimeout(timeout) };
    }, [pincode]);

    useEffect(()=> {
        codesRef[currentFocus].focus();
        codesRef[currentFocus].setSelectionRange(1, 1);
    }, [currentFocus]);

    function handleHotKey(e) {
        switch (e.keyCode) {
            case 8: //backspace
                if(currentFocus>0 && !codeHooks[currentFocus].value) {
                    setCurrentFocus(prev=>prev-1);
                }
                break;
            case 37: //left
                if(currentFocus>0) {
                    setCurrentFocus(prev=>prev-1);
                }
                break;
            case 39: //right
                if(currentFocus<num-1) {
                    setCurrentFocus(prev=>prev+1);
                }
                break;
        }
    }

    return (
        <div className={classes.container} onKeyDown={handleHotKey}>
            {[...Array(num).keys()].map((e,i) => {
                codeHooks[i] = useSingleCodeInput("");
                return(
                    <Input key={i} type="tel" className={classes.codeField} {...codeHooks[i]}
                           // className={classNames(classes.codeField, [type[i]==="password" && classes.hide])}
                           inputProps={{
                               inputMode: 'numeric', maxLength: 1,required: true, className: classes.input, autoComplete:"new-password", type: type[i], name: `${i}`,
                               ref: ref => codesRef[i]=ref, onFocus:()=> setCurrentFocus(i)
                           }}  />
                )
            })}
        </div>
    )
};

export default withStyles(theme => ({
    container: {
        display: "flex",
        justifyContent:"space-around",
        maxWidth: 400,
        margin: "0 auto 8px"
    },
    codeField: {
        borderBottom:"1px solid white",
        width:45
    },
    hide: {
        "& input": {
            // fontFamily: "text-security-disc",
            textSecurity: "disc"
        }
    },
    input: {
        textAlign:"center",
        fontSize:28
    }
}))(PincodeInput);

