import config from "config";
import {API_TYPE, DATA_SOURCE, BUILD, MEDIA_TYPE, BUILD_TYPE} from "./constants";
import aesjs from "aes-js";
import pbkdf2 from "pbkdf2";

const key_256 = pbkdf2.pbkdf2Sync(config.AJMylzk7x5, config.UVQeG6bGXU, 1, 256 / 8, config.fvBJwxOOqB);

export const aesEncode = (s) => {
  const textBytes = aesjs.utils.utf8.toBytes(s);
  // The counter is optional, and if omitted will begin at 1
  const aesCtr = new aesjs.ModeOfOperation.ctr(key_256, new aesjs.Counter(5));
  const encryptedBytes = aesCtr.encrypt(textBytes);
  // To print or store the binary data, you may convert it to hex
  const encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
  return encryptedHex;
};

export const aesDecode = (encryptedHex) => {
  // When ready to decrypt the hex string, convert it back to bytes
  const encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex);
  // The counter mode of operation maintains internal state, so to
  // decrypt a new instance must be instantiated.
  const aesCtr = new aesjs.ModeOfOperation.ctr(key_256, new aesjs.Counter(5));
  const decryptedBytes = aesCtr.decrypt(encryptedBytes);
  // Convert our bytes back into text
  const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
  return decryptedText;
};

export const encode = (s) => {
  return customEncode(s, 5);
};

export const customEncode = (s, l) => {
  const regex = new RegExp(`(.{${l}})` ,"g");
  return btoa("" + encodeURIComponent(s)).replace(regex,"$1-");
};

export const decode = (k) => {
  if(!k) {
    return "";
  }
  return decodeURIComponent(atob(k.replace(/-/g, "")));
};

export const getFolderUrl = (source, mediaType, key, collectionName) => {
  if(source === DATA_SOURCE.SHARE) {
    return `/pdvd/share/${customEncode(key, config.URL_ENCODE_BREAK_LENGTH)}`
  }
  return `/${mediaType.toLowerCase()}/folder/${customEncode(collectionName, config.URL_ENCODE_BREAK_LENGTH)}/${customEncode(key, config.URL_ENCODE_BREAK_LENGTH)}`
};

export const getFileUrl = (source, mediaType, key) => {
  if(source === DATA_SOURCE.SHARE) {
    return `/pdvd/share/${customEncode(key, config.URL_ENCODE_BREAK_LENGTH)}`
  }
  return `/${mediaType.toLowerCase()}/file/${customEncode(key, config.URL_ENCODE_BREAK_LENGTH)}`
};

export const getPlayUrl = (source, mediaType, isFolder, key) => {
  if(source === DATA_SOURCE.SHARE) {
    return `/pdvd/share/${customEncode(key, config.URL_ENCODE_BREAK_LENGTH)}`
  }
  return `/${mediaType.toLowerCase()}/${isFolder ? "folder" : "file"}/${customEncode(key, config.URL_ENCODE_BREAK_LENGTH)}`
};

// handlePrefix Issue
export const getPrefixPath = (path) => {
  // check: history.push / <Redirect
  return `${config.URL_PREFIX}${path}`;
};
export const getNoPrefixPath = (path = location.pathname) => {
  // check: location.pathname
  return config.URL_PREFIX? path.split(config.URL_PREFIX).pop(): path;
};

export const getFolderPath = (path) => {
  return path.split("/").splice(0, 5).join("/") + "/";
};

export const getLastTwoPath = (path) => {
  return "/" + path.split("/").slice(-2).join("/");
};

export const getParentPath = (apiType, path, mediaType) => {
  if(apiType === API_TYPE.MEDIA_SERVER) {
    if(mediaType === MEDIA_TYPE.TV) {
      return path.split("/").splice(0, path.includes("Collection") ? 6 : 5).join("/") + "/";
    }
    if(mediaType === MEDIA_TYPE.Photo) {
      return path.split("/").splice(0, 5).join("/") + "/";
    }
  }
  if(apiType === API_TYPE.CLOUD) {
    if(mediaType === MEDIA_TYPE.TV) {
      return path.substring(0, path.lastIndexOf("/") + 1);
    }
    if(mediaType === MEDIA_TYPE.Photo) {
      return path.substring(0, path.lastIndexOf("/") + 1);
    }
  }
  return path;
};

export const getMediaTypeFromPath = (path) => {
  return Object.values(MEDIA_TYPE).find(m => path.toLowerCase().indexOf(`/${m}`.toLowerCase()) >= 0);
};

export const get = (obj, key) => {
  return htmlDecode(key.split(".").reduce(function(o, x) {
    return (typeof o == "undefined" || o === null) ? o : o[x];
  }, obj));
};

export const has = function (obj, key) {
  return key.split(".").every(function (x) {
    if (typeof obj != "object" || obj === null || !(x in obj)) {
      return false;
    }
    obj = obj[x];
    return true;
  });
};

export const shuffle = (array) => {
  const result = [].concat(array);
  for (let i = result.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]];
  }
  return result;
};

export const generateUUID = () => {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
};

export const delay = async (ms) => {
  return new Promise((resolve) => setTimeout(() => {resolve(undefined);}, ms));
};


export const htmlDecode = (input) => {
  if(input && typeof input === "string"){
    let doc = new DOMParser().parseFromString(input, "text/html");
    if(doc.documentElement.textContent === '')
      return input;
    return doc.documentElement.textContent;
  } else {
    return input;
  }
};

export function downloadFile(data, filename, fileType, fileExtension) {
  let blob = new Blob([data], {type: fileType});
  let hiddenElement = window.document.createElement("a");
  hiddenElement.href = window.URL.createObjectURL(blob);
  hiddenElement.download = filename + fileExtension;
  document.body.appendChild(hiddenElement);
  hiddenElement.click();
  document.body.removeChild(hiddenElement);
}

export function checkHasProduct(product) {
  const initUserData = store.getState().user.initUserData;
  if (initUserData) {
    const products = initUserData.products.map(p => p.product);
    return products.indexOf(product) !== -1
  }
}

export function getMediaTypeArray() {
  if (BUILD === BUILD_TYPE.clCloud) {
    if(checkHasProduct("PowerDVD")) {
      return Object.keys(MEDIA_TYPE);
    }
    else {
      return Object.keys(MEDIA_TYPE).slice(2)
    }
  }
  else {
    return Object.keys(MEDIA_TYPE).slice(0, 5);
  }
}