import api from "./dataApiService";
import logger from 'logger';
import {has, get} from "../../utils/dataUtils";
import {logout} from "../user/userService";
import {USER_ACTIONS} from "../user/userReducer";

const APIS = {
    INIT: "/pdvd/web/init.action",
};

export async function init() {
    try {
        const result = await api.post(APIS.INIT, {});
        if(result.response && result.response.status === 500){
            return {status: result.response.status}
        }
        if(result.data && result.data.hasOwnProperty("api")) {
            api.setApiList(result.data.api);
            if(result.data.server) {
                api.setServer(result.data.server);
                await api.revokeCookie();
            }
            if (result.data.user) {
                store.dispatch({type: USER_ACTIONS.SET_INIT_USER_DATA, initUserData: result.data.user});
            }
        }
        return {result: result.data.server ? "success" : "shareOnly", initUserData: result.data.user, status:result.status};
    }catch(e) {
        logger.error(e);
        //User not found. Special error handling for the api (through SSI discover server side bug)
        if(e.response.status!==500){
            logout({redirect: true});
        }
        return get(e, "response.data") || e;
    }
}

export async function continueWatching(params) {
    const {pageSize, pageIndex} = params;
    try {
        const result = await api.post(api.getApiFromKey("home.continuingWatching"), {pageIndex, pageSize});
        if(result.hasOwnProperty("result")) {
            return result.result;
        }
        return [];
    }catch(e) {
        return get(e, "response.data") || e;
    }
}

export async function getCollections(params) {
    const {key, pageSize, pageIndex} = params;
    try {
        const result = await api.post(api.getApiFromKey(key), {pageSize, pageIndex});
        if(result.hasOwnProperty("data")) {
            return result.data;
        }
        return [];
    }catch(e) {
        logger.error(e);
        return get(e, "response.data") || e;
    }
}

export async function getDownloadFile(params) {
    const {key, url, ...param} = params;
    try {
        const result = await api.post(url || api.getApiFromKey(key), param);

        if(!result.hasOwnProperty("data")) {
            console.log(result.hasOwnProperty("response") ? result.response : result);
            // return result.hasOwnProperty("response") ? result.response : result;
        }

        if(result.hasOwnProperty("data")) {
            const resDisposition = result.request.getResponseHeader('Content-Disposition') || '';
            const regex = /filename[^;\n=]*="((['"]).*?\2|[^;\n]*)"/;
            let filename = regex.exec(resDisposition);
            let extension = ""
            if (filename && filename[1]) {
                const fileData = filename[1].split(".");
                filename = fileData[0];
                extension = fileData[1]
            }
            console.log("resDisposition", resDisposition, filename, extension);
            return {data: result.data, filename, extension};
        }
    }catch(e) {
        logger.error(e);
        return get(e, "response.data") || e;
    }
}

export async function getList(params) {
    const {key, path, sort, asc, pageSize, pageIndex, customParams} = params;
    try {
        const result = await api.post(api.getApiFromKey(key), {path, sort, asc, pageSize, pageIndex, ...customParams});
        if(result.hasOwnProperty("data")) {
            return result.data;
        }
        return [];
    }catch(e) {
        logger.error(e);
        return get(e, "response.data") || e;
    }
}

export async function getSearch(params) {
    const {key, keyword, sort, pageSize, pageIndex} = params;
    try {
        const result = await api.post(api.getApiFromKey(key), {keyword, sort, pageSize, pageIndex});
        if(result.hasOwnProperty("data")) {
            return result.data;
        }
        return {result:[]};
    }catch(e) {
        logger.error(e);
        return get(e, "response.data") || e;
    }
}


export async function getDetail(params) {
    const {key, path, pageSize, pageIndex} = params;
    try {
        const result = await api.post(api.getApiFromKey(key), {path, pageSize, pageIndex});
        if(result.hasOwnProperty("data")) {
            return result.data;
        }
        return {};
    }catch(e) {
        logger.error(e);
        return get(e, "response.data") || e;
    }
}

export async function getMusicByTag(params) {
    const {tag, pageSize, pageIndex, sort, asc} = params;
    try {
        const result = await api.post(api.getApiFromKey("music.tags"), {tag, pageSize, pageIndex, sort, asc});
        if(result.hasOwnProperty("data")) {
            return result.data;
        }
        return {};
    }catch(e) {
        logger.error(e);
        return get(e, "response.data") || e;
    }
}

export async function getMusicByPath(params) {
    await init();
    const {path} = params;
    try {
        const result = await api.post(api.getApiFromKey("music.music"), {path});  //api.getApiFromKey("music.music") / https://demo1.powercloud.cyberlink.com/pdvd/web/music.action?music
        if(result.hasOwnProperty("data")) {
            return result.data;
        }
        return {};
    }catch(e) {
        logger.error(e);
        return get(e, "response.data") || e;
    }
}

export async function updatePlaylistEnd(params) {
    const {playlistEnd, path} = params;
    try {
        await api.post(api.getApiFromKey("update.updatePlaylistEnd"), {path, playlistEnd});
        return {status: "OK"};
    }catch(e) {
        logger.error(e);
        return get(e, "response.data") || e;
    }
}

export async function updateResumeEpisode(params) {
    const {resumeEpisode, path} = params;
    try {
        await api.post(api.getApiFromKey("update.updateResumeEpisode"), {path, resumeEpisode});
        return {status: "OK"};
    }catch(e) {
        logger.error(e);
        return get(e, "response.data") || e;
    }
}

export async function updateResumeTime(params) {
    const {resumeTime, path} = params;
    try {
        await api.post(api.getApiFromKey("update.updateResumeTime"), {path, resumeTime});
        return {status: "OK"};
    }catch(e) {
        logger.error(e);
        return get(e, "response.data") || e;
    }
}

function updateShareServer(newServer) {
    const oldServer = api.apiList.shared.query.substring(0, api.apiList.shared.query.indexOf("/", 8));
    api.apiList.shared.query = api.apiList.shared.query.replace(oldServer, newServer);
    return api.apiList.shared.query;
}

export async function queryShare(params) {
    const {sharedId, revisionId, sort, asc, pincode, pageSize, pageIndex, tag} = params;
    try {
        if(!api.apiList) {
            await init();
        }
        let shareApi = api.getApiFromKey("shared.query");
        if(api.shareServer && shareApi.indexOf(api.shareServer) < 0) {
            shareApi = updateShareServer(api.shareServer);
        }
        const result = await api.post(shareApi, {sharedId, revisionId, sort, asc, pinCode: pincode, pageSize, pageIndex, tag});
        if(has(result, "data.result.redirectTo")) {
            api.setShareServer(result.data.result.redirectTo);
            return await queryShare(params);
        }
        return get(result, result.hasOwnProperty("response") ? "response.data" : "data");
    }catch(e) {
        logger.error(e);
        return get(e, "response.data") || e;
    }
}

export async function generateSharedPath(params) {
    try {
        const result = await api.post(api.getApiFromKey("app.shared.generate"), params);
        if (result.hasOwnProperty("data")) {
            return result.data.result;
        }
    } catch(e) {
        return get(e, "response.data") || e;
    }
}

export async function deleteSharePath(params){
    try{
        const result = await api.post(api.getApiFromKey("app.shared.delete"), params);
        if(result.hasOwnProperty("data")){
            return result.data.status;
        }
    } catch(e) {
        return get(e, "response.data") || e;
    }
}

export async function listSharedPath(params){
    try{
        const result = await api.post(api.getApiFromKey("app.shared.list"), params);
        if(result.hasOwnProperty("data")){
            return result.data.result;
        }
    } catch(e) {
        return get(e, "response.data") || e;
    }
}

export async function deleteContent(params){
    try {
        const result = await api.post(api.getApiFromKey("app.file.delete"), params);
        if(result.hasOwnProperty("data")){
            return result.data.status;
        }
    } catch(e) {
        return get(e, "response.data") || e;
    }
}

