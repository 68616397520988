/**
 * Created by Jeffy_Chuang on 2019/10/14.
 */
import {
    API_TYPE,
    MEDIA_TYPE,
    DATA_TYPE,
    RESOLUTION_TYPE,
    CONTAINER_FORMAT,
    VIDEO_CODEC,
    AUDIO_CODEC,
    SAFARI_SUPPORT_CODEC, BROWSER_SUPPORT_EXTENSION, BROWSER_SUPPORT_CODEC, BROWSER_NOT_SUPPORT_CODEC, ORIENTATION_CODEC
} from "../utils/constants";
import logger from "logger";
import {get, getLastTwoPath, getParentPath} from "../utils/dataUtils";
import {DATA_SOURCE} from "../utils/constants";
import sessionService from "../services/user/sessionService";
import Bowser from "bowser";

function getResolutionSetting(height) {
    const result = [];
    Object.values(RESOLUTION_TYPE).map(r => {
        if(result.length === 0 || height >= r.minHeight) {
            result.unshift(r);
        }
    });
    return result;
}

const DEFAULT_PHOTO = require(`../assets/images/main/photo_default_img@2x.png`);

function getPhotoCoverforPMS(m) {
    if(m.tags.thumbPath) {
        return {url: m.tags.thumbPath, x: m.tags.resolutionX, y: m.tags.resolutionY}
    }
    if(m.tags.medias && m.tags.medias.length > 0) {
        const jpeg = m.tags.medias.find(media => media.contentType === "image/jpeg");
        if(jpeg) {
            return {url: jpeg.url, x: jpeg.resolutionX, y: jpeg.resolutionY};
        }else if(m.tags.medias[0].url.includes(".jpg") || m.tags.medias[0].url.includes(".jpeg") || m.tags.medias[0].url.includes(".png")) {
            return {url: m.tags.medias[0].url, x: m.tags.medias[0].resolutionX, y: m.tags.medias[0].resolutionY};
        }
    }
    return {url: DEFAULT_PHOTO, x: 150, y: 250};
}

function getPhotoFromPMS(m) {
    if(m.tags.medias && m.tags.medias.length > 0) {
        if(m.tags.medias[0].url.includes(".jpg") || m.tags.medias[0].url.includes(".jpeg") || m.tags.medias[0].url.includes(".png") || m.tags.medias[0].url.includes(".gif")) {
            return m.tags.medias[0].url;
        }else {
            const jpeg = m.tags.medias.find(media => media.contentType === "image/jpeg");
            if(jpeg) {
                return jpeg.url;
            }
        }
    }
    return DEFAULT_PHOTO;
}

const DEFAULT_SETTING = {audio: 0, subtitle: undefined, quality: 0};

function checkBrowserCodec(currentCodec){
    const browser = Bowser.parse(window.navigator.userAgent).browser.name.toLowerCase().replace(" ","_");

    // check safari for support
    if (browser === "safari") {
        // result = SAFARI_MEDIA_CODEC["videoCodec"].indexOf(currentCodec["videoCodec"]) >= 0 && SAFARI_MEDIA_CODEC["audioCodec"].indexOf(currentCodec["audioCodec"]) >= 0;
        return Object.keys(SAFARI_SUPPORT_CODEC).every(key => SAFARI_SUPPORT_CODEC[key].indexOf(currentCodec[key]) !== -1);  //all in array
    }
    // check other browser for not support
    const notSupportCodec = BROWSER_NOT_SUPPORT_CODEC[browser];
    if (notSupportCodec) {
        return Object.keys(notSupportCodec).every(key => notSupportCodec[key].indexOf(currentCodec[key]) === -1);  //all not in array
    }

    return true;
}

function getPlayUrlFromPMS(m, settings) {
    const {subMediaType, subVideoType, subAudioType} = m.tags;
    const fileExtension = m.path.substring(m.path.lastIndexOf(".")).toLowerCase();
    const currentCodec = {
        containerFormat: CONTAINER_FORMAT[subMediaType],
        videoCodec: VIDEO_CODEC[subVideoType],
        audioCodec: AUDIO_CODEC[subAudioType],
    };
    console.log("current codec: ", currentCodec);
    // return m.tags.medias[0].url;

    if(checkBrowserCodec(currentCodec) && BROWSER_SUPPORT_EXTENSION.indexOf(fileExtension)!==-1 && settings.audio===0 && isNaN(settings.subtitle)) {
        console.log("play original");
        return m.tags.medias[0].url;
    }

    const lastLevel = parseInt(localStorage.getItem("autoLevel"));
    const playUrl = `${m.tags.medias[1].url}?uuid=${sessionService.getUUID()}&audioTrackID=${settings.audio}` +
        `${isNaN(settings.subtitle) ? "" : `&subtitleTrackID=${settings.subtitle}`}`+
        `&stream_profile_id=${lastLevel || 5}`;
    console.log("play m3u8");
    return playUrl;
}

function getPreferSubtitleForTV(cache, name, list, episodeNumber) {
    let splitCache = cache ? cache.split('.') : '';
    let cacheFileExtension = splitCache ? splitCache[splitCache.length - 1] : '';
    let ep;


    for(let i = 0; i < list.length; i++){
        ep = list[i].name.match(/e\d+|ep\d+/gi);

        if(name.includes(list[i].name.slice(0, list[i].name.length -4)))
            return list[i].url;
        else if(ep && ep.length !== 0){
            for(let j = 0; j < ep.length; j++){
                if(parseInt(ep[j].toLowerCase().replace('ep','').replace('e',''), 10) === parseInt(episodeNumber, 10))
                    return list[i].url;
            }
        }
        else if(cacheFileExtension === list[i].name.slice(-3) && list.length - 1 === i)
            return list[i].url;
    }

    if(!splitCache && !cacheFileExtension) return '';
}

const currName = sessionService.getName() + sessionService.getUUID();

export const DATA_PARSER = {
    [DATA_TYPE.COLLECTION]: {
        [API_TYPE.MEDIA_SERVER]: {
            [MEDIA_TYPE.Movie]: (collection) => {
                // logger.log(`Collection_${API_TYPE.MEDIA_SERVER}_${MEDIA_TYPE.Movie}`, collection);
                const data = [];
                collection.map((m,index) => {
                    data.push({
                        path: m.path,
                        name: m.tags.title || m.tags.name,
                        folder: m.tags.queryType !== "File",
                        cover: {url: m.tags.ml_user_thumbPath || m.tags.ml_thumbPath || m.tags.thumbPath},
                        havePincode: m.tags.havePincode,
                        dataType: 'collection/movie',
                        _pcn: currName,
                        order: index,
                        distinctPath: m.path + 'collection' + m.sort + currName + index + m.pageIndex,
                        attribute: '/collection/pms/movie',
                        sort: m.sort,
                        totalSize: m.totalSize,
                        pageIndex: m.pageIndex ? m.pageIndex : 0,
                    });
                });
                return {data};
            },
            [MEDIA_TYPE.TV]: (collection) => {
                // logger.log(`Collection_${API_TYPE.MEDIA_SERVER}_${MEDIA_TYPE.TV}`, collection);
                const data = [];
                collection.map((m, index) => {
                    data.push({
                        path: m.path,
                        name: m.tags.name,
                        // name: m.tags.tvTitle || m.tags.name,
                        folder: m.tags.queryType !== "File",
                        cover: {url: m.tags.ml_user_thumbPath || m.tags.ml_thumbPath || m.tags.thumbPath},
                        havePincode: m.tags.havePincode,
                        dataType: 'collection/tv',
                        _pcn: currName,
                        order: index,
                        distinctPath: m.path + 'collection' + m.sort + currName + index + m.pageIndex,
                        attribute: '/collection/pms/tv',
                        sort: m.sort,
                        totalSize: m.totalSize,
                        pageIndex: m.pageIndex ? m.pageIndex : 0,
                    });
                });
                return {data};
            },
            [MEDIA_TYPE.Video]: (collection) => {
                // logger.log(`Collection_${API_TYPE.MEDIA_SERVER}_${MEDIA_TYPE.Video}`, collection);
                const data = [];
                collection.map((m, index) => {
                    data.push({
                        path: m.path,
                        name: m.tags.name,
                        folder: m.tags.queryType !== "File",
                        cover: {url: m.tags.thumbPath},
                        havePincode: m.tags.havePincode,
                        childCount: m.tags.folderCount,
                        dataType: 'collection/video',
                        _pcn: currName,
                        order: index,
                        distinctPath: m.path + 'collection' + m.sort + currName + index + m.pageIndex,
                        attribute: '/collection/pms/video',
                        sort: m.sort,
                        totalSize: m.totalSize,
                        pageIndex: m.pageIndex ? m.pageIndex : 0,
                    });
                });
                return {data};
            },
            [MEDIA_TYPE.Photo]: (collection) => {
                // logger.log(`Collection_${API_TYPE.MEDIA_SERVER}_${MEDIA_TYPE.Photo}`, collection);
                const data = [];
                collection.map((m, index) => {
                    data.push({
                        path: m.path,
                        name: m.tags.name,
                        folder: m.tags.queryType !== "File",
                        cover: {url: m.tags.thumbPath},
                        havePincode: m.tags.havePincode,
                        childCount: m.tags.folderCount,
                        dataType: 'collection/photo',
                        _pcn: currName,
                        order: index,
                        distinctPath: m.path + 'collection' + m.sort + currName + index + m.pageIndex,
                        attribute: '/collection/pms/photo',
                        sort: m.sort,
                        totalSize: m.totalSize,
                        pageIndex: m.pageIndex ? m.pageIndex : 0,
                    });
                });
                return {data};
            },
            [MEDIA_TYPE.Music]: (collection) => {
                // logger.log(`Collection_${API_TYPE.MEDIA_SERVER}_${MEDIA_TYPE.Music}`, collection);
                const data = [];
                collection.map((m, index) => {
                    data.push({
                        path: m.path,
                        name: m.tags.name || "Unknown Albums",
                        folder: m.tags.queryType !== "File",
                        cover: {url: m.tags.thumbPath},
                        havePincode: m.tags.havePincode,
                        dataType: 'collection/music',
                        _pcn: currName,
                        order: index,
                        distinctPath: m.path + 'collection' + m.sort + currName + index + m.pageIndex,
                        attribute: '/collection/pms/music',
                        sort: m.sort,
                        totalSize: m.totalSize,
                        pageIndex: m.pageIndex ? m.pageIndex : 0,
                    });
                });
                return {data};
            }
        },
        [API_TYPE.CLOUD]: {
            [MEDIA_TYPE.Movie]: (collection) => {
                // logger.log(`Collection_${API_TYPE.CLOUD}_${MEDIA_TYPE.Movie}`, collection);
                const data = [];
                collection.map((c, index) => {
                    data.push({
                        ...c,
                        name: c.title || c.name,
                        cover: {url: get(c, "customized.url") || get(c, "cover.url") || get(c, 'thumbnail.url')},
                        order: index,
                        distinctPath: c.path + 'collection' + c.sort + currName + index + c.pageIndex,
                        dataType: 'collection/movie',
                        _pcn: currName,
                        sort: c.sort,
                        totalSize: c.totalSize,
                        pageIndex: c.pageIndex ? c.pageIndex : 0,
                    });
                });
                // logger.log(`Collection_${API_TYPE.CLOUD}_${MEDIA_TYPE.Movie}`, collection, data);
                return {data};
            },
            [MEDIA_TYPE.TV]: (collection) => {
                // logger.log(`Collection_${API_TYPE.CLOUD}_${MEDIA_TYPE.TV}`, collection);
                const data = [];
                collection.map((c, index) => {
                    data.push({
                        ...c,
                        name: c.title || c.name,
                        cover: {url: get(c, "customized.url") || get(c, "cover.url") || get(c, 'thumbnail.url')},
                        order: index,
                        distinctPath: c.path + 'collection' + c.sort + currName + index + c.pageIndex,
                        dataType: 'collection/tv',
                        _pcn: currName,
                        sort: c.sort,
                        totalSize: c.totalSize,
                        pageIndex: c.pageIndex ? c.pageIndex : 0,
                    });
                });
                // logger.log(`Collection_${API_TYPE.CLOUD}_${MEDIA_TYPE.TV}`, collection, data);
                return {data};
            },
            [MEDIA_TYPE.Video]: (collection) => {
                // logger.log(`Collection_${API_TYPE.CLOUD}_${MEDIA_TYPE.Video}`, collection);
                const data = [];
                collection.map((c, index) => {
                    data.push({
                        path: c.path,
                        name: c.name,
                        cover: {url: get(c, 'thumbnail.url')},
                        childCount: c.childCount,
                        order: index,
                        distinctPath: c.path + 'collection' + c.sort + currName + index + c.pageIndex,
                        dataType: 'collection/video',
                        _pcn: currName,
                        sort: c.sort,
                        totalSize: c.totalSize,
                        pageIndex: c.pageIndex ? c.pageIndex : 0,
                    });
                });
                // logger.log(`Collection_${API_TYPE.CLOUD}_${MEDIA_TYPE.Video}`, collection, data);
                return {data};
            },
            [MEDIA_TYPE.Photo]: (collection) => {
                // logger.log(`Collection_${API_TYPE.CLOUD}_${MEDIA_TYPE.Photo}`, collection);
                const data = [];
                collection.map((c,index) => {
                    data.push({
                        path: c.path,
                        name: c.name,
                        cover: {url: get(c, 'thumbnail.url')},
                        childCount: c.childCount,
                        order: index,
                        distinctPath: c.path + 'collection' + c.sort + currName + index + c.pageIndex,
                        dataType: 'collection/photo',
                        _pcn: currName,
                        sort: c.sort,
                        totalSize: c.totalSize,
                        pageIndex: c.pageIndex ? c.pageIndex : 0,
                    });
                });
                return {data};
            },
            [MEDIA_TYPE.Music]: (collection) => {
                // logger.log(`Collection_${API_TYPE.CLOUD}_${MEDIA_TYPE.Music}`, collection);
                const data = [];
                collection.map((c, index) => {
                    data.push({
                        path: `/PDVD/Music/${c.artist || "Unknown Artist"}/${c.albumTitle}`,
                        name: c.albumTitle || "Unknown Albums",
                        cover: {url: get(c, 'thumbnail.url')},
                        order: index,
                        distinctPath: `/PDVD/Music/${c.artist || "Unknown Artist"}/${c.albumTitle}` + 'collection' + c.sort + currName + index + c.pageIndex,
                        dataType: 'collection/music',
                        _pcn: currName,
                        sort: c.sort,
                        totalSize: c.totalSize,
                        pageIndex: c.pageIndex ? c.pageIndex : 0,
                    });
                });
                return {data};
            },
            [MEDIA_TYPE.Template]: (collection) => {
                // logger.log(`Collection_${API_TYPE.CLOUD}_${MEDIA_TYPE.Template}`, collection);
                const data = [];
                collection.map((c, index) => {
                    data.push({
                        path: c.path,
                        name: c.name,
                        cover: {url: get(c, 'thumbnail.url') || get(c, 'thumbnailList.0.url')},
                        defaultThumbnail: (c.type === "SOUND")? "SOUND": undefined,
                        downloadUrl: get(c, 'original.url'),
                        order: index,
                        distinctPath: c.path + 'collection' + c.sort + currName + index + c.pageIndex,
                        dataType: 'collection/template',
                        _pcn: currName,
                        sort: c.sort,
                        totalSize: c.totalSize,
                        pageIndex: c.pageIndex ? c.pageIndex : 0,
                    });
                });
                // logger.log(`Collection_${API_TYPE.CLOUD}_${MEDIA_TYPE.Template}`, collection, data);
                return {data};
            },
            [MEDIA_TYPE.Project]: (collection) => {
                // logger.log(`Collection_${API_TYPE.CLOUD}_${MEDIA_TYPE.Project}`, collection);
                const data = [];
                collection.map((c, index) => {
                    data.push({
                        path: c.path,
                        name: c.name,
                        cover: {url: get(c, 'thumbnail.url')},
                        defaultThumbnail: c.ap,
                        order: index,
                        distinctPath: c.path + 'collection' + c.sort + currName + index + c.pageIndex,
                        dataType: 'collection/project',
                        _pcn: currName,
                        sort: c.sort,
                        totalSize: c.totalSize,
                        pageIndex: c.pageIndex ? c.pageIndex : 0,
                    });
                });
                // logger.log(`Collection_${API_TYPE.CLOUD}_${MEDIA_TYPE.Project}`, collection, data);
                return {data};
            },
        }
    },
    [DATA_TYPE.LIST]: {
        [API_TYPE.MEDIA_SERVER]: {
            [MEDIA_TYPE.Movie]: (parent, list) => {
                // logger.log(`List_${API_TYPE.MEDIA_SERVER}_${MEDIA_TYPE.Movie}`, parent, list);
                const data = [];
                list.map((m,index) => {
                    data.push({
                        path: m.path.includes("/PDVD/Movie/Collection_All/") ? m.path.replace("/PDVD/Movie/Collection_All/", m.tags.folderPath) : m.path,
                        title: m.tags.title || m.tags.name,
                        folder: m.tags.queryType !== "File",
                        cover: {url: m.tags.ml_user_thumbPath || m.tags.ml_thumbPath || m.tags.thumbPath},
                        mediaType: m.mediaType,
                        havePincode: m.tags.havePincode,
                        dataType: 'list/movie',
                        order: index,
                        parent: parent[0].name,
                        _pcn: currName,
                        pageIndex: m.pageIndex ? m.pageIndex : 0,
                        distinctPath: m.path.includes("/PDVD/Movie/Collection_All/") ? m.path.replace("/PDVD/Movie/Collection_All/", m.tags.folderPath) + 'list' + m.sort + currName + index: m.path + 'list' + m.sort + currName + index,
                        attribute: '/list/pms/movie',
                        totalSize: m.totalSize,
                        sort: m.sort,
                    });
                });
                return {name: parent[0] ? parent[0].name : '', path: parent[0] ? parent[0].path : '', data: data};
            },
            [MEDIA_TYPE.TV]: (parent, list) => {
                // logger.log(`List_${API_TYPE.MEDIA_SERVER}_${MEDIA_TYPE.TV}`, parent, list);
                const data = [];
                list.map((m,index) => {
                    data.push({path: m.path.includes("/PDVD/TV/Collection_All/") ? m.path.replace("/PDVD/TV/Collection_All/", m.tags.folderPath) : m.path,
                        title: m.tags.title || m.tags.name,
                        // title: m.tags.tvTitle ? `${m.tags.tvTitle} ${m.tags.seasonNumber || ""}` : m.tags.title || m.tags.name,
                        folder: m.tags.queryType === "Folder",
                        cover: {url: m.tags.ml_user_thumbPath || m.tags.ml_thumbPath || m.tags.thumbPath}, mediaType: m.mediaType, havePincode: m.tags.havePincode, dataType: 'list/tv',
                        order: index, parent: parent[0].name, _pcn: currName, pageIndex: m.pageIndex ? m.pageIndex : 0,
                        distinctPath: m.path.includes("/PDVD/TV/Collection_All/") ? m.path.replace("/PDVD/TV/Collection_All/", m.tags.folderPath) + 'list' + m.sort + currName + index : m.path + 'list' + m.sort + currName + index,
                        attribute: '/list/pms/tv',totalSize: m.totalSize, sort: m.sort,
                    });
                });
                return {name: parent[0] ? parent[0].name : '', path: parent[0] ? parent[0].path : '', data: data};
            },
            [MEDIA_TYPE.Video]: (parent, list) => {
                // logger.log(`List_${API_TYPE.MEDIA_SERVER}_${MEDIA_TYPE.Video}`, parent, list);
                const data = [];
                list.map((m,index) => {
                    data.push({
                        path: m.path.includes("/PDVD/Video/All/") ? m.path.replace("/PDVD/Video/All/", m.tags.folderPath) : m.path,
                        title: m.tags.name,
                        folder: m.tags.queryType !== "File",
                        cover: {url: m.tags.thumbPath},
                        duration: m.tags.duration,
                        mediaType: m.mediaType,
                        havePincode: m.tags.havePincode,
                        dataType: 'list/video',
                        order: index,
                        parent: parent[0].name,
                        _pcn: currName,
                        pageIndex: m.pageIndex ? m.pageIndex : 0,
                        distinctPath: m.path.includes("/PDVD/Video/All/") ? m.path.replace("/PDVD/Video/All/", m.tags.folderPath) + 'list' + m.sort + currName + index : m.path + 'list' + m.sort + currName + index,
                        attribute: '/list/pms/video',
                        totalSize: m.totalSize,
                        sort: m.sort,
                        tags: m.tags
                    });
                });
                return {name: parent[0] ? parent[0].name : '', path: parent[0] ? parent[0].path : '', data: data};
            },
            [MEDIA_TYPE.Photo]: (parent, list, listSize) => {
                // logger.log(`List_${API_TYPE.MEDIA_SERVER}_${MEDIA_TYPE.Photo}`, parent, list, listSize);
                const data = [];
                list.map((m, index) => {
                    data.push({
                        path: m.path.includes("/PDVD/Photo/All/") ? m.path.replace(/\/PDVD\/Photo\/All\/.*?\//, m.tags.folderPath) : m.path,
                        title: m.tags.name,
                        folder: m.tags.queryType !== "File",
                        cover:  getPhotoCoverforPMS(m),
                        mediaType: m.mediaType,
                        havePincode: m.tags.havePincode,
                        dataType: 'list/photo',
                        parent: parent[0].name,
                        _pcn: currName,
                        order: index,
                        pageIndex: m.pageIndex ? m.pageIndex : 0,
                        distinctPath: m.path.includes("/PDVD/Photo/All/") ? m.path.replace(/\/PDVD\/Photo\/All\/.*?\//, m.tags.folderPath) + 'list'+ m.sort + currName + index : m.path + 'list' + m.sort + currName + index,
                        attribute: '/list/pms/photo',
                        totalSize: m.totalSize || listSize,
                        sort: m.sort,
                    });
                });
                return {name: parent[0] ? parent[0].name : '', path: parent[0] ? parent[0].path : '', data: data, totalSize: listSize};
            },
            [MEDIA_TYPE.Music]: (parent, list, listSize) => {
                // logger.log(`List_${API_TYPE.MEDIA_SERVER}_${MEDIA_TYPE.Music}`, parent, list, listSize);
                const data = [];
                list.map((m, index) => {
                    data.push({
                        path: m.path,
                        title: m.tags.name||"Unknown Albums",
                        folder: m.tags.queryType !== "File",
                        cover: {url: m.tags.thumbPath},
                        havePincode: m.tags.havePincode,
                        mediaType: m.mediaType,
                        dataType: 'list/music',
                        parent: (parent && parent[0] && parent[0].name) || '',
                        _pcn: currName,
                        order: index,
                        pageIndex: m.pageIndex ? m.pageIndex : 0,
                        distinctPath: m.path + 'list' + 'sort' + currName + index,
                        attribute: '/list/pms/music',
                        totalSize: m.totalSize || listSize,
                        sort: m.sort,
                    });
                });
                return {data};
            }
        },
        [API_TYPE.CLOUD]: {
            [MEDIA_TYPE.Movie]: (parent, list, listSize, timestamp) => {
                // logger.log(`List_${API_TYPE.CLOUD}_${MEDIA_TYPE.Movie}`, parent, list, listSize);
                const data = [];
                list.map((m,index) => {
                    data.push({
                        ...m,
                        cover: {url: get(m, "customized.url") || get(m, "cover.url") || get(m, 'thumbnail.url')},
                        distinctPath: m.path + 'list' + m.sort + currName + index,
                        dataType: 'list/movie',
                        _pcn: currName,
                        pageIndex: m.pageIndex ? m.pageIndex : 0,
                        order: index,
                        parent: parent[0].title || parent[0].name,
                        mediaType: 'Movie',
                        totalSize: m.totalSize || listSize,
                        sort: m.sort,
                    });
                });
                return {
                    name: parent[0].title || parent[0].name,
                    path: parent[0].path,
                    data: data,
                    totalSize: listSize,
                    timestamp: timestamp
                };
            },
            [MEDIA_TYPE.TV]: (parent, list, listSize) => {
                // logger.log(`List_${API_TYPE.CLOUD}_${MEDIA_TYPE.TV}`, parent, list, listSize);
                const data = [];
                list.map((m,index) => {
                    data.push({
                        ...m,
                        title: m.title || `${m.name} ${m.seasonNumber || ""}`,
                        cover: {url: get(m, "customized.url") || get(m, "cover.url") || get(m, 'thumbnail.url')},
                        folder: false,
                        distinctPath: m.path + 'list' + m.sort + currName + index,
                        dataType: 'list/tv',
                        _pcn: currName,
                        order: index,
                        pageIndex: m.pageIndex ? m.pageIndex : 0,
                        parent: parent[0].title || parent[0].name,
                        mediaType: 'TV',
                        totalSize: m.totalSize || listSize,
                        sort: m.sort,
                    });
                });
                return {name: parent[0].name, path: parent[0].path, data: data, totalSize: listSize};
            },
            [MEDIA_TYPE.Video]: (parent, list, listSize, timestamp) => {
                // logger.log(`List_${API_TYPE.CLOUD}_${MEDIA_TYPE.Video}`, parent, list, listSize);
                const data = [];
                list.map((m,index) => {
                    data.push({
                        path: m.path,
                        title: m.name || m.title,
                        folder: m.folder,
                        cover: {url: get(m, 'thumbnail.url')},
                        revisionId: m.revisionId,
                        distinctPath: m.path + 'list' + m.sort + currName + index,
                        dataType: 'list/video',
                        _pcn: currName,
                        order: index,
                        pageIndex: m.pageIndex ? m.pageIndex : 0,
                        parent: parent[0].title || parent[0].name,
                        mediaType: 'Video',
                        totalSize: m.totalSize || listSize,
                        sort: m.sort,
                    });
                });
                return {
                    name: parent[0].name,
                    path: parent[0].path,
                    data: data,
                    totalSize: listSize,
                    timestamp: timestamp,
                };
            },
            [MEDIA_TYPE.Photo]: (parent, list, listSize) => {
                // logger.log(`List_${API_TYPE.CLOUD}_${MEDIA_TYPE.Photo}`, parent, list, listSize);
                const data = [];
                list.map((m,index) => {
                    const cover = get(m, 'thumbnail.url');
                    data.push({
                        path: m.path,
                        title: m.name || m.title,
                        folder: m.folder,
                        // add for gridList
                        cover: cover ? {url: cover, x: get(m, 'thumbnail.resolutionX'), y: get(m, 'thumbnail.resolutionY')} :
                            {url: require(`../assets/images/main/photo_default_img@2x.png`), x: 150, y: 250},
                        playUrl: get(m, 'original.url'),
                        revisionId: m.revisionId,
                        order: index,
                        distinctPath: m.path + 'list' + m.sort + currName + index,
                        dataType: 'list/photo',
                        _pcn: currName,
                        pageIndex: m.pageIndex ? m.pageIndex : 1,
                        parent: parent[0].title || parent[0].name,
                        mediaType: 'Photo',
                        totalSize: m.totalSize || listSize,
                        sort: m.sort,
                    });
                });
                return {
                    name: parent[0].name,
                    parentPath: `/pdvd/share/${parent[0].sharedId}/browse`,
                    path: parent[0].path,
                    data: data,
                    totalSize: listSize
                };
            },
            [MEDIA_TYPE.Music]: (parent, list, listSize) => {
                // logger.log(`List_${API_TYPE.CLOUD}_${MEDIA_TYPE.Music}`, parent, list, listSize);
                const data = [];
                list.map((m,index) => {
                    data.push({
                        path: `/PDVD/Music/${m.artist || "Unknown Artist"}/${m.albumTitle}${m.folder ? "" : "/" + m.title}`,
                        title: m.albumTitle||"Unknown Albums",
                        artist: m.artist,
                        folder: m.folder,
                        cover: {url: get(m, 'thumbnail.url')},
                        name: m.title || m.name,
                        order: index,
                        distinctPath: `/PDVD/Music/${m.artist || "Unknown Artist"}/${m.albumTitle}${m.folder ? "" : "/" + m.title}` + 'list' + m.sort + currName + index,
                        dataType: 'list/music',
                        _pcn: currName,
                        pageIndex: m.pageIndex ? m.pageIndex : 0,
                        parent: parent[0].title || parent[0].name,
                        mediaType: 'Music',
                        totalSize: m.totalSize || listSize,
                        sort: m.sort,
                        trackNumber: m.trackNumber,
                        songPath: m.path,
                    });
                });
                return {name: parent[0].name, path: parent[0].path, data: data, totalSize: listSize};
            },
            [MEDIA_TYPE.Template]: (parent, list, listSize) => {
                const data = [];
                list.map((m, index) => {
                    data.push({
                        path: m.path,
                        name: m.name,
                        cover: {url: get(m, 'thumbnail.url') || get(m, 'thumbnailList.0.url')},
                        defaultThumbnail: (m.type === "SOUND")? "SOUND": undefined,
                        downloadUrl: get(m, 'original.url'),
                        order: index,
                        distinctPath: m.path + 'list' + m.sort + currName + index + m.pageIndex,
                        dataType: 'list/template',
                        _pcn: currName,
                        sort: m.sort,
                        mediaType: 'Template',
                        totalSize: m.totalSize,
                        pageIndex: m.pageIndex ? m.pageIndex : 0,
                    });
                });
                // logger.log(`LIST_${API_TYPE.CLOUD}_${MEDIA_TYPE.Template}`, parent, list, listSize, data);
                return {
                    name: parent[0].name,
                    path: parent[0].path,
                    data: data,
                    totalSize: listSize,
                };
            },
            [MEDIA_TYPE.Project]: (parent, list, listSize) => {
                const data = [];
                list.map((m, index) => {
                    data.push({
                        path: m.path,
                        name: m.name,
                        cover: {url: get(m, 'thumbnail.url')},
                        defaultThumbnail: m.ap,
                        order: index,
                        distinctPath: m.path + 'list' + m.sort + currName + index + m.pageIndex,
                        dataType: 'list/project',
                        _pcn: currName,
                        sort: m.sort,
                        mediaType: 'Project',
                        totalSize: m.totalSize,
                        pageIndex: m.pageIndex ? m.pageIndex : 0,
                    });
                });
                // logger.log(`LIST_${API_TYPE.CLOUD}_${MEDIA_TYPE.Project}`, parent, list, listSize, data);
                return {
                    name: parent[0].name,
                    path: parent[0].path,
                    data: data,
                    totalSize: listSize,
                };
            },
        }
    },
    [DATA_TYPE.DETAIL]: {
        [API_TYPE.MEDIA_SERVER]: {
            [MEDIA_TYPE.Movie]: (parent, detail, detailSize) => {
                // logger.log(`Detail_${API_TYPE.MEDIA_SERVER}_${MEDIA_TYPE.Movie}`, parent, detail, detailSize);
                const data = {
                    path: detail[0].path,
                    title: detail[0].tags.title || detail[0].tags.name,
                    rating: detail[0].tags.ml_rating,
                    contentRating: detail[0].tags.ml_contentRating,
                    cover: {url: detail[0].tags.ml_user_thumbPath || detail[0].tags.ml_thumbPath || detail[0].tags.thumbPath},
                    releaseDate: detail[0].tags.ml_releaseDate,
                    genre: detail[0].tags.ml_genre,
                    directors: detail[0].tags.ml_director,
                    studio: detail[0].tags.ml_studio,
                    screenPlay: detail[0].tags.ml_screenplay,
                    synopsis: detail[0].tags.ml_synopsis,
                    casts: !detail[0].tags.castsDetail ? [] : detail[0].tags.castsDetail.map(c => ({
                        name: c.castName,
                        thumbnail: {url: c.thumbPath}
                    })),
                    original: {url: detail[0].tags.medias[1].url},
                    duration: detail[0].tags.duration,
                    resumeTime: detail[0].tags.resumeTime,
                    dataType: 'detail/movie',
                    _pcn: currName,
                    distinctPath: detail[0].path + 'detail'+currName,
                    attribute: '/detail/pms/movie'
                };
                return data;
            },
            [MEDIA_TYPE.TV]: (parent, detail, detailSize) => {
                // logger.log(`Detail_${API_TYPE.MEDIA_SERVER}_${MEDIA_TYPE.TV}`, parent, detail, detailSize);
                const data = {
                    title: parent[0].tags.title || parent[0].tags.name,
                    // title: `${parent[0].tags.tvTitle || parent[0].tags.name} ${parent[0].tags.seasonNumber || ""}`,
                    rating: parent[0].tags.ml_rating,
                    contentRating: parent[0].tags.ml_contentRating,
                    cover: {url: parent[0].tags.ml_user_thumbPath || parent[0].tags.ml_thumbPath || parent[0].tags.thumbPath},
                    releaseDate: parent[0].tags.ml_releaseDate,
                    synopsis: parent[0].tags.ml_synopsis,
                    path: parent[0].path,
                    genre: null,
                    studio: null,
                    casts: !parent[0].tags.castsDetail ? [] : parent[0].tags.castsDetail.map(c => ({name: c.castName, thumbnail: {url: c.thumbPath}})),
                    episodes: detail.map(e => ({
                        title: e.tags.title,
                        thumbnail: {url: e.tags.thumbPath},
                        path: e.path,
                        original: {url: e.tags.medias[1].url},
                        duration: e.tags.duration,
                        resumeTime: e.tags.resumeTime,
                        episodeNumber: e.tags.episodeNumber
                    })),
                    resumeEpisode: parent[0].tags.resumeEpisode,
                    dataType: 'detail/tv',
                    _pcn: currName,
                    distinctPath: parent[0].path + 'detail'+currName,
                    attribute: '/detail/pms/tv'
                };
                return data;
            },
            [MEDIA_TYPE.Music]: (parent, detail, totalSize) => {
                // logger.log(`Detail_${API_TYPE.MEDIA_SERVER}_${MEDIA_TYPE.Music}`, detail, totalSize);
                const data = [];

                detail.map((m,index) => {
                    const musicFormat = [];
                    m.tags.medias.map(m => {
                        musicFormat.push({
                            format: m.url.split(".").pop(),
                            url: m.url,
                        });
                    });

                    data.push({
                        thumbnail: {url: m.tags.thumbPath},
                        artist: m.tags.artist,
                        albumTitle: m.tags.albumTitle || "Unknown Albums",
                        duration: m.tags.duration,
                        original: {url: m.tags.medias[0].url},
                        title: m.tags.title || m.tags.name,
                        trackNumber: m.tags.trackNumber,
                        musicFormatArray: musicFormat,
                        parent: m.parent || "Unknown Albums",
                        pageIndex: m.pageIndex || 0,
                        mediaType: m.mediaType,
                        order: index,
                        dataType: 'detail/music',
                        _pcn: currName,
                        distinctPath: m.path + 'detail' + currName,
                        attribute: '/detail/pms/music',
                        totalSize: m.totalSize
                    });
                });
                data.sort((a, b) => a.trackNumber - b.trackNumber);
                return data;
            },
        },
        [API_TYPE.CLOUD]: {
            [MEDIA_TYPE.Movie]: (parent, detail, source) => {
                // logger.log(`Detail_${API_TYPE.CLOUD}_${MEDIA_TYPE.Movie}`, parent, detail);
                if (source === DATA_SOURCE.SHARE) {
                    detail.resumeTime = 0;
                }
                if (detail) {
                    detail.title = detail.title || detail.name;
                    detail.cover = {url: get(detail, "customized.url") || get(detail, "cover.url") || get(detail, 'thumbnail.url')};
                    detail.distinctPath = detail.path + 'detail' + currName;
                    detail.dataType = 'detail/movie';
                    detail._pcn = currName;
                    return detail;
                }
            },
            [MEDIA_TYPE.TV]: (parent, detail, source) => {
                // logger.log(`Detail_${API_TYPE.CLOUD}_${MEDIA_TYPE.TV}`, parent, detail);
                if (detail) {
                    detail.episodes.sort((a, b) => parseInt(a.episodeNumber) - parseInt(b.episodeNumber));
                    detail.genre = null;
                    detail.studio = null;
                    if (source === DATA_SOURCE.SHARE) {
                        detail.episodes.map(e => e.resumeTime = 0);
                    }
                    detail.title = detail.title || `${detail.name} ${detail.seasonNumber || ""}`;
                    detail.cover = {url: get(detail, "customized.url") || get(detail, "cover.url") || get(detail, 'thumbnail.url')};
                    detail.distinctPath = detail.path + 'detail' + currName;
                    detail.dataType = 'detail/tv';
                    detail._pcn = currName;
                    return detail;
                }
            },
            [MEDIA_TYPE.Music]: (parent, detail, totalSize) => {
                // logger.log(`Detail_${API_TYPE.CLOUD}_${MEDIA_TYPE.Music}`, detail, totalSize);
                if(Array.isArray(detail)) {
                    detail.map((m,index) => {
                        m.musicFormat = m.original.url.split(".").pop();
                        m.distinctPath = m.path + 'detail' + currName;
                        m.dataType = 'detail/music';
                        m._pcn = currName;
                        m.mediaType = MEDIA_TYPE.Music;
                        m.totalSize = totalSize;
                        m.order = index;
                    });
                }else {
                    detail.musicFormat = detail.original.url.split(".").pop();
                    detail.mediaType = MEDIA_TYPE.Music;
                    detail.distinctPath = detail.path + 'detail' + currName;
                    detail.dataType = 'detail/music';
                    detail._pcn = currName;
                    detail.totalSize = totalSize;
                }
                return detail;
            },
            [MEDIA_TYPE.Video]: (parent, detail) => {
                const data = [];
                data.push({
                    path: detail.path,
                    title: detail.name || detail.title,
                    folder: false,
                    cover: {url: get(detail, 'thumbnail.url')},
                    playUrl: get(detail, 'original.url'),
                    revisionId: detail.revisionId,
                    distinctPath: detail.path + 'detail' + currName,
                    dataType: 'detail/video',
                    _pcn: currName
                });
                return {parentPath: parent.path, data, totalSize: detail.totalSize, timestamp: new Date().getTime()};
            },
            [MEDIA_TYPE.Photo]: (parent, detail) => {
                // logger.log(`Detail_${API_TYPE.CLOUD}_${MEDIA_TYPE.Photo}`, parent, detail);
                if(!Array.isArray(detail)) {
                    const item = [{
                        path: detail.path,
                        title: detail.name || detail.title,
                        folder: false,
                        cover: {url: get(detail, 'thumbnail.url')},
                        playUrl: get(detail, 'original.url'),
                        revisionId: detail.revisionId,
                        distinctPath: detail.path + 'detail' + currName,
                        dataType: 'detail/photo',
                        _pcn: currName,
                    }];
                    return item;
                }
                const data = [];
                data.push({
                    path: detail.path,
                    title: detail.title || detail.name,
                    folder: false,
                    cover: {url: get(detail, 'thumbnail.url')},
                    playUrl: get(detail, 'original.url'),
                    revisionId: detail.revisionId,
                    distinctPath: detail.path + 'detail' + currName,
                    dataType: 'detail/photo',
                    _pcn: currName,
                });
                return {parentPath: parent.path, data, totalSize: detail.totalSize, timestamp: new Date().getTime()};
            },
            [MEDIA_TYPE.Template]: (parent, detail) => {
                const data = [];
                data.push({
                    path: detail.path,
                    title: detail.name || detail.title,
                    folder: false,
                    cover: {url: get(detail, 'thumbnail.url')},
                    downloadUrl: get(detail, 'original.url'),
                    revisionId: detail.revisionId,
                    distinctPath: detail.path + 'detail' + currName,
                    dataType: 'detail/template',
                    _pcn: currName
                });
                return data;
            },
            [MEDIA_TYPE.Project]: (parent, detail) => {
                const data = [];
                data.push({
                    path: detail.path,
                    title: detail.name || detail.title,
                    folder: false,
                    cover: {url: get(detail, 'thumbnail.url')},
                    downloadUrl: detail.api,
                    revisionId: detail.revisionId,
                    distinctPath: detail.path + 'detail' + currName,
                    dataType: 'detail/project',
                    _pcn: currName
                });
                return data;
            },
        }
    },
    [DATA_TYPE.PLAY]: {
        [API_TYPE.MEDIA_SERVER]: {
            [MEDIA_TYPE.Movie]: (path, parent, detail) => {
                // logger.log(`Play_${API_TYPE.MEDIA_SERVER}_${MEDIA_TYPE.Movie}`, parent, detail);
                const cacheSetting = localStorage.getItem(getLastTwoPath(path));
                const settings = cacheSetting ? JSON.parse(cacheSetting) : DEFAULT_SETTING;
                const resolutionInfo = getResolutionSetting(detail[0].tags.resolutionY);
                const data = {
                    path,
                    parentPath: parent[0].path,
                    title: detail[0].tags.title || detail[0].tags.name,
                    playUrl: getPlayUrlFromPMS(detail[0], settings),
                    mediaUrl: `${detail[0].tags.medias[1].url}?uuid=${sessionService.getUUID()}`,
                    duration: detail[0].tags.duration,
                    resumeTime: (Math.floor(detail[0].tags.resumeTime/10000000) > (detail[0].tags.duration*0.9))? 0: detail[0].tags.resumeTime,
                    audioTrackInfo: detail[0].tags.audioTrackInfo,
                    subtitleTrackInfo: detail[0].tags.subtitleTrackInfo,
                    externalSubtitleInfos: detail[0].tags.externalSubtitleInfos,
                    mediaResolution: {x: detail[0].tags.medias[1].resolutionX, y: detail[0].tags.medias[1].resolutionY},
                    resolutionInfo: resolutionInfo,
                    settings,
                    prev: undefined,
                    next: undefined,
                    dataType: 'play/movie',
                    _pcn: currName,
                    distinctPath: path + 'play' + currName,
                    attribute: '/play/pms/movie',
                    orientation: ORIENTATION_CODEC[detail[0].tags.orientation||1],
                };

                return data;
            },
            [MEDIA_TYPE.TV]: (path, parent, detail, formatMessage) => {
                let num = detail.findIndex((e, i) => {
                    // if last episode and less than 1 min, resumeEpisode use "" rather than parent resumeEpisode
                    const resumeEpisode = (i === detail.length-1 && Math.floor(e.tags.resumeTime / 10000000) > (e.tags.duration - 60))? "" : parent[0].tags.resumeEpisode;
                    return e.path === (path === parent[0].path ? resumeEpisode : path)
                });
                num = num < 0 ? 0 : num;
                const episode = detail[num];
                const cacheSetting = localStorage.getItem(getLastTwoPath(parent[0].path));
                const settings = cacheSetting ? JSON.parse(cacheSetting) : DEFAULT_SETTING;
                if(settings.subtitle && isNaN(settings.subtitle)) {
                    settings.subtitle = getPreferSubtitleForTV(settings.subtitle, episode.tags.name, episode.tags.externalSubtitleInfos, episode.tags.episodeNumber);
                }
                const resolutionInfo = getResolutionSetting(detail[0].tags.resolutionY);
                const data = {
                    path: episode.path,
                    parentPath: parent[0].path,
                    title: `${parent[0].tags.title || parent[0].tags.name} - ${formatMessage({id: "general.episode"}, {num: episode.tags.episodeNumber})}`,
                    // title: `${parent[0].tags.tvTitle || parent[0].tags.name} ${parent[0].tags.seasonNumber || ""} - ${formatMessage({id: "general.episode"}, {num: episode.tags.episodeNumber})}`,
                    playUrl: getPlayUrlFromPMS(episode, settings),
                    mediaUrl: `${episode.tags.medias[1].url}?uuid=${sessionService.getUUID()}`,
                    duration: episode.tags.duration,
                    resumeTime: (Math.floor(episode.tags.resumeTime / 10000000) > (episode.tags.duration - 60)) ? 0: episode.tags.resumeTime,
                    audioTrackInfo: episode.tags.audioTrackInfo,
                    subtitleTrackInfo: episode.tags.subtitleTrackInfo,
                    externalSubtitleInfos: episode.tags.externalSubtitleInfos,
                    mediaResolution: {x: episode.tags.medias[1].resolutionX, y: episode.tags.medias[1].resolutionY},
                    resolutionInfo: resolutionInfo,
                    settings,
                    prev: detail[num - 1] ? detail[num - 1].path : undefined,
                    next: detail[num + 1] ? detail[num + 1].path : undefined,
                    dataType: 'play/tv',
                    _pcn: currName,
                    distinctPath: episode.path + 'play' + currName,
                    attribute: '/play/pms/tv',
                    orientation: ORIENTATION_CODEC[episode.tags.orientation||1],
                };
                // logger.log(`Play_${API_TYPE.MEDIA_SERVER}_${MEDIA_TYPE.TV}`, {path, parent, detail}, data);

                return data;
            },
            [MEDIA_TYPE.Video]: (path, parent, detail) => {
                // logger.log(`Play_${API_TYPE.MEDIA_SERVER}_${MEDIA_TYPE.Video}`, parent, detail);
                let num = detail.findIndex(e => e.path === path);
                num = num < 0 ? 0 : num;
                const episode = detail[num];
                const parentPath = parent[0].path.split("/").splice(0, 5).join("/") + "/";
                const cacheSetting = localStorage.getItem(getLastTwoPath(parentPath));
                const settings = cacheSetting ? JSON.parse(cacheSetting) : DEFAULT_SETTING;
                let resolutionInfo = getResolutionSetting(episode.tags.resolutionY);
                const data = {
                    path,
                    parentPath,
                    title: episode.tags.name,
                    playUrl: getPlayUrlFromPMS(episode, settings),
                    mediaUrl: `${episode.tags.medias[1].url}?uuid=${sessionService.getUUID()}`,
                    duration: episode.tags.duration,
                    audioTrackInfo: episode.tags.audioTrackInfo,
                    subtitleTrackInfo: episode.tags.subtitleTrackInfo,
                    externalSubtitleInfos: episode.tags.externalSubtitleInfos,
                    mediaResolution: {x: episode.tags.medias[1].resolutionX, y: episode.tags.medias[1].resolutionY},
                    resolutionInfo: resolutionInfo,
                    settings,
                    resumeTime: 0,
                    prev: detail[num - 1] ? detail[num - 1].path : undefined,
                    next: detail[num + 1] ? detail[num + 1].path : undefined,
                    dataType: 'play/video',
                    _pcn: currName,
                    distinctPath: path + 'play'+currName,
                    attribute: '/play/pms/video',
                    // orientation: episode.tags.orientation,
                    orientation: ORIENTATION_CODEC[episode.tags.orientation||1],
                };
                return data;
            },
            [MEDIA_TYPE.Photo]: (path, parent, detail) => {
                // logger.log(`List_${API_TYPE.MEDIA_SERVER}_${MEDIA_TYPE.Photo}`, path, parent, detail);
                const data = [];
                detail.map(m => {
                    data.push({
                        path: m.path,
                        title: m.tags.name,
                        folder: false,
                        cover: {url: getPhotoCoverforPMS(m).url},
                        playUrl: getPhotoFromPMS(m),
                        dataType: 'play/photo',
                        _pcn: currName,
                        distinctPath: m.path + 'play'+currName,
                        attribute: '/play/pms/photo'
                    });
                });

                return {name: parent[0].name, parentPath: parent[0].path, data: data, totalSize: detail.totalSize};
            },
        },
        [API_TYPE.CLOUD]: {
            [MEDIA_TYPE.Movie]: (path, parent, detail) => {
                // logger.log(`Play_${API_TYPE.CLOUD}_${MEDIA_TYPE.Movie}`, parent, detail);
                const cacheSetting = localStorage.getItem(getLastTwoPath(path));
                const settings = cacheSetting ? JSON.parse(cacheSetting) : DEFAULT_SETTING;
                const item = {
                    path,
                    parentPath: parent.path,
                    title: detail.title || detail.name,
                    playUrl: detail.original.url,
                    duration: detail.duration,
                    resumeTime: (Math.floor(detail.resumeTime/10000000) > (detail.duration*0.9))? 0: detail.resumeTime,
                    audioTrackInfo: [],
                    subtitleTrackInfo: [],
                    externalSubtitleInfos: detail.subtitles,
                    mediaResolution: {x: detail.original.resolutionX, y: detail.original.resolutionY},
                    resolutionInfo: [],
                    settings: settings,
                    prev: undefined,
                    next: undefined,
                    distinctPath: path + 'play' + currName,
                    dataType: 'play/movie',
                    _pcn: currName,
                    orientation: ORIENTATION_CODEC[detail.orientation||1],
                };

                return item;
            },
            [MEDIA_TYPE.TV]: (path, parent, detail, formatMessage) => {
                // logger.log(`Play_${API_TYPE.CLOUD}_${MEDIA_TYPE.TV}`, parent, detail);
                if (detail) {
                    detail.episodes.sort((a, b) => parseInt(a.episodeNumber) - parseInt(b.episodeNumber));
                    let num = detail.episodes.findIndex(e => e.path === (path === parent.path ? detail.resumeEpisode : path));
                    num = num < 0 ? 0 : num;
                    const episode = detail.episodes[num];
                    const item = {
                        path: episode.path,
                        parentPath: parent.path,
                        title: `${detail.title || detail.name} ${!detail.title && detail.seasonNumber || ""} - ${formatMessage({id: "general.episode"}, {num: episode.episodeNumber})}`,
                        playUrl: episode.original.url,
                        duration: episode.duration,
                        resumeTime: (Math.floor(episode.resumeTime/10000000) > (episode.duration-60))? 0: episode.resumeTime,
                        audioTrackInfo: [],
                        subtitleTrackInfo: [],
                        externalSubtitleInfos: episode.subtitles,
                        mediaResolution: {x: episode.original.resolutionX, y: episode.original.resolutionY},
                        resolutionInfo: [],
                        settings: DEFAULT_SETTING,
                        prev: detail.episodes[num - 1] ? detail.episodes[num - 1].path : undefined,
                        next: detail.episodes[num + 1] ? detail.episodes[num + 1].path : undefined,
                        distinctPath: episode.path + 'play' + currName,
                        dataType: 'play/tv',
                        _pcn: currName,
                        orientation: ORIENTATION_CODEC[detail.orientation||1],
                    };

                    return item;
                }
            },
            [MEDIA_TYPE.Video]: (path, parent, detail, collectionList) => {
                // logger.log(`Play_${API_TYPE.CLOUD}_${MEDIA_TYPE.Video}`, parent, detail, collectionList);
                let num = collectionList.findIndex(e => e.path === path);
                num = num < 0 ? 0 : num;
                const episode = collectionList[num];
                const item = {
                    path,
                    parentPath: parent.path,
                    title: detail.name || detail.title,
                    playUrl: detail.original.url,
                    duration: detail.duration,
                    resumeTime: detail.resumeTime ? detail.resumeTime : 0,
                    audioTrackInfo: [],
                    subtitleTrackInfo: [],
                    externalSubtitleInfos: [],
                    mediaResolution: {x: detail.original.resolutionX, y: detail.original.resolutionY},
                    resolutionInfo: [],
                    settings: DEFAULT_SETTING,
                    prev: collectionList[num - 1] ? collectionList[num - 1].path : undefined,
                    next: collectionList[num + 1] ? collectionList[num + 1].path : undefined,
                    distinctPath: path + 'play' + currName,
                    dataType: 'play/video',
                    _pcn: currName,
                    orientation: ORIENTATION_CODEC[detail.orientation||1],
                };

                return item;
            },
            [MEDIA_TYPE.Photo]: (path, parent, detail) => {
                // logger.log(`List_${API_TYPE.CLOUD}_${MEDIA_TYPE.Photo}`, parent, detail);
                const data = [];
                detail.map((m,index) => {
                    data.push({
                        path: m.path,
                        title: m.name || m.title,
                        folder: false,
                        cover: {url: get(m, 'thumbnail.url')},
                        playUrl: get(m, 'original.url'),
                        revisionId: m.revisionId,
                        distinctPath: m.path + 'play' + currName,
                        dataType: 'play/photo',
                        _pcn: currName,
                    });
                });

                return {parentPath: parent.path, data: data, totalSize: detail.totalSize};
            },
            [MEDIA_TYPE.Music]: (parent, detail, totalSize) => {
                // logger.log(`Detail_${API_TYPE.CLOUD}_${MEDIA_TYPE.Music}`, detail, totalSize);
                if(Array.isArray(detail)) {
                    detail.map(m => {
                        m.musicFormat = m.original.url.split(".").pop();
                        m.distinctPath = m.path + 'play' + currName;
                        m.dataType = 'play/music';
                        m._pcn = currName;
                    });
                }else {
                    detail.musicFormat = detail.original.url.split(".").pop();
                    detail.distinctPath = detail.path + 'play' + currName;
                    detail.dataType = 'play/music';
                    detail._pcn = currName;
                }

                return detail;
            },
        }
    }
};
