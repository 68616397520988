import React from "react";
import classNames from "classnames";
import {FormattedMessage} from 'react-intl';
import {getGAObject} from "./componentUtils";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';

const styles = theme => ({

});

export default class SubmitButton extends React.Component {
    render() {
        const {title, timerShouldHide, onClick, isButton, gaEvent, className, id, disabled} = this.props;
        return (
            <Button type={isButton ? "button" : "submit"} className={className} variant="contained" id={id}
                         {...getGAObject(gaEvent)} onClick={onClick} disabled={disabled}>
                {timerShouldHide &&
                    <span>{title.hasOwnProperty("key") ? <FormattedMessage id={title.key} /> : title.string}</span>
                }
                {!timerShouldHide && <CircularProgress color="inherit" style={{margin: "3px 0 4px"}} size={21} />}
            </Button>
        );
    }
}
