import React, {useMemo, useState} from 'react';
import {withRouter} from "react-router";
import {getConfigByUrl} from "pageConfig";
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Divider from "@material-ui/core/Divider";
import InputBase from "@material-ui/core/InputBase";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import List from "@material-ui/core/List";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {Fade, MenuItem} from "@material-ui/core";
import {useFormInput, useThemeMediaQuery} from "../components/componentUtils";
import {getSSOToken, logout} from "../services/user/userService";
import {useIntl} from "react-intl";
import sessionService from "../services/user/sessionService";
import LightBox from "./LightBox";
import langsUtils from "../utils/langsUtils";
import {DIALOG_CONFIG, BUILD, BUILD_TYPE} from "../utils/constants"
import MenuIcon from '@material-ui/icons/Menu';
import {useDispatch, useSelector} from "react-redux";
import {SIDEBAR_ACTIONS} from "../services/data/sideBarReducer";
import Hidden from '@material-ui/core/Hidden';
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {encode, getPrefixPath} from "../utils/dataUtils";
import LangDialog from "../components/LangDialog";

const Header = props => {
    const {classes} = props;
    const {formatMessage: f} = useIntl();
    const showHeader = useMemo(() => {
        return getConfigByUrl(props.location.pathname).header
    }, [props.location.pathname]);
    const [open, setOpen] = useState(false);
    const [langBoxOpen, setLangBoxOpen] = useState(false);
    const searchKey = useFormInput('');
    const dispatch = useDispatch();
    const isOpen = useSelector(state => state.sideBar.open);
    const connectionError = useSelector(state => state.user.connectionError);
    const [searchBarOpen, setSearchBarOpen] = useState(false);
    const {mobile} = useThemeMediaQuery("xs");
    const [inputRef, setInputRef] = useState(undefined);
    const [backPage, setBackPage] = useState(undefined);
    const errorDialogueConfig = {
        onClose: BUILD !== BUILD_TYPE.pms ?
            () => {
                sessionService.destroyServerData();
                window.location.href = (location.protocol === "http:") ?
                    `http://${location.hostname}/select`:
                    `http://${location.hostname}/select/${encode(JSON.stringify(sessionService.getUnDestroyedInfo()))}`;
            }: undefined
    };

    const handleClick = () => {
        setOpen(prev => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    if (!showHeader) {
        return null;
    }

    const menu = [
        {
            name: f({id: "header.dropdown.tutorial"}),
            id: "view_tutorial",
            action: () => {
                window.open(`https://${langsUtils.getLangParam(sessionService.getLang()).domain}.cyberlink.com/learning/powerdvd-ultra`);
                setOpen(false);
            },
            isOuterLink: true,
            showBuild: ["cpp", "pms", "clCloud"]
        },
        {
            name: f({id: "header.dropdown.switch-source"}),
            id: "switch_source",
            action: async () => {
                // if login from SSO, then call sso login in "http"
                if (sessionService.getLoginType() === "sso") {
                    const sso = await getSSOToken();
                    sessionService.destroyServerData();
                    if (sso.token) window.location.href = `http://${location.hostname}/sso/${encodeURIComponent(sso.token)}`;
                    // if (sso.token) window.location.href = `http://${location.hostname}/select/${encode(JSON.stringify({...sessionService.getUnDestroyedInfo(), sso}))}`;
                }else {
                    sessionService.destroyServerData();
                    window.location.href = (location.protocol === "http:") ?
                        `http://${location.hostname}/select`:
                        `http://${location.hostname}/select/${encode(JSON.stringify(sessionService.getUnDestroyedInfo()))}`;
                }
                setOpen(false);
            },
            showBuild: ["cpp"]
        },
        {
            name: f({id: "header.dropdown.switch-languages"}),
            action: () => {
                setLangBoxOpen(true);
                setOpen(false);
            },
            showBuild: ["cpp", "pms", "clCloud"]
        },
        {
            name: f({id: "header.dropdown.members-zone"}),
            action: () => {
                window.open(`https://membership.cyberlink.com/prog/member/service/index.do?lang=${langsUtils.getLangParam(sessionService.getLang()).param}`);
                setOpen(false);
            },
            isOuterLink: true,
            showBuild: ["cpp", "clCloud"]
        },
        {
            name: f({id: "header.dropdown.sign-out"}),
            id: "signout",
            action: async () => {
                await logout();
                window.location.href = `${location.protocol === "http:" ? "https" : "http"}://${location.hostname}/signout/${encode(JSON.stringify(sessionService.getUnDestroyedInfo()))}`;
                // window.location.href = `${location.protocol === "http:" ? "https" : "http"}://${location.hostname}/signout`;
                setOpen(false);
            },
            showBuild: ["cpp", "clCloud"]
        },
    ];

    function renderSearchInput() {
        return(
            <div className={classes.search}>
                <img className={classes.searchIcon} src={require(`../assets/images/main/icon_search@2x.png`)}
                     onClick={handleSearch} alt=""/>
                <form onSubmit={handleSearch} action="" style={{width: "100%"}}>
                    <InputBase
                        id="searchInput"
                        // onKeyPress={handleSearch}
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{'aria-label': 'search'}}
                        {...searchKey}
                        type={"search"}
                        placeholder={f({id: "header.search-input.placeholder"})}
                        inputRef={ref => setInputRef(ref)}
                    />
                </form>
            </div>
        )
    }

    const handleSearch = (evt) => {
        // if ((evt.type==="keypress" && evt.key === 'Enter') || (evt.type==="click" && searchBarOpen)) {
        evt.preventDefault();
        if (!searchKey.value || searchKey.value.trim() === "") {
            searchKey.onChange({target: {value: ""}});
        } else {
            searchKey.onChange({target: {value: searchKey.value.trim()}});
            props.history.push(getPrefixPath(`/PDVD/Search/${encodeURIComponent(searchKey.value.trim())}`));
        }
        inputRef.blur();
    };
    
    function renderLogo() {
        if (BUILD === BUILD_TYPE.clCloud) return (
          <div className={classes.logoText} onClick={() => props.history.push(getPrefixPath("/home"))} > Cyberlink Cloud </div>
        )
        else
            return (
          <img src={require(`../assets/images/main/CPP_web_logo@2x.png`)} className={classes.logoImg2} alt=""
               onClick={() => props.history.push(getPrefixPath("/home"))}/>
        )
    }

    return (
        <AppBar position="fixed" classes={{colorPrimary: classes.colorPrimary}} className={classes.appBar}>
            <Fade in={!searchBarOpen || !mobile}>
                <Toolbar className={classes.toolbar}>
                    <Hidden mdUp>
                        <div className={classes.logo}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={()=> dispatch({type: SIDEBAR_ACTIONS[isOpen? "CLOSE": "OPEN"]}) }
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>
                            {renderLogo()}
                        </div>
                    </Hidden>
                    <Hidden smDown>
                        <IconButton id="home" onClick={() => props.history.push(getPrefixPath("/home"))} className={classes.homeButton}>
                            <img className={classes.homeIcon} src={require(`../assets/images/main/btn_home_n@2x.png`)} alt=""/>
                        </IconButton>
                    </Hidden>

                    <Hidden xsDown> {renderSearchInput()} </Hidden>
                    <Hidden smUp>
                        <IconButton style={{marginLeft: "auto"}} onClick={() => {
                            setSearchBarOpen(true);
                            setBackPage(props.location.pathname);
                            // setTimeout(()=> inputRef.focus(), 50);
                            inputRef.focus()
                        }}>
                            <img className={classes.searchIcon} src={require(`../assets/images/main/icon_search@2x.png`)} alt=""/>
                        </IconButton>
                    </Hidden>

                    <ClickAwayListener onClickAway={handleClickAway}>
                        <div className={classes.profile}>
                            <Hidden xsDown>
                                <div className={classes.holder} id="option" onClick={handleClick}>
                                    <Avatar className={classes.avatar} src={require("../assets/images/main/icon_profile_default@2x.png")} alt="profile"/>
                                    <Typography variant="subtitle1" className={classes.name} >{sessionService.getName()}</Typography>
                                    <img src={require(`../assets/images/main/icon_dropdown@2x.png`)} alt="" width={13}
                                         style={open? {transform: "rotate(180deg)"}: null}/>
                                </div>
                            </Hidden>
                            <Hidden smUp>
                                <IconButton color="inherit" onClick={handleClick} >
                                    <MoreVertIcon />
                                </IconButton>
                            </Hidden>
                            <Fade in={open}>
                                <List component="nav" disablePadding id="navbar">
                                    <Hidden smUp>
                                        <div className={classes.holder}>
                                            <Avatar className={classes.avatar} src={require("../assets/images/main/icon_profile_default@2x.png")} alt="profile"/>
                                            <Typography variant="subtitle1" >{sessionService.getName()}</Typography>
                                        </div>
                                    </Hidden>
                                    {menu.filter(item => item.showBuild.indexOf(BUILD) !== -1).map(item => (
                                        <MenuItem key={item.name} id={item.id} onClick={item.action} button
                                                  disableGutters>
                                            {item.name}
                                            {item.isOuterLink && <OpenInNewIcon className={classes.openInNewIcon}/>}
                                        </MenuItem>
                                    ))}
                                </List>
                            </Fade>
                        </div>
                    </ClickAwayListener>
                </Toolbar>
            </Fade>

            {/*<Fade in={searchBarOpen && mobile}>*/}
                <Toolbar className={classes.toolbar} style={{position: "absolute", top: searchBarOpen && mobile? 0: "-300px"}}>
                    <IconButton color="inherit" onClick={(e) => {
                        setSearchBarOpen(false);
                        props.history.push(backPage);
                        searchKey.onChange({target: {value: ""}});
                    }}>
                        <img id="goBack" className={classes.backBtn} src={require("../assets/images/movie/btn_backto_n@2x.png")} alt="" />
                    </IconButton>
                    {renderSearchInput()}
                </Toolbar>
            {/*</Fade>*/}
            <Divider/>
            <LangDialog open={langBoxOpen} handleClose={() => setLangBoxOpen(false)} />
            <LightBox open={connectionError} {...DIALOG_CONFIG.connectionError} handleClose={errorDialogueConfig.onClose} />
        </AppBar>
    )
};

export default withRouter(withStyles(theme => ({
    appBar: {
        // width: `calc(100% - ${theme.constant.drawerWidth})`,
        // marginLeft: theme.constant.drawerWidth,
        // zIndex: 2000,
    },
    toolbar: {
        padding: "10px 30px",
        flexGrow: 1,
        marginLeft: theme.constant.drawerWidth,
        // width: `calc(100% - ${theme.constant.drawerWidth}px)`,
        height: theme.constant.headerHeight,
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            marginLeft: 0,
            height: theme.constant.headerHeightSm,
        },
        [theme.breakpoints.down('xs')]: {
            padding: "10px 15px",
        },
    },
    logo: {
        display: "flex",
        color: "#FFF",
        alignItems: "center",
    },
    menuButton: {
        display: "none",
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    logoImg2: {
        width: 146,
        [theme.breakpoints.down('sm')]: {
            width: 120,
        },
        cursor: "pointer"
    },
    logoText: {
        fontSize: 18,
        cursor: "pointer",
        whiteSpace: "nowrap",
    },
    colorPrimary: {
        color: "#fff",
        backgroundColor: "#1D1D1D",
    },
    homeButton: {
        padding: "12px",
    },
    homeIcon: {
        width: "40px",
    },
    search: {
        borderRadius: "30px",
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:focus-within': {
            backgroundColor: theme.palette.action.hover,
        },
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        // width: '100%',
        flexGrow: 1,
        flexBasis: "300px",
        maxWidth: "586px",
        display: "flex",
        padding: "5px 20px",
        transition: "0.3s cubic-bezier(0.4, 0, 0.2, 1)",
        [theme.breakpoints.down('xs')]: {
            flexDirection: "row-reverse",
            marginRight: 0,
        },
    },
    inputRoot: {
        color: 'inherit',
        width: "100%",
        height: '100%',
    },
    inputInput: {
        padding: "0 10px",
        width: '100%',
    },
    searchIcon: {
        width: 38,
        height: 38,
        display: "block",
        [theme.breakpoints.down('xs')]: {
            cursor: "pointer"
        },
    },
    profile: {
        marginLeft: "auto",
        [theme.breakpoints.down('xs')]: {
            marginLeft: "unset"
        },
        '& nav': {
            position: "absolute",
            paddingBottom: "5px",
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.primary.light,
            right: "25px",
            '&:before': {
                content: '""',
                position: "absolute",
                top: "-20px",
                right: "25px",
                borderBottom: `10px solid ${theme.palette.primary.light}`,
                borderRight: "8px solid transparent",
                borderLeft: "8px solid transparent",
                borderTop: "10px solid transparent",
                zIndex: 10,
            }
        },
    },
    layer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        cursor: "pointer"
    },
    openInNewIcon: {
        fontSize: 14,
        marginLeft: 5
    },
    // profile
    holder: {
        position: 'relative',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        padding: "0 5px",
        [theme.breakpoints.down('xs')]: {
            cursor: "auto",
            padding: "10px 35px",
            backgroundColor: "rgba(240, 240, 240, 0.13)",
            borderRadius: "6px 6px 0 0",
            justifyContent: "flex-start",
        },
        "& img": {
            transition: theme.constant.transition(0.1),
        }
    },
    avatar: {
        marginRight: 15,
    },
    name: {
        marginRight: 10,
        // flexShrink: 0,
    },
    // for search bar
    backBtn: {
        width: 25
    },
}))(Header));
