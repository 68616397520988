export const PINCODE_ACTIONS = {
    ADD: `PINCODE_ACTIONS_ADD`,
    REMOVE: `PINCODE_ACTIONS_REMOVE`,
};

const initialState = {
    map: {}
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case PINCODE_ACTIONS.ADD:
            return {
                ...state,
                map: {
                    ...state.map,
                    [action.path]: action.pincode
                }
            };
        case PINCODE_ACTIONS.REMOVE:
            const newState = Object.assign({}, state);
            delete newState[action.path];
            return newState;
        default:
            return state;
    }
}
