import config from 'config';
import {encode, decode} from "../../utils/dataUtils";
import logger from "logger";

class SessionCacheService {
    set(key, value) {
        if(sessionStorage) {
            sessionStorage.setItem(key, encode(value));
        }
    }

    get(key) {
        if(!sessionStorage) {
            return null;
        }
        const result = sessionStorage.getItem(key);
        return result ? decode(result) : null;
    }

    remove(key) {
        if (this.get(key)) {
            sessionStorage.removeItem(key);
        }
    }

    destroy() {
        sessionStorage.clear();
    }
}

const sessionCacheService = new SessionCacheService();

export default sessionCacheService;
