/**
 * Created by Karen_Yeh on 2017/4/27.
 */
// import 'intl/locale-data/jsonp/de';
// import 'intl/locale-data/jsonp/en';
// import 'intl/locale-data/jsonp/es';
// import 'intl/locale-data/jsonp/zh';
// import 'intl/locale-data/jsonp/fr';
// import 'intl/locale-data/jsonp/pt';
// import 'intl/locale-data/jsonp/ja';
// import 'intl/locale-data/jsonp/ko';
// import 'intl/locale-data/jsonp/ru';
// import 'intl/locale-data/jsonp/it';


import deDE from './langs/de_DE.json';
import enUS from './langs/en_US.json';
import esES from './langs/es_ES.json';
import frFR from './langs/fr_FR.json';
import itIT from './langs/it_IT.json';
import jaJP from './langs/ja_JP.json';
import koKR from './langs/ko_KR.json';
import ptBR from './langs/pt_BR.json';
import ruRU from './langs/ru_RU.json';
import zhCN from './langs/zh_CN.json';
import zhTW from './langs/zh_TW.json';
import sessionService from "../services/user/sessionService";

const FULL_LANG_MAP = ["en", "en_US", "de", "de_DE", "es", "es_ES", "fr", "fr_FR", "it", "it_IT", "ja", "jp", "ja_JP",
  "ko", "kr", "ko_KR", "pt", "br", "pt_BR", "ru", "ru_RU", "zh", "cn", "zh_CN", "tw", "zh_TW"];
const LANG_PARAM_MAP = {
  "en_US": {domain: "www", param: "ENU"},
  "de_DE": {domain: "de", param: "DEU"},
  "es_ES": {domain: "es", param: "ESP"},
  "fr_FR": {domain: "fr", param: "FRA"},
  "it_IT": {domain: "it", param: "ITA"},
  "ja_JP": {domain: "jp", param: "JPN"},
  "ko_KR": {domain: "kr", param: "KOR"},
  "pt_BR": {domain: "www", param: "ENU"},
  "ru_RU": {domain: "www", param: "ENU"},
  "zh_CN": {domain: "cn", param: "CHS"},
  "zh_TW": {domain: "tw", param: "CHT"}
};

const getLangParam = (lang) => {
  return LANG_PARAM_MAP[lang] || LANG_PARAM_MAP["en_US"];
};

const getLanguage = () => {
  let lang = FULL_LANG_MAP.find(l => location.pathname.search(new RegExp(`/${l}/`, 'i')) >= 0) || sessionService.getLang();
  const locale = convertToValidLanguage(lang || navigator.language || navigator.browserLanguage);
  if(!sessionService.getLang()) {
    sessionService.generateInfo({ lang: locale.replace(/"/g, '').replace('-', '_') });
  }
  return locale.replace(/"/g, '').replace('_', '-');
};

const convertToValidLanguage = lang => {
  if(lang.toLowerCase().indexOf("tw") >= 0 || lang.toLowerCase().indexOf("hk") >= 0) {
    return "zh_TW";
  }
  if(lang.toLowerCase().indexOf("zh") >= 0) {
    return "zh_CN";
  }
  if(lang.toLowerCase().indexOf("jp") >= 0) {
    return "ja_JP"
  }
  if(lang.toLowerCase().indexOf("kr") >= 0) {
    return "ko_KR"
  }
  if(lang.toLowerCase().indexOf("br") >= 0) {
    return "pt_BR"
  }
  return Object.keys(LANG_PARAM_MAP).find(l => lang.substring(0,2) === l.substring(0,2)) || FULL_LANG_MAP[0];
};

const formatMessage = (msg, key) => {
  let result = msg;
  Object.entries(key).forEach(([k, v]) => {
    result = result.replace("{" + k + "}", v);
  });
  return result;
};

const loadMessages = language => {
  const lang = language.substring(0,2);

  switch (lang) {
    case 'de':
      return deDE;
    case 'es':
      return esES;
    case 'fr':
      return frFR;
    case 'it':
      return itIT;
    case 'ja':
      return jaJP;
    case 'ko':
      return koKR;
    case 'pt':
      return ptBR;
    case 'ru':
      return ruRU;
    case 'zh':
      if (language.search(/tw/i) >= 0 || language.search(/hk/i) >= 0) {
        return zhTW;
      } else {
        return zhCN;
      }
    default:
      return enUS;
  }
};

export default {
  formatMessage,
  getLanguage,
  loadMessages,
  getLangParam
};
