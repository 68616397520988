/**
 * Created by Jeffy_Chuang on 2019/5/10.
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {useSelector} from "react-redux";
import { FormattedMessage, useIntl } from 'react-intl';

import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

import sessionService from "../services/user/sessionService";
import sessionCacheService from "../services/user/sessionCacheService";
import { MUSIC_ACTIONS } from "../services/data/musicReducer";
import { DELETE_ACTION } from "../services/data/deleteReducer";
import { deleteContent } from "../services/data/cloudDataService";
import { API_TYPE, CONTENT_CONFIG, DATA_SOURCE, MEDIA_TYPE } from "../utils/constants";
import {getUserPackages} from "../services/user/userService";

export const getWordingFromConfig = (config) => {
    return config.hasOwnProperty("key") ? <FormattedMessage id={config.key} /> : config.string;
};

export const getWordingArray = (wordings) => {
    if(!wordings) {
        return "";
    }
    return wordings.split(",").map((w, i) => {
        if (w === "Others") w = "studio.others";
        if (i === 0) {
            return <FormattedMessage id={w} key={w + i}/>
        } else {
            return <FormattedMessage id={w} key={w + i}>{w => <span>{`, ${w}`}</span>}</FormattedMessage>
        }
    });
};

export const getGAObject = (gaEvent) => {
    const ga = {};
    if(gaEvent) {
        if(gaEvent.type) {
            ga["data-ga-event"] = gaEvent.type;
        }
        if(gaEvent.action) {
            ga["data-ga-action"] = gaEvent.action;
        }
        if(gaEvent.label) {
            ga["data-ga-label"] = gaEvent.label;
        }
    }
    return ga;
};

export const useFormInput = (init) => {
    const [value, setValue] = useState(init);
    function onChange(e) {
        setValue(e.target.value);
    }
    return {value, onChange};
};

export const useSingleCodeInput = (init) => {
    const [value, setValue] = useState(init);
    function onChange(e) {
        if (e.target.value !== "" && isNaN(Number(e.target.value))) return;
        setValue(e.target.value);
    }
    return {value, onChange};
};

export const useFormCheckBox = (init) => {
    const [checked, setChecked] = useState(init);
    function onChange(e) {
        setChecked(!checked);
    }
    return {checked, onChange, setChecked};
};

export const useFormRadio = (init) => {
    const [value, setValue] = useState(init);
    function onChange(e) {
        setValue(e.target.value);
    }
    return {value, onChange};
};

// const RESIZE_MAP = [704, 896, 1088, 1280, 1472, 1664, 1856, 2048, 2240];
export const useNumberOfItemByWidth = (mediaType, source, page) => {
    // need to calculate the resize map by content mediaType(with different width, reference by CONTENT_CONFIG)
    const [number, setNumber] = useState(null);
    useEffect(() => {
        function handleResize() {
            let RESIZE_MAP = calculateMap(mediaType, source, page);
            let result = 1;
            RESIZE_MAP.map(m => {
                if(window.document.documentElement.clientWidth > m) {
                    result++
                }
            });
            setNumber(result);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return number;
};

const INIT_MAP = {
    [DATA_SOURCE.OWN]: {"home": 325, "file": 385},
    [DATA_SOURCE.SHARE]: {"home": 85, "file": 145}
};
function calculateMap(mediaType, source="OWN", page="home"){
    // todos: detect init by pageConfig setting (have sidebar? have detail page margin? & RWD adjustment), should delete source/page parameter

    const margin = (mediaType === "PhotoGrid" || mediaType === "PhotoGallery") ? 0 : 30;
    const width = CONTENT_CONFIG[mediaType].width + margin;
    let init = INIT_MAP[source][page] - (window.document.documentElement.clientWidth<960 && source===DATA_SOURCE.OWN ? 240: 0);
    if(mediaType === "PhotoGallery") {
        init = 132;
    }
    // let resizeMap = [];
    // for(let i = init; i <= 7680; i += width) {
    //     resizeMap.push(i);
    // }
    // resizeMap.splice(0,2);

    // new number calculate rule
    let resizeMap = [];
    // if card zoom larger than 7/6 of original size, then it will be breakpoint, and num will +1(size will be smaller)
    for(let i = init; i <= 7680; i += ((width-margin)*(7/6) + margin)) {
        resizeMap.push(i);
    }
    resizeMap.splice(0,1);
    return resizeMap;
}

export const useVideoResolution = (mediaResolution, orientation) => {
    const [resolution, setResolution] = useState({width: "100%", height: "100%"});
    useEffect(() => {
        function handleResize() {
            if(!mediaResolution || !mediaResolution.x || !mediaResolution.y) {
                return;
            }
            const {innerWidth: winWidth, innerHeight: winHeight} = window;
            // const {x: mediaWidth, y: mediaHeight} = mediaResolution;
            const isPortrait = (orientation.rotate === -270 || orientation.rotate === -90);
            const mediaWidth = isPortrait? mediaResolution.y: mediaResolution.x;
            const mediaHeight = isPortrait? mediaResolution.x: mediaResolution.y;

            if(winWidth / winHeight >= mediaWidth / mediaHeight) {
                setResolution({width: mediaWidth / mediaHeight * winHeight, height: winHeight});
            }else {
                setResolution({width: winWidth, height: mediaHeight / mediaWidth * winWidth});
            }
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [mediaResolution]);
    return resolution;
};

export const useWindowFullScreen = () => {
    const [isWindowFullScreen, setIsWindowFullScreen] = useState(false);
    useEffect(() => {
        function handleResize() {
            setIsWindowFullScreen(screen.width - window.innerWidth === 0 && screen.height - window.innerHeight <= 38);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return isWindowFullScreen;
};

export function useSlider({goPrev, goNext}) {
    // animation
    const [translateX, setTranslateX] = useState(0);
    const [sliding, setSliding] = useState(false);
    const animationTime = 750;
    // touchEvent
    const [startX, setStartX] = useState(undefined);
    const [endX, setEndX] = useState(undefined);
    const [hasChangedPage, setHasChangedPage] = useState(false);
    return {
        translateX, setTranslateX, sliding, setSliding, animationTime,
        swipeEvent: {
            onTouchStart: e => {
                e.persist();
                setStartX(e.touches[0].clientX);
            },
            onTouchMove: e=>{
                if(sliding) return null;  // avoid lag
                e.persist();
                setEndX(e.touches[0].clientX);
                if (startX && endX && Math.abs(endX-startX)> 30 && !hasChangedPage){
                    (startX > endX)? goNext(): goPrev();
                    setHasChangedPage(true);
                }
            },
            onTouchEnd: e => {
                e.persist();
                setStartX(undefined); setEndX(undefined); setHasChangedPage(false);
            }
        },
        scrollEvent: {
            onWheel: e => {
                e.persist();
                if(sliding) {
                    return null;  // avoid lag
                }
                (e.deltaY>0)? goNext(): goPrev();
            }
        }
    }
}

export const useThemeMediaQuery = (breakpoint) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(breakpoint));
    return { theme, mobile };
};

export const copyToClipBoard = (params) => {
    window.getSelection().removeAllRanges();
    const {value} = params;
    let textArea = document.createElement("textarea");
    let range = document.createRange();
    document.body.appendChild(textArea);
    textArea.textContent = value;

    range.selectNode(textArea);
    window.getSelection().addRange(range);

    try{
        document.execCommand("copy");
    } catch(e){}

    window.getSelection().removeAllRanges();
    textArea.remove();
};

export const useContextMenu = () => {
    const apiType = useSelector(state => state.user.apiType);
    const [openMore, setOpenMore] = useState(false);
    const [path, setPath] = useState('');

    useEffect(() => {
        window.addEventListener('click', handleCloseMore);
        return () => {
            window.removeEventListener('click', handleCloseMore);
        };
    }, []);

    function handleCloseMore(){
        setOpenMore(false);
    }

    function handleOpenMore(e, url){
        e.stopPropagation();
        if (apiType !== API_TYPE.CLOUD || location.pathname.match(/\/pdvd\/share\//))
            return;
        setOpenMore(true);
        setPath(url);
    }

    return {
        openMore,
        handleOpenMore,
        handleCloseMore,
        path,
    };
};

export const useTimeout = (callback, timeout) => {
    const timeoutIdRef = useRef();
    const cancel = useCallback(
        () => {
            const timeoutId = timeoutIdRef.current;
            if(timeoutId){
                timeoutIdRef.current = undefined;
                clearTimeout(timeoutId);
            }
        },
        [timeoutIdRef]
    );

    useEffect(() => {
        timeoutIdRef.current = setTimeout(callback, timeout);
        return cancel;
    }, [callback, timeout, cancel]);

    return cancel;
};


export const useUnmounted = () => {
    const unmounted = useRef(false);
    useEffect(() => () => { unmounted.current = true }, []);

    return unmounted;
};

export const deleteMusicCheck = params => {
    const { deletePath, currMusic, dispatch } = params;
    const splitStr = deletePath.split("/");
    const album = splitStr[splitStr.length - 1];
    if (currMusic) {
        if (album === currMusic.albumTitle || deletePath === currMusic.path)
            dispatch({ type: MUSIC_ACTIONS.EXIT });
        else dispatch({ type: MUSIC_ACTIONS.UPDATE_PLAYLIST, deletePath });
    }
};

export const deleteData = async (params, deleteFrom='', dispatch=undefined) => {
    const accessToken = sessionService.getToken();
    const deleteParams = {
        ...params,
        accessToken,
        clientModified: Math.floor(new Date().getTime() / 1000),
        recursive: true
    };
    if (Boolean(deleteFrom && dispatch)) {
        dispatch({
            type: DELETE_ACTION.DELETE,
            url: (params.paths && params.paths[0]) || params.path,
            deleteFrom
        });
    }
    return await deleteContent(deleteParams);
};

export const handleMarkScrollPosition = params => () => {
    const { gridRef, mediaType } = params;
    const scrollTop = mediaType ? gridRef.state.scrollTop : gridRef.state.scrollOffset;

    sessionCacheService.set("scroll", JSON.stringify({path: location.pathname, scrollTop}));
};

export const useScrollToOffset = params => {
    const { finishLoading, sort, mediaType } = params;
    const [gridRef, setGridRef] = useState(undefined);
    const scroll = JSON.parse(sessionCacheService.get("scroll"));
    const execution = Boolean(mediaType) ? {
        condition: Boolean(gridRef),
        arrayDependency: [gridRef],
        offset: scroll && { scrollTop: scroll.scrollTop }
    } : {
        condition: Boolean(gridRef && finishLoading),
        arrayDependency: [gridRef, finishLoading],
        offset: scroll && scroll.scrollTop
    };

    // clear scroll when sort changed
    useEffect(() => {
        if (gridRef && sort) gridRef.scrollTo({ scrollTop: 0 });
    }, [sort && sort.name]);

    useEffect(() => {
        if (scroll && scroll.path === location.pathname && execution.condition) {
            gridRef.scrollTo(execution.offset);
        }
    }, execution.arrayDependency);

    return [gridRef, setGridRef];
};

export const useRenderContent = (params) => {
    const {
        collection,
        list,
        pageIndex,
        pageSize,
        source,
        sort,
        deletePath,
        updatePageIndex,
        num,
        location,
        filter
    } = params;
    const [skeleton, setSkeleton] = useState(true);
    const [col, setCol] = useState(collection);
    const [pIndex, setPIndex] = useState(pageIndex);
    const [finishLoading, setFinishLoading] = useState(false);
    const ratio = (c) => (c.cover.x < c.cover.y) ? 1 : (c.cover.x < 1.5 * c.cover.y) ? 2 : 2.5;
    const isShare = source === DATA_SOURCE.SHARE;
    const isPhotoGrid = location === MEDIA_TYPE.Photo;
    const isMusic = location === MEDIA_TYPE.Music;
    const isUsingCorrectPageIndex = list && list.data && list.data[0] && list.data.every(item => item.pageIndex === (isShare ? list.pageIndex : pIndex));
    const isValidList =
        isShare ?
            isPhotoGrid ?
                Boolean(num) :
                isUsingCorrectPageIndex
            : isUsingCorrectPageIndex;

    useEffect(() => {
        if (list.totalSize === 0) setFinishLoading(true);
    }, [list.timestamp]); // fix totalSize not change but also 0 case (filter)

    useEffect(() => {
        if (Boolean(sort && sort.name) || Boolean(deletePath) || Boolean(filter && filter.name)) {
            console.log("reset", "sort", pIndex)
            reset();
        }
    }, [sort && sort.name, deletePath, filter && filter.name]);

    useEffect(() => {
        if (!Boolean(pIndex)) {
            reset();
            console.log("reset", "pIndex change", pIndex)
        }
    }, [pIndex]);
    
    // useEffect(() => {
    //     console.log("pageIndex change", pageIndex)
    //     // setPIndex(pageIndex);
    // }, [pageIndex])

    useEffect(() => {
        if (isValidList) {
            let temp = col;
            const isEmpty = !Boolean(temp.data.length) && list.data.every(item => item.pageIndex === 1);
            const needIncrementIndex = ( isShare ? list.pageIndex : pIndex ) * pageSize < list.totalSize;
            const needReset = col.data && !col.data.every(c => c.totalSize === list.totalSize) && !isShare;

            if (isEmpty) {
                temp.data = Array(list.totalSize).fill(null);
            }

            for (let i = 0; i < list.data.length; i++) {
                temp.data[i + (list.pageIndex - 1) * (list.pageSize)] = list.data[i];
            }

            temp.data = temp.data.filter(t => Boolean(t));

            if (isPhotoGrid && !finishLoading) {
                list.data.reduce((acc, cur, i, array) => {
                    if (i + 1 >= array.length) {
                        temp.lastAcc = acc;
                        return;
                    }

                    const br = i + (list.pageIndex - 1) * (list.pageSize);
                    if (br <= temp.breakpoint[temp.breakpoint.length - 1]) {
                        temp.lastAcc = acc;
                        return acc;
                    }

                    if (acc + ratio(cur) + ratio(array[i+1]) > num) {
                        temp.breakpoint.push(br);
                        return 0;
                    }

                    return acc + ratio(cur);
                }, temp.lastAcc);
            }
            else if (isMusic) {
                temp.data.sort((a, b) => parseInt(a ? a.trackNumber || 99999 : 99999) - parseInt(b ? b.trackNumber || 99999 : 99999));
            }
            else if (isMusic && isShare) {
                temp.data.sort((a, b) => parseInt(a ? a.trackNumber || 99999 : 99999) - parseInt(b ? b.trackNumber || 99999 : 99999));
                temp.data.sort((a, b) => a.albumTitle < b.albumTitle? -1: 1);
            }

            setCol(temp);

            if (needIncrementIndex) {
                console.log("needIncrementIndex", pIndex);
                updatePageIndex && isShare ? updatePageIndex() : setPIndex(prev => ++prev);
            } else {
                setFinishLoading(true);
            }

            if (needReset) {
                console.log("need reset")
                setPIndex(null);
            }
        }
        const timeout = setTimeout(() => {setSkeleton(false)}, 150);
        return () => clearTimeout(timeout);
    }, [!!num, JSON.stringify(list.data), list.timestamp]);

    function reset() {
        setPIndex(1);
        setCol(isPhotoGrid ? {breakpoint: [], lastAcc: 0, data: []} : {data: []});
        setFinishLoading(false);
    }

    return {
        collection: col,
        pageIndex: pIndex,
        skeleton,
        finishLoading,
        timestamp: new Date().getTime()
    }
};

export const useLightBox = ({callback}) => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        callback && callback();
    };

    const handleOpen = () => setOpen(true);

    return { open, handleOpen, handleClose }
};

export const useCheckExpired = () => {
    const [open, setOpen] = useState(false);
    const [cloudStatus, setCloudStatus] = useState({});
    const initUserData = useSelector(state => state.user.initUserData);

    async function checkExpired() {
        // >90: 1600524800 / 30-90: 1610524800 / 0-30: 1612524800 / not expired: 1641772800 / not valid: null
        const result2 = {
            "subscription": {
                "valid": false,
                "expired": true,
                "expiryDate": 1610524800,
                "gracePeriodDay": 90
            },
            "ultraRedeem": {
                "valid": false,
                "expired": true,
                "expiryDate": 1600524800,
                "gracePeriodDay": 90
            },
            "essential": {
                "valid": true,
                "expired": true,
                "expiryDate": 1612524800,
                "gracePeriodDay": 30,
            },
            "serverTime": 1614911787
        };
        const result = await getUserPackages();
        let status = {};
        let blockChecking = false; // if have "valid && !expired", no need to check anymore
        Object.keys(result).forEach(plan => {
            const {valid, expired, expiryDate, gracePeriodDay} = result[plan];
            if (!valid || blockChecking) return;
            if (valid && !expired) blockChecking = true;
            status = {
                plan,
                expired,
                outOfGracePeriod: (plan === "essential")? expired: expired && result.serverTime > expiryDate + gracePeriodDay * 86400
            };
        });
        console.log("package status: ", status);
        setCloudStatus(status);
        return status;
    }

    function isCPP() {
        // check is CPP if user "subscription" expired (from "init" user product)
        const expireProducts = initUserData && initUserData.products.reduce((filtered, p) => {
            if (p.expiration < initUserData.serverTime + 90*86400) filtered.push(p.product);
            return filtered;
        }, []);
        const result = expireProducts.indexOf("PowerPlayer") >= 0 && expireProducts.indexOf("PowerDVD") < 0;
        return result;
    }

    function upgrade() {
        const URL = {
            ultra: "https://membership.cyberlink.com/prog/member/service/manage-subscription.do", // Ultra
            cpp: "https://www.cyberlink.com/products/powerplayer", // Subscription (CPP365)
            others: "https://www.cyberlink.com/products/powerdvd-ultra/features_en_US.html"  // Free user / Essential / Subscription (PDVD365)
        };
        window.open(cloudStatus.plan === "ultraRedeem" ? URL.ultra: (cloudStatus.plan === "subscription" && isCPP() ? URL.cpp : URL.others));
    }

    return ({ open, setOpen, checkExpired, upgrade, cloudStatus });
};