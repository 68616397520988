import * as idb from 'idb';
import logger from 'logger';
import { IDB, IDB_MISC } from '../utils/constants';

export const indexedDb = (function(){
    if(!("indexedDB" in window))return;

    const dbPromise = idb.openDB("clt", 2, {upgrade(db, oldVersion){
            switch(oldVersion){
                case 0:
                case 1:
                    logger.log('Creating the object store');
                    let msStore = db.createObjectStore('mediaServer',
                        {keyPath: 'distinctPath', autoIncrement: true}
                    );
                    let cStore = db.createObjectStore('cloud',
                        {keyPath: 'distinctPath', autoIncrement: true}
                    );
                    Object.keys(IDB[IDB_MISC.INDEX]).forEach(index => {
                       msStore.createIndex(IDB[IDB_MISC.INDEX][index].name, IDB[IDB_MISC.INDEX][index].keyParams);
                       cStore.createIndex(IDB[IDB_MISC.INDEX][index].name, IDB[IDB_MISC.INDEX][index].keyParams);
                    });
            }}
    });

    function addItem(items, objectStore){
        dbPromise.then(function(db){
            let tx = db.transaction([objectStore], 'readwrite');
            let store = tx.objectStore([objectStore]);
            return Promise.all(items.map(function(item){
                // return store.add(item) throws exception error
                return store.put(item);
            })).catch(function(){
                tx.abort();
                return Promise.resolve();
            }).then(function(){
                logger.log('ADDED');
                return Promise.resolve();
            });
        }).catch(function() {
            return Promise.resolve();
        });
    }

    function deleteItem(item, objectStore){
        dbPromise.then(function(db){
            let tx = db.transaction([objectStore], 'readwrite');
            let store = tx.objectStore([objectStore]);
            return store.delete(item).catch(function(e){
                logger.log('There has been an error with DELETING your data: ', e);
            }).then(function(){
                logger.log('DELETED');
            });
        })
    }

    function getByIndex(key, index, objectStore){
        let range = IDBKeyRange.only(key);
        let collection = [];
        let name = index;
        return dbPromise.then(function(db){
            let tx = db.transaction([objectStore], 'readonly');
            let store = tx.objectStore([objectStore]);
            let index = store.index(name);
            return index.openCursor(range);
        }).then(function fetchCollection(cursor){
            if(!cursor) return;
            collection.push(cursor.value);
            return cursor.continue().then(fetchCollection);
        }).then(function(){
            return collection;
        });
    }

    // function getByPath(key, objectStore){
    //     return dbPromise.then(function(db){
    //         let tx = db.transaction([objectStore], 'readonly');
    //         let store = tx.objectStore([objectStore]);
    //         let index = store.index('path');
    //         return index.get(key)
    //     }).catch(function(){
    //         return null;
    //     })
    // }

    // function updateMsStore(oldValue, newValue, objectStore){
    //     if(oldValue && newValue && JSON.stringify(oldValue) !== JSON.stringify(newValue)){
    //         dbPromise.then(function(db){
    //             let tx = db.transaction([objectStore], 'readwrite');
    //             let store = tx.objectStore([objectStore]);
    //             return store.put(newValue).catch(function(e){
    //                 logger.log("There has been an error with UPDATING your data: ", e);
    //             }).then(function(){
    //                 logger.log('UPDATED');
    //             })
    //         })
    //     }
    // }

    function deleteDatabase(){
        idb.deleteDB("clt").then(function(){return null});
    }

    return {dbPromise, addItem, deleteItem, deleteDatabase, getByIndex};
})();