export default function LaunchURI (uri, successCallback, noHandlerCallback) {
    var iframe, timeout, blurHandler, timeoutHandler, browser;

    var TIMEOUT_TIME = 1500;

    function callback(cb) {
        if (typeof cb === 'function') cb();
    }

    function createHiddenIframe(parent) {
        if (!parent) parent = document.body;
        iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        parent.appendChild(iframe);
        return iframe;
    }

    function removeHiddenIframe(iframe, parent) {
        if (!iframe) return;
        if (!parent) parent = document.body;
        parent.removeChild(iframe);
        iframe = null;
    }

    browser = {isChrome: false, isFirefox: false};

    if (window.chrome && !navigator.userAgent.match(/Opera|OPR\//)) {
        browser.isChrome = true;
    } else if (typeof InstallTrigger !== 'undefined') {
        browser.isFirefox = true;
    }

    // Proprietary msLaunchUri method (IE 10+ on Windows 8+)
    if (navigator.msLaunchUri) {
        navigator.msLaunchUri(uri, successCallback, noHandlerCallback);
    }
    // Blur hack (Chrome)
    else if (browser.isChrome) {
        location.href = uri;
        blurHandler = function () {
            window.clearTimeout(timeout);
            window.removeEventListener('blur', blurHandler);
            callback(successCallback);
        };
        timeoutHandler = function () {
            if (document.hasFocus()) {
                window.removeEventListener('blur', blurHandler);
                callback(noHandlerCallback);
            } else {
                window.removeEventListener('blur', blurHandler);
                callback(successCallback);
            }
        };
        window.addEventListener('blur', blurHandler);
        timeout = window.setTimeout(timeoutHandler, TIMEOUT_TIME);
    }else {
        blurHandler = function () {
            window.clearTimeout(timeout);
            window.removeEventListener('blur', blurHandler);
            callback(successCallback);
        };
        timeoutHandler = function () {
            if (document.hasFocus()) {
                window.removeEventListener('blur', blurHandler);
                callback(noHandlerCallback);
            } else {
                window.removeEventListener('blur', blurHandler);
                callback(successCallback);

            }
        };
        window.focus();
        window.addEventListener('blur', blurHandler);
        timeout = window.setTimeout(timeoutHandler, TIMEOUT_TIME);
        iframe = createHiddenIframe();
        iframe.contentWindow.location.href = uri;
        setTimeout(function () {
            removeHiddenIframe();
        }, TIMEOUT_TIME);
    }
}
