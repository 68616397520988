export const SIDEBAR_ACTIONS = {
    OPEN: "SIDEBAR_ACTIONS_OPEN",
    CLOSE: "SIDEBAR_ACTIONS_CLOSE",
};

const initialState = {
    open: true,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SIDEBAR_ACTIONS.OPEN:
            return {
                ...state,
                open: true,
            };
        case SIDEBAR_ACTIONS.CLOSE:
            return {
                ...state,
                open: false,
            };
        default:
            return state;
    }
}