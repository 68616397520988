import config from "config";
import logger from "logger";
import qs from 'query-string';
import sessionService from "../user/sessionService";
import axios from 'axios';
import {API_TYPE} from "../../utils/constants";
import {logout} from "../user/userService";
import sessionCacheService from "../user/sessionCacheService";
import {get} from "../../utils/dataUtils";

const singleton = Symbol();
const singletonEnforcer = Symbol();

const INTERNAL_APIS = {
    SIGN_COOKIE: "/pdvd/web/sign-cookies.action"
};

class DataApiService {
    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) {
            throw new Error('Cannot construct singleton');
        }
        this.token = sessionService.getToken();
        this.tokenType = sessionService.getTokenType();
        this.type = API_TYPE.UNKNOWN;
        this.cookieExpireTime = new Date().getTime();
        this.apiList = undefined;
        this.server = undefined;
        this.shareServer = undefined;
        this.expireTimer = undefined;
        this.session = axios.create({
            baseURL: config.DATA_API_HOST,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        this.session.interceptors.request.use(request => this.requestHandler(request), error => this.errorHandler(error));
        this.session.interceptors.response.use(response => this.responseHandler(response),error => this.errorHandler(error));
    }

    getApiFromKey(key) {
        return get(this.apiList, key);
    }

    getSignCookiesApi() {
        const nodeId = this.getApiFromKey("misc.nodeId");
        const api = this.getApiFromKey("misc.sign-cookies");
        if(!nodeId) {
            return api;
        }
        return `/${nodeId}${api.replace(this.server, "")}`;
    }

    async revokeCookie() {
        // Add checking if signCookie API exist, otherwise return
        if(!this.apiList || !this.getApiFromKey("misc.sign-cookies")) {
            return;
        }
        const EXPIRE_SAFE_TIME = 1000 * 60 * 10;
        const self = this;
        if(this.expireTimer) {
            clearTimeout(this.expireTimer);
        }
        this.expireTimer = setTimeout(() => { logger.log("timer check");self.revokeCookie(); }, EXPIRE_SAFE_TIME);
        const expireTtimeFromCache = sessionCacheService.get("cookieExpireTime");
        this.cookieExpireTime = expireTtimeFromCache ? parseInt(expireTtimeFromCache) : 0;
        // logger.log(this.cookieExpireTime, new Date().getTime(), new Date().getTime() - this.cookieExpireTime);
        if(this.cookieExpireTime - new Date().getTime() > EXPIRE_SAFE_TIME) {
            return;
        }
        try {
            const result = await axios.post(this.getSignCookiesApi(), qs.stringify({accessToken: this.token}), {withCredentials: true});
            sessionCacheService.set("cookieExpireTime", result.data.expired);
        }catch(e) {
            logger.error(e);
            return e;
        }
    }

    reassignCookie(){
        sessionCacheService.set("cookieExpireTime", '0');
        this.revokeCookie();
    }

    needToken(request) {
        return this.token && request.baseURL.indexOf("http://") !== 0 && request.url.indexOf("http://") !== 0 && !location.pathname.toLowerCase().includes("/pdvd/share/");
    }

    requestHandler(request) {
        if(request.method === "get" && request.url.indexOf('/pdvd/web/sso/get-token.action') === -1) {
            return request;
        }
        if(!this.token && sessionService.getToken()) {
            this.setToken(sessionService.getToken());
            this.setTokenType(sessionService.getTokenType());
        }
        if(this.needToken(request)) {
            if(request.url.indexOf(INTERNAL_APIS.SIGN_COOKIE) < 0) {
                this.revokeCookie();
            }
            request.headers['authorization'] = `${this.tokenType} ${this.token}`;
        }
        request.data = qs.stringify(request.data);
        return request;
    }

    responseHandler(response) {
        return response;
    }

    errorHandler(error) {
        if(error.hasOwnProperty("response")) {
            const filteredResponse = {...error.response.data};
            delete filteredResponse.timestamp;
            // filter out "pin code not match" cases (error message)
            if (filteredResponse.errorMessage !=="pin code not match"){
                dataLayer.push({"event": "apiError", "errorPath": error.config.url, "errorData": JSON.stringify(filteredResponse)});
            }

            if(error.response.config.url.indexOf("terms") >= 0) {
                return error;
            }
            // return sso login error, not logout directly
            if(error.response.config.url.indexOf("/pdvd/web/sso/login.action") >= 0) {
                return error;
            }
            if(error.response.config.url.indexOf("/pdvd/web/sso/get-token.action") >= 0) {
                return error;
            }
            if(error.response.config.url.indexOf("/pdvd/web/project.action?download=&sharedToken=") >= 0) {
                return error;
            }
            if(error.response.status === 401 || error.response.status === 403) {
                logout({redirect: true});
            }
        }
        return error;
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new DataApiService(singletonEnforcer);
        }
        return this[singleton];
    }
    setBaseUrl = url => this.session.defaults.baseURL = url;
    setToken = token => this.token = token;
    setTokenType = tokenType => this.tokenType = tokenType;
    setType = type => this.type = type;
    setApiList = apiList => this.apiList = apiList;
    setServer = server => {
        this.server = server;
        this.shareServer = server;
    };
    setShareServer = server => {
        this.shareServer = server;
    };
    get = (...request) => this.session.get(...request);
    post = (...request) => this.session.post(...request);
}

export default DataApiService.instance;
