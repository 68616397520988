import {shuffle} from "../../utils/dataUtils";
import produce from 'immer';

export const MUSIC_ACTIONS = {
    ADD: `MUSIC_ACTIONS_ADD`,
    STOP: `MUSIC_ACTIONS_STOP`,
    PLAY: `MUSIC_ACTIONS_PLAY`,
    NEXT: `MUSIC_ACTIONS_NEXT`,
    PREV: `MUSIC_ACTIONS_PREV`,
    TOGGLE_LOOP: `MUSIC_ACTIONS_TOGGLE_LOOP`,
    TOGGLE_SHUFFLE: `MUSIC_ACTIONS_TOGGLE_SHUFFLE`,
    EXIT: `MUSIC_ACTIONS_EXIT`,
    READY: `MUSIC_ACTIONS_READY`,
    USER_CLICK: `MUSIC_ACTIONS_USER_CLICK`,
    UPDATE_PLAYLIST: 'MUSIC_ACTIONS_UPDATE_PLAYLIST'
};

export const MUSIC_LOOP_SEQ = ["DISABLE", "LOOP_ALL", "LOOP_ONE"];
export const MUSIC_LOOP_TYPE = {
    DISABLE: "DISABLE",
    LOOP_ALL: "LOOP_ALL",
    LOOP_ONE: "LOOP_ONE"
};

const initialState = {
    list: [],
    queue: [],
    history: [],
    key: "",
    playing: false,
    loop: 0,
    shuffle: false,
    isReady: false,
    userClick: undefined,
};

export default (state = initialState, action = {}) =>
    produce(state, draft => {
        switch (action.type) {
            case MUSIC_ACTIONS.ADD:
                draft.playing = true;
                draft.list = action.playList.slice();
                draft.queue = draft.list.slice();
                if(state.shuffle) {
                    const tmp = draft.queue.splice(action.from, 1);
                    draft.queue = tmp.concat(shuffle(draft.queue));
                    draft.history = [];
                    break;
                }
                draft.history = draft.queue.splice(0, action.from);
                draft.isReady = false;
                break;
            case MUSIC_ACTIONS.STOP:
                draft.playing = false;
                break;
            case MUSIC_ACTIONS.PLAY:
                draft.playing = true;
                break;
            case MUSIC_ACTIONS.NEXT:
                draft.history.push(draft.queue.splice(0, 1)[0]);
                if (state.queue.length === 1) {
                    draft.queue = state.shuffle ? shuffle(state.list) : state.list.slice();  // state.queue
                    draft.playing = state.loop === 1 || !!state.shuffle;
                    break;
                }
                draft.playing = true;
                draft.isReady = false;
                break;
            case MUSIC_ACTIONS.PREV:
                if(draft.history.length === 0 && state.loop === 1) {
                    draft.history = state.list.slice();
                    draft.queue = [];
                }
                if(draft.history.length !== 0) {
                    draft.queue.unshift(draft.history.splice(-1, 1)[0]);
                }
                draft.isReady = false;
                break;
            case MUSIC_ACTIONS.TOGGLE_LOOP:
                draft.loop = state.loop === 2 ? 0 : state.loop + 1;
                break;
            case MUSIC_ACTIONS.TOGGLE_SHUFFLE:
                if(!state.shuffle) {
                    const tmp = draft.queue.splice(0, 1);
                    draft.queue = tmp.concat(shuffle(draft.queue));
                }
                if(state.shuffle) {
                    const index = state.queue.length === 0 ? 0 : state.list.findIndex(m => m.original.url === state.queue[0].original.url);
                    draft.queue = state.list.slice();
                    draft.history = draft.queue.splice(0, index);
                }
                draft.shuffle = !state.shuffle;
                break;
            case MUSIC_ACTIONS.READY:
                draft.isReady = true;
                break;
            case MUSIC_ACTIONS.USER_CLICK:
                draft.userClick = state.queue[0];
                break;
            case MUSIC_ACTIONS.UPDATE_PLAYLIST:
                draft.history = draft.history.filter(h => h.path !== action.deletePath);
                draft.list = draft.list.filter(l => l.path !== action.deletePath);
                draft.queue = draft.queue.filter(q => q.path !== action.deletePath);
                break;
            case MUSIC_ACTIONS.EXIT:
                return {...initialState, shuffle: state.shuffle, loop: state.loop};
            default:
                return draft;
        }
    });
