const theme = {
    palette: {
        // fix QA bug: add theme.palette.background.default to rewrite body background
        background: {
            default: "#1D1D1D",
        },
        primary: {
            main: '#292929',
            dark: '#1D1D1D',
            light: '#4A4A4A',
            contrastText: "black"
        },
        secondary: {
            main: '#229EFE',
            light: "#66CCFF",
        },
        google:{
            main: '#FFF',
        },
        facebook:{
            main:"#1877F2"
        },
        apple:{
            main: '#000',
        },
        gray: {
            main: '#CCCCCC',
            light: '#e2e2e2',
            dark: '#686868'
        },
        error: {
            main: "#ffff80"
        },
        // add: text, divider color
        text: {
            primary: "#FDFBFB",
            secondary: "#D7D7D7"
        },
        divider: "#4C4D4D",
        action: {
            hover: "rgba(240, 240, 240, 0.13)",
            liFocus: "rgba(240, 240, 240, 0.25)",
        },
    },
    shape: {
        borderRadius: 6
    },
    spacing: {
        unit: 8
    },
};

export const FONT_LANG_MAP = {
    "en-US": '"Segoe UI", "Microsoft JhengHei", "Helvetica", "Arial", sans-serif',
    "de-DE": '"Segoe UI", "Microsoft JhengHei", "Helvetica", "Arial", sans-serif',
    "es-ES": '"Segoe UI", "Microsoft JhengHei", "Helvetica", "Arial", sans-serif',
    "fr-FR": '"Segoe UI", "Microsoft JhengHei", "Helvetica", "Arial", sans-serif',
    "it-IT": '"Segoe UI", "Microsoft JhengHei", "Helvetica", "Arial", sans-serif',
    "ja-JP": '"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", sans-serif;',
    "ko-KR": '"Segoe UI", "Microsoft JhengHei", "Helvetica", "Arial", sans-serif',
    "pt-BR": '"Segoe UI", "Microsoft JhengHei", "Helvetica", "Arial", sans-serif',
    "ru-RU": '"Segoe UI", "Microsoft JhengHei", "Helvetica", "Arial", sans-serif',
    "zh-CN": '"Segoe UI", "Microsoft JhengHei", "Helvetica", "Arial", sans-serif',
    "zh-TW": '"Segoe UI", "Microsoft JhengHei", "Helvetica", "Arial", sans-serif'
};

export default {
    palette: theme.palette,
    shape: theme.shape,
    typography: {
        useNextVariants: true,
        fontFamily: '"Segoe UI", "Microsoft JhengHei", "Helvetica", "Arial", sans-serif',
        h1: {
            fontSize: "30px",
            fontWeight: "400",
        },
        h2: {
            fontSize: "18px",
            fontWeight: "bold",
            marginBottom: "14px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: "300px",
            whiteSpace: "nowrap",
            lineHeight: "normal",
            // maxWidth: "38%",
            // minWidth: "270px",
        },
        subtitle1: {
            fontSize: "16px",
            // padding: "6px 0",
            margin: "6px 0",
            lineHeight: 1.5,
            lineClamp: "2",
            display: "-webkit-box",
            boxOrient: "vertical",
            textOverflow: "ellipsis",
            visibility: "visible",
            overflow: "hidden",
        },
        body2: {
            fontSize: "14px"
        }
    },
    overrides: {
        MuiSkeleton: {
            text: {
                marginTop: 0,
                height: 16
            },
        },
        MuiCard: {
            root: {
                background: "none",
            }
        },
        MuiCardHeader: {
            root: {
                padding: "8px 16px"
            },
            avatar: {
                display: "flex"
            }
        },
        MuiLink: {
            root: {
                color: "inherit"
            }
        },
        MuiInputBase:{
            input: {
                height: "100%"
            }
        },
        MuiSlider: {
            rail: {
                height: "6px",
                borderRadius: "3px",
                color: "rgba(255,255,255,.7)",
            },
            track: {
                height: "6px",
                borderRadius: "3px",
            },
            thumb: {
                width: "14px",
                height: "14px",
                marginTop: "-4px",
                backgroundColor: "white",
            }
        },
        MuiBox: {
            root: {
                padding: "40px",
                paddingBottom: "10px",
                // should improve: can inset theme breakpoint
                "@media(max-width: 959.95px)": {
                    padding: "25px 4vw"
                },
            }
        },
        MuiAppBar: {
            root: {
                backgroundColor: theme.palette.primary.dark,
                boxShadow: "none"
            },
        },
        MuiDrawer: {
            paperAnchorDockedLeft: {
                borderRight: "none"
            }
        },
        MuiListItem: {
            button: {   //Sidebar
                padding: "5px 30px",
                marginTop: "20px",
            },
        },
        MuiListItemText: {
            primary:{
                fontSize: "18px",
            },
            root: {
                flex: "unset",
            }
        },
        MuiMenuItem: {
            root: {
                marginTop: "5px",
                padding: "0 35px",
                minHeight: "30px !important",
                lineHeight: "unset",
                height: "auto",
                paddingTop: 0,
                paddingBottom: 0,
                '& *': {
                    pointerEvents: "none",
                }
            }
        },
        MuiContainer: {
            root: {
                fontSize: "16px",
                backgroundColor: "#1D1D1D",
                // overflow: "hidden"
            }
        },
        MuiRating: {
            root: {
                fontSize: "28px",
            },
            decimal: {
                margin: "0 2px",
            },
            iconEmpty: {
                color: "rgba(255, 255, 255, 0.5)"
            }
        },
        MuiLinearProgress: {
            root: {
                height: "6px"
            },
            colorPrimary: {
                backgroundColor: "#424241",
            },
            barColorPrimary: {
                backgroundColor: theme.palette.secondary.main
            }
        },
        MuiFab: {
            root: {
                backgroundColor: "rgba(0,0,0, 0.8)",
                // backgroundColor: "rgba(34, 159, 255, 0.8)",
                color: "white",
                // transitionProperty: "all",
                transition: "0.2s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                width: "50px",
                height: "50px",
                position: "absolute",
                bottom: "15px",
                // left: "15px",
                right: "15px",
                "& svg": {
                    // fontSize: "35px"
                    fontSize: "2rem"
                },
                "&:hover": {
                    backgroundColor: "rgba(0,0,0, 1)",
                    // backgroundColor: "rgba(34, 159, 255, 1)",
                    transform: "scale(1.15)",
                },
            },
            sizeSmall: {
                "& svg": {
                    fontSize: "1.5rem"
                },
            }
        },
        MuiTypography: {
            h6: {
                fontWeight: 'bold'
            }
        },
        MuiTableRow: {
            head: {
                height: 45,
                backgroundColor: theme.palette.gray.main,
            },
            hover: {
                '&:hover': {
                    backgroundColor: '#e1f2fc !important'
                }
            }
        },
        MuiTableCell: {
            root: {
                height: 45,
                paddingRight: 10,
                '&:first-child': {
                    paddingLeft: 24
                },
                '&:last-child': {
                    paddingRight: 10
                },
            },
            head: {
                color: '#000',
                fontWeight: 'bold',
                fontSize: 14,
                fontFamily: "Roboto, Helvetica, Arial, sans-serif"
            }
        },
        MuiButton: {
            root: {
                '& *': {
                    pointerEvents: "none",
                }
            },
            outlined: {
                borderWidth: 2,
                textTransform: 'capitalize',
                borderColor: theme.palette.gray.dark,
            },
            contained: {
                textTransform: 'capitalize',
                fontSize: "16px",
                borderRadius: "30px",
                color: "#FFF",
                padding: "3px 20px",
                '&.Mui-disabled': {
                    borderColor: "rgba(0,0,0, 0.26)",
                }
            }
        },
        MuiIconButton: {
            root: {
                padding: "6px",
                color: "inherit",
                '& *': {
                    pointerEvents: "none",
                }
            }
        },
        MuiDialog: {
            paper: {
                padding: 0,
                minWidth: 300
            },
        },
        MuiDialogTitle: {
            root: {
                backgroundColor: theme.palette.gray.dark,
            }
        },
        MuiDialogContent: {
            root: {
                backgroundColor: theme.palette.gray.dark,
            }
        },
        MuiDialogActions: {
            root: {
                backgroundColor: theme.palette.gray.dark,
            }
        },
        MuiSelect: {
            icon: {
                color: theme.palette.text.primary,
                right: 10
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 14
            }
        },
        // calendar
        MuiPickersBasePicker: {
            container: {
                backgroundColor: "#848383",
            },
        },
        MuiPickersDay: {
            daySelected: {
                backgroundColor: theme.palette.secondary.main,
                border: "1px solid lightblue",
                '&:hover': {
                    backgroundColor: theme.palette.secondary.light,
                }
            }
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: "inherit",
            }
        }
    },
    // store some UI constant (ex: width/height ...)
    constant: {
        drawerWidth: 240,
        headerHeight: 128,
        headerHeightSm: 70,
        transition: second => `${second}s ease-in-out`,
    },
    // test if can add custom setting in theme(to be global to every component!!!): it works!
    custom: {
        backBtn: {
            display: "block",
            marginRight: "20px",
            cursor: "pointer",
            width: "30px"
        },
        textLineClamp: {
            display: "-webkit-box",
            boxOrient: "vertical",
            textOverflow: "ellipsis",
            overflow: "hidden",
            visibility: "visible",
            lineClamp: "2",
        },
        hoverEffect: {
            opacity: 0,
            transition: "0.2s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            position: "absolute",
            "&.showMore" : {
                top: 3,
                right: 3,
                width: 40,
                height: 40,
                zIndex: 1,
                '& .MuiFab-root': {
                    right: 0,
                    bottom: 0,
                    boxShadow: 'none',
                    background: 'transparent',
                    '&:focus' : {
                        background: '#282828',
                        borderRadius: 50,
                    }
                }
            }
        },
        dialogButton: {
            backgroundColor: "#848383",
            fontSize: 14,
            border: "1px solid #FFF",
            borderRadius: 5,
            boxShadow: "none",
            "&:hover": {
                color: "black",
            },
        }
    }
}
