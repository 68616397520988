import React from 'react';
import {useIntl} from "react-intl";
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({});

const NotSupport = props => {
    const {formatMessage: f} = useIntl();

    return (
        <Dialog open={true} maxWidth={'md'}>
            <DialogTitle>{f({id: "not-support.title"})}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography component="p">
                        {f({id: "not-support.browser"})}
                    </Typography>
                    <Typography component="p">
                        {f({id: "not-support.suggest"})}
                    </Typography>
                </DialogContentText>
            </DialogContent>

        </Dialog>
    );

};

export default withStyles(styles)(NotSupport);
