import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {withRouter} from "react-router";
import classNames from 'classnames';
import {useDispatch, useSelector} from "react-redux";
import pageConfig, {getConfigByUrl} from "pageConfig";
import {checkHasProduct, getMediaTypeFromPath, getPrefixPath} from "../utils/dataUtils";
import {useIntl} from "react-intl";
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import {SIDEBAR_ACTIONS} from "../services/data/sideBarReducer";
import {useThemeMediaQuery} from "../components/componentUtils";
import {BUILD, BUILD_TYPE} from "../utils/constants";

const useStyles = makeStyles(theme => ({
    drawer: {
        width: theme.constant.drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        minHeight: "100vh",
    },
    paper: {
        width: theme.constant.drawerWidth,
        backgroundColor: theme.palette.primary.main,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar
    },
    toolbarClose: {
        justifyContent: 'center'
    },
    listItem: {
        '& *': {
            pointerEvents: "none",
        }
    },
    pageFocus: {
        backgroundColor: theme.palette.primary.light,
        "& .MuiListItemText-primary": {
            fontWeight: "bold",
            color: "#229EFE",
        }
    },
    listIcon: {
        width: 38,
        height: 38
    },
    listTitle: {
        whiteSpace: 'normal',
        textTransform: "capitalize",
    },
    logo: {
        display: "flex",
        padding: "0 20px",
        color: "#FFF",
        alignItems: "center",
        flexShrink: 0,
        height: 128,
        [theme.breakpoints.down('sm')]: {
            height: theme.constant.headerHeightSm,
        },
        '& img': {
            cursor: "pointer",
        }
    },
    logoImg: {
        width: 56,
        marginRight: "6px",
        display: "block",
        [theme.breakpoints.down('sm')]: {
            width: 46
        },
    },
    logoImg2: {
        width: 146,
    },
    logoText: {
        fontSize: 20,
        cursor: "pointer"
    }
}));

const Sidebar = props => {
    const {location} = props;
    const config = getConfigByUrl(props.location.pathname);
    const [showSidebar, setShowSidebar] = useState(!!config.sidebar);
    const sidebar = pageConfig;
    const isOpen = useSelector(state => state.sideBar.open);
    const {formatMessage: f} = useIntl();
    const {mobile} = useThemeMediaQuery("sm");
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(()=> setShowSidebar(!!config.sidebar), [config]);

    useEffect(()=>{
        dispatch({type: SIDEBAR_ACTIONS[mobile?"CLOSE": "OPEN"]});
    }, [mobile]);

    function generateLink(key, link, item) {
        const { title, icon, activatePattern } = item.config.sidebar;
        const isPage = location.pathname.substring(1).includes(activatePattern.toLowerCase()) ||
            getMediaTypeFromPath(location.pathname) === activatePattern;
        const iconType = isPage ? "p":"n";

        if(!mobile && item.config.sidebar.mobile) return "";
        return (
            <ListItem key={key} id={item.key} button component={Link} to={link}
                      className={classNames(classes.listItem, {[classes.pageFocus]: isPage})}
                      onClick={()=> dispatch({type: SIDEBAR_ACTIONS[mobile?"CLOSE": "OPEN"]}) }
            >
                <ListItemIcon>
                    <img className={classes.listIcon} src={require(`../assets/images/main/${icon}_${iconType}@2x.png`)} alt="" />
                </ListItemIcon>
                <ListItemText className={classes.listTitle}> {f({id: title.key})} </ListItemText>
            </ListItem>
        );
    }

    const renderMenu = () => sidebar.map((item, index) => {
        if(item.config.hasOwnProperty("sidebar") && !item.config.sidebar.hide) {
            if (item.config.sidebar.checkPDVD && !checkHasProduct("PowerDVD")) return "";
            return generateLink(`${item.key} -li- ${index}`, item.link, item);
        }
    });
    
    function goHome(e) {
        props.history.push(getPrefixPath("/home"))
    }
    
    function renderLogo() {
        if (BUILD === BUILD_TYPE.clCloud) return (
          <div className={classes.logo}>
              <img id="logo" className={classes.logoImg} src={require(`../assets/images/main/Cloud_logo@2x.png`)} alt="" onClick={goHome} />
              <div className={classes.logoText} onClick={goHome}> Cyberlink Cloud </div>
          </div>
        )
        else return (
          <div className={classes.logo}>
              <img id="logo" className={classes.logoImg} src={require(`../assets/images/main/CPP_logo@2x.png`)} alt="" onClick={goHome} />
              <img id="logo" className={classes.logoImg2} src={require(`../assets/images/main/CPP_web_logo@2x.png`)} alt="" onClick={goHome}/>
          </div>
        )
    }

    if (!showSidebar) return "";
    return (
        <Drawer
            variant={mobile?"temporary": "persistent"}
            className={classes.drawer}
            classes={{paper: classes.paper}}
            open={isOpen}
            onClose={() => dispatch({type: SIDEBAR_ACTIONS.CLOSE})}
        >
            {renderLogo()}
            <Divider />
            <List disablePadding> {renderMenu()} </List>
        </Drawer>
    )
};

export default withRouter(Sidebar);
