import React, {useEffect, useMemo, useState} from 'react';
import {Button, Link, Slide, withStyles} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import LightBox from "./LightBox";
import {getUserTerms, logout, patchAcceptDate} from "../services/user/userService";
import {withRouter} from "react-router";
import {getConfigByUrl} from "pageConfig";
import moment from "moment";
import sessionService from "../services/user/sessionService";
import {useIntl} from "react-intl";
import langsUtils from "../utils/langsUtils";

const GDPR = props => {
    const {classes} = props;
    const {formatMessage: f} = useIntl();
    const [footerOpen, setFooterOpen] = useState(false);
    const [lightBoxStatus, setLightBoxStatus] = useState({open: false, checked: false});
    const [termsData, setTermsData] = useState(undefined);
    const langCode = useMemo(() => langsUtils.getLangParam(sessionService.getLang()).param.toLowerCase(), []);

    useEffect(()=>{
        async function fetchData() {
            // todo: fakeData for testing, need to remove afterwards.
            let fakeData = localStorage.getItem("fakeGDPR");
            if(fakeData) {
                fakeData = JSON.parse(fakeData);
            }

            const data = fakeData || termsData || await getUserTerms();
            // fix: if no /select, will read termsData from sign in
            if(!termsData && requireSignin) {
                setTermsData(data);
            }

            // Footer check (store in localStorage)
            const localModifiedDate = sessionService.getLastTerms() || null;
            const closeFooter = sessionService.getCloseFooter() === "true";
            if(!data) {
                if(!closeFooter) {
                    setTimeout(() => setFooterOpen(true), 500);
                }
                return;
            }
            if (data.isEUSepc && ((localModifiedDate !== data.termsLastModifiedDate) || !closeFooter)) {
                setTimeout(() => setFooterOpen(true), 500);
                sessionService.generateInfo({ lastTerms: data.termsLastModifiedDate });
            }
            if (localModifiedDate !== data.termsLastModifiedDate){
                sessionService.generateInfo({ closeFooter: "false"});
            }

            // LightBox check (from api) and "if pageconfig have require signin"(?)
            const requireSignin = getConfigByUrl(props.location.pathname).requireSignin;
            if(moment(data.termsLastModifiedDate).isSameOrAfter(moment(data.acceptedDate || 0)) && requireSignin && !lightBoxStatus.checked){
                setTimeout(() => setLightBoxStatus({open: true, checked: false}), 100);
            }
        }
        fetchData();
    }, [props.location.pathname]);

    function handleClose() {
        setFooterOpen(false);
        sessionService.generateInfo({ closeFooter: "true"});
    }

    async function handleAccept() {
        setLightBoxStatus({open: false, checked: true});
        // todo: fakeData for testing, need to remove afterwards.
        let fakeData = localStorage.getItem("fakeGDPR");
        if(fakeData) {
            fakeData = JSON.parse(fakeData);
            fakeData.acceptedDate = moment(new Date()).format();
            localStorage.setItem("fakeGDPR", JSON.stringify(fakeData));
            setLightBoxStatus({open: false, checked: true});
        }else {
            // patch api
            const result = await patchAcceptDate();
            if(result.status === 200) {
                setLightBoxStatus({open: false, checked: true});
            }
        }
    }

    function login(e){
        e.preventDefault();
        setLightBoxStatus({open: false, checked: true});
    }

    async function signout(e) {
        e.preventDefault();
        await logout();
        window.location.href = `${location.protocol === "http:" ? "https" : "http"}://${location.hostname}/signout`;
    }

    function renderLightBox(){
        const localModifiedDate = sessionService.getLastTerms() || null;
        const dialogConfig = [{
            type: "message",
            title: f({id: "gdpr.light-box.title"}),
            text: f({id: "gdpr.light-box.content"}, {date: moment(localModifiedDate).format("YYYY/MM/DD")}),
            customContent: (
                <div className={classes.actions}>
                    <Button variant="contained" className={classes.pinBtn} onClick={handleAccept} >{f({id: "gdpr.light-box.accept"})}</Button>
                    <Link component={"button"} underline="always" onClick={login}>{f({id: "gdpr.light-box.maybe"})}</Link>
                    <Link component={"button"} underline="always" onClick={signout}>{f({id: "gdpr.light-box.reject"})}</Link>
                </div>
            ),
        }];
        return <LightBox open={lightBoxStatus.open} {...dialogConfig[0]} />
    }

    function renderFooter() {
        return(
            <Slide direction="up" in={footerOpen} mountOnEnter unmountOnExit>
                <div className={classes.root}>
                    <div className={classes.wording}>
                        <span>{f({id: "gdpr.light-box.footer1"})}</span>
                        <Link href={`https://privacy.cyberlink.com/${langCode}/privacy-policy#cookies`} target={"_blank"} underline="always">{f({id: "gdpr.light-box.footer2"})}</Link>
                    </div>
                    <IconButton id="closeGDPR" aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </Slide>
        )
    }

    return (
        <React.Fragment>
            {renderLightBox()}
            {renderFooter()}
        </React.Fragment>

    );
};
export default withRouter(withStyles(theme => ({
    root: {
        position: "fixed",
        bottom: 0,
        background: "#4C4D4D",
        width: "100%",
        zIndex: 1250,
    },
    wording: {
        maxWidth: 1200,
        margin: "auto",
        padding: "10px 50px"
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    actions: {
        width: "100%",
        paddingTop: 10,
        "& > *":{
            display:"block",
            margin: "15px auto"
        }
    },
    pinBtn: {
        fontSize: "14px",
        paddingTop: 5,
        paddingBottom: 5,
        boxShadow: "none",
        background: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        },
    },
}))(GDPR));
