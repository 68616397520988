import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {useShare} from "../DataHooks";
import {MEDIA_TYPE, API_TYPE, DIALOG_CONFIG, BUILD, BUILD_TYPE} from "../../utils/constants";
import Bowser from "bowser";
import config from "config";
import {Box, Button, CircularProgress, Divider, withStyles} from "@material-ui/core";
import LightBox from "../../common/LightBox";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import launchURI from "../../utils/launchURI";
import logger from "logger";
import sessionService from "../../services/user/sessionService";
import {downloadFile} from "../../utils/dataUtils";
import {getDownloadFile} from "../../services/data/cloudDataService";

const SHARE_IMAGE = {
    [MEDIA_TYPE.Movie]: require("../../assets/images/open_share/share_movie_thumb@2x.png"),
    [MEDIA_TYPE.TV]: require("../../assets/images/open_share/share_TV_thumb@2x.png"),
    [MEDIA_TYPE.Video]: require("../../assets/images/open_share/share_video_thumb@2x.png"),
    [MEDIA_TYPE.Photo]: require("../../assets/images/open_share/share_photo_thumb@2x.png"),
    [MEDIA_TYPE.Music]: require("../../assets/images/open_share/share_music_thumb@2x.png"),
    // Use movie/tv thumbnail for replacement temporary
    [MEDIA_TYPE.Template]: require("../../assets/images/open_share/share_movie_thumb@2x.png"),
    [MEDIA_TYPE.Project]: require("../../assets/images/open_share/share_TV_thumb@2x.png"),
    share: require("../../assets/images/open_share/share_protected_thumb@2x.png"),
};

const Share = props => {
    const {formatMessage: f} = useIntl();
    const {match, classes, blockPage} = props;
    const sharedId = (match)? match.params.sharedId: undefined;
    const [pincode, setPincode] = useState(undefined);

    const [isExpired, setIsExpired] = useState(0);
    const share = useShare({sharedId, pincode, isExpired});
    const [device, setDevice] = useState(Bowser.parse(window.navigator.userAgent));
    const mobile = useMediaQuery('(max-width:766px)');

    if(!share.timestamp && !blockPage) {
        return <CircularProgress color="inherit" size={30} style={{margin: "40px"}} />;
    }

    if(share.expired && !blockPage) {
        return <LightBox open={true} {...DIALOG_CONFIG.expired} />
    }
    if(share.notFound && !blockPage) {
        return <LightBox open={true} {...DIALOG_CONFIG.notFound} />
    }

    function getAPP(e) {
        e.preventDefault();
        window.location.href = 'https://www.cyberlink.com/qr/cpp1-appstore';
    }

    function launchAP(e) {
        e.preventDefault();
        if(device.platform.type === "desktop") {
            launchURI(`${config.LAUNCH_URI["desktop"].share}${sharedId}`, () => {}, () => {});
            return;
        }
        window.location.href = sharedId ? `${config.SHARE_CPP_DOMAIN}/pdvd/share/${sharedId}` : config.SHARE_CPP_DOMAIN;
    }
    
    function launchPDVD() {
        logger.log(`Launch URI: ${config.LAUNCH_URI["desktop"].share}${sharedId}`);
        launchURI(`${config.LAUNCH_URI["desktop"].share}${sharedId}`, () => {
            logger.log(`Launch Success: ${config.LAUNCH_URI["desktop"].share}${sharedId}`);
        }, () => {
            logger.log(`Launch Fail: ${config.LAUNCH_URI["desktop"].share}${sharedId}`);
        });
    }

    function goContent(e) {
        e.preventDefault();
        props.history.push(`${window.location.pathname}/browse`);
    }

    async function clickDownload() {
        const url = share.data[0].downloadUrl;
        const mediaType = share.info.mediaType;
        console.log("download", mediaType, url);

        if (mediaType === MEDIA_TYPE.Template) {
            window.open(url);
            // window.location.assign(url);
            // Need to use axios and catch error
        }
        else if (mediaType === MEDIA_TYPE.Project) {
            const result = await getDownloadFile({ url });
            if (result) {
                downloadFile(result.data, result.filename || share.info.name, `application/dlp`, `.dlp`);
            }
            // If error
            else {
                // 1. Page refresh
                // alert("token expired, refresh your page");
                // location.reload();

                // 2. Call useShare again & download again
                setIsExpired(prevState => prevState+1);
            }
        }
    }

    function renderAction() {
        if(!device) {
            return null;
        }
        if(device.platform.type === "desktop") {
            if(!share.isDownload) launchPDVD();
            return (
                <div className={classes.action}>
                    <div className={classes.actionItem}>
                        {!share.isDownload?
                          <>
                              <div className={classes.actionDesp}>{f({id: "share.desktop-guide-sentence"})}</div>
                              <Button className={classes.actionButton} id="continue" variant="contained" onClick={goContent}>{f({id: "share.desktop-btn-continue"})}</Button>
                          </>:
                          <Button className={classes.actionButton} id="continue" variant="contained" onClick={clickDownload}> Download </Button>
                        }
                    </div>
                </div>
            )
        }
        return (
            <div className={classes.action}>
                <div className={classes.actionItem}>
                    <div className={classes.actionDesp}>{f({id: "share.mobile-guide-sentence-1"})}</div>
                    <Button className={classes.actionButton} variant="contained" onClick={getAPP}>{f({id: "share.mobile-btn-install"})}</Button>
                </div>
                <div className={classes.actionItem}>
                    <div className={classes.actionDesp}>{f({id: "share.mobile-guide-sentence-2"})}</div>
                    <Button className={classes.actionButton} variant="contained" onClick={launchAP}>{f({id: "share.mobile-btn-launch"})}</Button>
                </div>
            </div>
        )
    }

    function logoClick() {
        const logoLink = !sessionService.getToken()? "/signin": `${(sessionService.getServerType()===API_TYPE.CLOUD)?"https":"http"}://${location.hostname}/home`;
        window.location.href = logoLink;
    }

    function renderLogo() {
        if (BUILD === BUILD_TYPE.clCloud) return (
            <div className={classes.logo}>
                <img id="logo" className={classes.logoImg} src={require(`../../assets/images/main/Cloud_logo@2x.png`)} alt="" onClick={logoClick} />
                <div className={classes.logoText} onClick={logoClick}> Cyberlink Cloud </div>
            </div>
        )
        else return (
            <div className={classes.logo}>
                <img id="logo" className={classes.logoImg} src={require(`../../assets/images/main/CPP_logo@2x.png`)} alt="" onClick={logoClick} />
                <img id="logo" className={classes.logoImg2} src={require(`../../assets/images/main/share_powerplayer_logo@2x.png`)} alt="" onClick={logoClick} />
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.topbar}>
                {renderLogo()}
            </div>
            <Divider />
            <Box className={classes.main} >
                {!blockPage && <div className={classes.content}>
                    <div className={classes.title}>
                        {(share.info && share.info.sharer || share.sharer) &&
                            f({id: "share.msg"}, {mediaType: f({id: `general.${share.info ? share.info.mediaType.toLowerCase(): "media"}`}), sharer: share.info ? share.info.sharer : share.sharer})
                        }
                    </div>
                    <img className={classes.mediaImg} src={SHARE_IMAGE[(share.info)? share.info.mediaType: "share"]} style={!share.info?{width:147,height:147, margin: 10}:null} alt="" />

                    <Typography variant="subtitle1" className={classes.name} title={(share.info) ? share.data.title || share.info.name : ""}
                                style={{WebkitLineClamp: `${mobile?2:4}`}}>
                        {(share.info) ? share.data.title || share.info.name : f({id: "share.locked.content"})}
                    </Typography>
                </div>}
                {renderAction()}
            </Box>
        </div>
    )
};

export default withStyles(theme => ({
    root: {

    },
    topbar: {},
    logo: {
        display: "flex",
        padding: "20px 36px",
        color: "#FFF",
        alignItems: "center",
        '& img': {
            cursor: "pointer",
        }
    },
    logoImg: {
        width: "56px",
        marginRight: "6px",
        display: "block",
    },
    logoImg2: {
        width: 170,
    },
    logoText: {
        fontSize: 24,
        cursor: "pointer"
    },
    mediaImg: {
        width: 157,
        height: 157,
        objectFit: "cover",
        borderRadius: theme.shape.borderRadius,
    },
    main: {
        padding: "30px 35px",
        margin: "auto",
        textAlign: "center",
        fontSize: "16px",
        color: "#FFF",
        lineHeight: "19px"
    },
    content: {

    },
    title: {
        marginBottom: "25px"
    },
    name: {
        fontSize: "24px",
        color: "#71C1FE",
        overflow: "hidden",
        wordBreak: "break-word",
        margin: 0
    },
    action: {},
    actionItem: {},
    actionDesp: {
        marginTop: "36px",
    },
    actionButton: {
        background: theme.palette.secondary.main,
        padding: "5px",
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        },
        width: "95%",
        height: "50px",
        maxWidth: 450,
        marginTop: "18px"
    }
}))(Share);
