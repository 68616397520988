import config from "config";

const Logger = {
    log: (...messages) => {
        if (config.enableDebug) {
            messages.map((msg) => console.log(msg));
            // console.log(messages);
        }
    },
    trace: function trace(...messages) {
        if (config.enableDebug) {
            messages.map((msg) => console.log(msg));
            const err = new Error();
            console.log(err.stack.split('\n')[2]);
        }
    },
    error: (...messages) => {
        if (config.enableDebug) {
            messages.map((msg) => console.error(msg));
        }
    },
};

export default Logger;
