import React from 'react';
import Main from "./Main";
import CssBaseline from '@material-ui/core/CssBaseline/index';
import Error from "./Error";
import Header from "./Header";
import Sidebar from "./Sidebar";
import NotSupport from "./NotSupport";
import MusicPlay from "../pages/musics/MusicPlay";
import GDPR from "./GDPR";
import Bowser from "bowser";
import Share from "../pages/share/Share";
import {getConfigByUrl} from "pageConfig";
import dataApiService from "../services/data/dataApiService";
import {init} from "../services/data/cloudDataService";
import {BUILD, BUILD_TYPE} from "../utils/constants";

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            initReady: false
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, info){
        let [fileName,line,column] = error.stack.match(/.com\/.*/g)[0].replace('.com\/','').split(':');
        const errorDetail = {fileName, line, column, version: parseInt(__VERSION__)};
        // console.log(`${error.name}: ${error.message}`);
        // console.log(errorDetail);
        dataLayer.push({"event": "jsError", "action":`${error.name}: ${error.message}`, "label": JSON.stringify(errorDetail)});
    }

    async componentDidMount() {
        if (BUILD === BUILD_TYPE.clCloud && !dataApiService.apiList) {
            await init();
            this.setState({ initReady: true })
        }
    }

    isIE = () => !!document.documentMode;

    render() {
        if (this.isIE()) {
            return <NotSupport/>;
        }
        if(this.state.hasError) {
            return <Error />
        }
        if (BUILD === BUILD_TYPE.clCloud && !this.state.initReady) {
            return ""
        }

        // if mobile/tablet and not unBlock pages, show launch APP block page (setting in PageConfig)
        const platform = Bowser.parse(window.navigator.userAgent).platform;
        const unBlock = getConfigByUrl(window.location.pathname).unBlockMobile;

        if ((platform.type==="tablet" || platform.type==="mobile") && !unBlock){
            return <Share blockPage />;
        }
        window.addEventListener('keydown', function(e) {
            if(e.keyCode === 32 && e.target === document.body) {
                e.preventDefault();
            }
        });
        return (
            <div style={{display: "flex"}}>
                <Header/>
                <Sidebar/>
                <Main/>
                <MusicPlay/>
                {/*{BUILD !== BUILD_TYPE.pms && <GDPR />}*/}
            </div>
        );
    }
}
