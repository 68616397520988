/**
 * Created by Jeffy_Chuang on 2019/10/01.
 */
import React, {useEffect, useMemo, useState, useRef, useCallback} from 'react';
import {
    API_TYPE,
    CLOUD_CONFIG,
    CONTENT_CONFIG, DATA_HOOK_CONFIG,
    DATA_SOURCE,
    DATA_TYPE,
    MEDIA_CONFIG,
    MEDIA_TYPE
} from "../utils/constants";
import {
    continueWatching,
    getCollections,
    getDetail,
    getList,
    getMusicByTag,
    getSearch,
    queryShare
} from "../services/data/cloudDataService";
import {useTimeout, useUnmounted} from '../components/componentUtils';
import {browseMedia, searchMedia} from "../services/data/mediaDataService";
import {useDispatch, useSelector} from "react-redux";
import {DATA_PARSER} from "./DataParser";
import logger from 'logger';
import {
    get,
    getFolderPath,
    getMediaTypeArray,
    getMediaTypeFromPath,
    getParentPath
} from "../utils/dataUtils";
import api from "../services/data/dataApiService";
import {useIntl} from "react-intl";
import ReconnectingWebSocket from "reconnectingwebsocket";
import sessionCacheService from "../services/user/sessionCacheService";
import sessionService from "../services/user/sessionService";
import {indexedDb} from './IndexedDb';
import {USER_ACTIONS} from "../services/user/userReducer";

async function parseMediaServerWatchData(media, formatMessage) {
    if (media.mediaType === MEDIA_TYPE.TV) {
        let duration = media.tags.duration;
        let resumeTime = media.tags.resumeTime;
        let episodeThumb = undefined;
        let episodeNum = undefined;
        if(media.tags.resumeEpisode) {
            const episodes = await browseMedia({
                path: media.path,
                pageSize: 1000,
                pageIndex: 1
            });
            episodes.contents.sort((a, b) => parseInt(a.episodeNumber) - parseInt(b.episodeNumber));
            const index = episodes.contents.findIndex(e => e.path === media.tags.resumeEpisode);
            if(index >= 0) {
                episodeNum = episodes.contents[index].tags.episodeNumber;
                episodeThumb = episodes.contents[index].tags.thumbPath;
                // duration = episodes.totalSize;
                // resumeTime = (index + (episodes.contents[index].tags.resumeTime === 0 ? 0 : duration / (duration * 2))) * 10000000;
                duration = episodes.contents[index].tags.duration;
                resumeTime = episodes.contents[index].tags.resumeTime;
            }
        }
        return {
            mediaType: media.mediaType,
            path: media.tags.resumeEpisode ? media.tags.resumeEpisode : media.path,
            title: `${media.tags.title || media.tags.name} - ${formatMessage({id: "general.episode"}, {num: episodeNum})}`,
            // title: media.tags.tvTitle,
            thumbnail: {url: episodeThumb || get(media, "tags.ml_still_thumbPath") || get(media, "tags.thumbPath")},
            resumeTime: resumeTime,
            duration: duration
        }
    }
    return {
        mediaType: media.mediaType,
        path: media.path,
        title: media.tags.title,
        thumbnail: {url: get(media, "tags.ml_still_thumbPath") || get(media, "tags.thumbPath")},
        resumeTime: media.tags.resumeTime,
        duration: media.tags.duration
    }
}

async function parseCloudWatchData(media, formatMessage) {
    if (media.mediaType === MEDIA_TYPE.TV) {
        let resumeTime = media.resumeTime;
        let episodeThumb = undefined;
        let episodeNum = undefined;
        if(media.resumeEpisode) {
            const result = await getDetail({
                key: CLOUD_CONFIG[DATA_TYPE.DETAIL][MEDIA_TYPE.TV].key,
                path: media.path,
                pageSize: 30,
                pageIndex: 1
            });
            const episodes = result.result.episodes;
            episodes.sort((a, b) => parseInt(a.episodeNumber) - parseInt(b.episodeNumber));
            const index = episodes.findIndex(e => e.path === media.resumeEpisode);
            if(index >= 0) {
                episodeThumb = get(episodes[index], "still.url") || get(episodes[index], 'thumbnail.url');
                episodeNum = episodes[index].episodeNumber;
                resumeTime = episodes[index].finishPercentage * 100000;
            }
        }
        return {
            mediaType: media.mediaType,
            path: media.resumeEpisode ? media.resumeEpisode : media.path,
            title: `${media.title} - ${formatMessage({id: "general.episode"}, {num: episodeNum})}`,
            thumbnail: {url: episodeThumb || get(media, "still.url") || get(media, 'thumbnail.url')},
            resumeTime: resumeTime,
            // resumeTime: media.finishPercentage * 100000, //progress = resumeTime / 100000 / duration
            duration: 1
        }
    }
    return {
        mediaType: media.mediaType, path: media.path, title: media.title,
        thumbnail: {url: get(media, "still.url") || get(media, 'thumbnail.url')}, resumeTime: media.resumeTime,
        duration: media.duration
    }
}

async function parseWatchedData(list, apiType, limit, formatMessage) {
    const result = [];
    list.sort((a, b) => parseInt(b.modified) - parseInt(a.modified));
    let cnt = 0;
    while (cnt < limit && cnt < list.length) {
        const media = list[cnt];
        cnt++;
        if (!media || (new Date().getTime() - media.modified) / 1000 / 60 / 60 / 24 / 30 > 1) { //check 30 days
            continue;
        }
        if (apiType === API_TYPE.MEDIA_SERVER) {
            result.push(await parseMediaServerWatchData(media, formatMessage));
        }
        if (apiType === API_TYPE.CLOUD) {
            result.push(await parseCloudWatchData(media, formatMessage));
        }
    }
    return result;
}

export const useContinueWatching = (params) => {
    const {pageSize, pageIndex} = params;
    const [data, setData] = useState(null);
    const [error, setError] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const apiType = useSelector(state => state.user.apiType);
    const unmounted = useUnmounted();
    const {formatMessage} = useIntl();

    useEffect(() => {
        async function fetchData() {
            if (apiType === API_TYPE.MEDIA_SERVER) {
                let watchedContent = await Promise.all([
                    browseMedia({
                        //path: MEDIA_CONFIG[DATA_TYPE.COLLECTION][MEDIA_TYPE.Movie].path,
                        path: "/PDVD/Movie/All",
                        queryFilter: 4,
                        pageSize,
                        pageIndex
                    }),
                    browseMedia({
                        path: "/PDVD/TV/Folder",
                        queryFilter: 4,
                        pageSize,
                        pageIndex
                    })
                ]);
                let allResult = [];
                watchedContent.map(c => {
                    allResult = allResult.concat(c.contents)
                });
                const result = await parseWatchedData(allResult, API_TYPE.MEDIA_SERVER, 10, formatMessage);
                if (!unmounted.current) {
                    setData(result);
                    setTotalSize(result.length);
                }
            } else if (apiType === API_TYPE.CLOUD) {
                let watchedCollection = await Promise.all([
                    await getCollections({
                        key: "home.continueWatchingMovie",
                        pageSize: pageSize,
                        pageIndex: pageIndex
                    }),
                    await getCollections({
                        key: "home.continueWatchingTV",
                        pageSize: pageSize,
                        pageIndex: pageIndex
                    })
                ]);
                const movieWatched = watchedCollection[0].result.map(w => {
                    w.modified = w.lastModified;
                    w.mediaType = MEDIA_TYPE.Movie;
                    return w;
                });
                const tvWatched = watchedCollection[1].result.map(w => {
                    w.modified = w.lastModified;
                    w.mediaType = MEDIA_TYPE.TV;
                    return w;
                });
                const allResult = movieWatched.concat(tvWatched);
                const result = await parseWatchedData(allResult, API_TYPE.CLOUD, 10, formatMessage);
                if (!unmounted.current) {
                    setData(result);
                    setTotalSize(result.length);
                }
            }
        }

        fetchData();
    }, [apiType]);

    if (error) {
        return [error];
    }
    // logger.log(data);
    return [data, totalSize];
};

export const useCollections = (params) => {
    const {mediaType, sort, asc, pageSize, pageIndex, showPincode, deletePath, filter, sortWait} = params;
    const [error, setError] = useState("");
    const [data, setData] = useState({list: [], totalSize: 0, timeStamp: undefined});
    const apiType = useSelector(state => state.user.apiType);
    const [secondAttempt, setSecondAttempt] = useState(false);
    const [connectionError, setConnectionError] = useState(false);
    const unmounted = useUnmounted();
    const dispatch = useDispatch();
    const {key: {[apiType]: defaultSort}, asc: defaultAsc} = CONTENT_CONFIG[mediaType].config[DATA_TYPE.LIST].sort[0];

    useEffect(() => {
        async function fetchData() {
            if (apiType === API_TYPE.MEDIA_SERVER) {
                const result = await browseMedia({
                    path: MEDIA_CONFIG[DATA_TYPE.COLLECTION][mediaType].path,
                    sortFilter: !sort ? undefined : sort[API_TYPE.MEDIA_SERVER] === undefined ? defaultSort : sort[API_TYPE.MEDIA_SERVER],
                    sortFilter_order: !sort ? undefined : (asc === undefined ? defaultAsc : asc) ? 0 : 1,
                    pageSize,
                    pageIndex
                });
                if (!unmounted.current) {
                    if (result && result.status === "success") {
                        setData({
                            list: result.contents ? result.contents.map(m => {
                                return {
                                    ...m,
                                    pageIndex,
                                    sort: sort ? sort[apiType] !== undefined ? sort[apiType] : 'default' : 'default',
                                    totalSize: result.totalSize || data.totalSize,
                                }
                            }) : [],
                            totalSize: result.totalSize || data.totalSize,
                            timeStamp: new Date().getTime()
                        });
                    } else {
                        setConnectionError(true);
                    }
                }
            }
            else if (apiType === API_TYPE.CLOUD) {
                // console.log({[filter && filter.key]: filter && filter.value[API_TYPE.CLOUD]});
                const result = await getList({
                    key: CLOUD_CONFIG[DATA_TYPE.COLLECTION][mediaType].key,
                    sort: !sort ? undefined : (sort[API_TYPE.CLOUD] === undefined ? defaultSort : sort[API_TYPE.CLOUD]),
                    asc: !sort ? undefined : (asc === undefined ? defaultAsc : asc),
                    pageSize,
                    pageIndex,
                    customParams: {[filter && filter.key]: filter && filter.value[API_TYPE.CLOUD]}
                });

                if (result && result.hasOwnProperty("result") && !unmounted.current) {
                    setData({
                        list: result.result.map(m => {
                            return {
                                ...m,
                                pageIndex,
                                sort: sort ? sort[apiType] !== undefined ? sort[apiType] : 'default' : 'default',
                                totalSize: result.totalSize,
                            }
                        }) || [],
                        totalSize: result.totalSize,
                        timeStamp: new Date().getTime()
                    });
                }
            }
        }

        if (Boolean(pageIndex) && !sortWait) {
            fetchData();
        }
    }, [apiType, mediaType, sort, asc, pageSize, pageIndex, secondAttempt, deletePath, filter && filter.value, sortWait]);


    useTimeout(() => {
        if(connectionError && !secondAttempt) {
            setSecondAttempt(true);
        }
    }, 10000);

    if (connectionError && secondAttempt){
        dispatch({type: USER_ACTIONS.CONNECTION_ERROR, connectionError: true});
    }

    if (error) {
        return [error];
    }

    return [data.list, data.totalSize, data.timeStamp];
};

export const useFlatten = (params) => {
    const {mediaType, pageSize, pageIndex, deletePath} = params;
    const [error, setError] = useState("");
    const [data, setData] = useState({list: [], totalSize: 0, timeStamp: undefined});
    const apiType = useSelector(state => state.user.apiType);
    const unmounted = useUnmounted();

    useEffect(() => {
        async function fetchData() {
            if (apiType === API_TYPE.MEDIA_SERVER) {
                const collection = await browseMedia({
                    path: MEDIA_CONFIG[DATA_TYPE.COLLECTION][mediaType].path,
                    pageSize,
                    pageIndex
                });
                let result = [];
                if (collection && collection.contents && collection.status === "success") {
                    const contentResults = await Promise.all(collection.contents.map(c => browseMedia({
                        path: c.path,
                        pageSize,
                        pageIndex
                    })));
                    contentResults.map(c => {
                        result = result.concat(c.contents || [])
                    });
                }
                result.sort((a, b) => parseInt(b.modified) - parseInt(a.modified));
                if (!unmounted.current) {
                    setData({list: result, totalSize: result.length, timeStamp: new Date().getTime()});
                }
            }
            else if (apiType === API_TYPE.CLOUD) {
                const result = await getList({
                    key: CLOUD_CONFIG[DATA_TYPE.FLATTEN][mediaType].key,
                    pageSize,
                    pageIndex
                });
                if (result && result.hasOwnProperty("result") && !unmounted.current) {
                    setData({list: result.result || [], totalSize: result.totalSize, timeStamp: new Date().getTime()});
                }
            }
        }

        fetchData();
    }, [apiType, mediaType, pageSize, pageIndex, deletePath]);

    if (error) {
        return [error];
    }

    return [data.list, data.totalSize, data.timeStamp];
};

export const useList = (params) => {
    const {path, mediaType, sort, asc, pageSize, pageIndex, deletePath, sortWait} = params;
    const [error, setError] = useState("");
    const [data, setData] = useState({collection: undefined, list: [], totalSize: 0, timeStamp: undefined});
    const unmounted = useUnmounted();
    const pincode = sessionCacheService.get(path);
    const apiType = useSelector(state => state.user.apiType);
    const {asc: defaultAsc, key: {[apiType]: defaultSort}} = CONTENT_CONFIG[mediaType].config[DATA_TYPE.LIST].sort[0];

    useEffect(() => {
        async function fetchData() {
            if (apiType === API_TYPE.MEDIA_SERVER) {
                const parent = await browseMedia({path, isSpecificFolder: 1});
                const result = await browseMedia({
                    path,
                    pincode,
                    sortFilter: !sort ? undefined : sort[API_TYPE.MEDIA_SERVER] === undefined ? defaultSort : sort[API_TYPE.MEDIA_SERVER],
                    sortFilter_order: !sort ? undefined : (asc === undefined ? defaultAsc : asc) ? 0 : 1,
                    pageSize,
                    pageIndex
                });
                if (result && result.status === "success" && !unmounted.current) {
                    setData({
                        collection: {name: parent.contents && parent.contents[0].tags.name},
                        list: result.contents ? result.contents.map(m => {
                            return {...m,
                                pageIndex,
                                totalSize: result.totalSize || data.totalSize,
                                sort: sort ? sort[apiType] !== undefined ? sort[apiType] : 'default' : 'default'
                            }
                        }) : [],
                        totalSize: result.totalSize || data.totalSize,
                        timeStamp: new Date().getTime(),
                        asc
                    });
                }
            } else if (apiType === API_TYPE.CLOUD) {
                const result = await getList({
                    key: CLOUD_CONFIG[DATA_TYPE.LIST][mediaType].key,
                    path,
                    sort: !sort ? undefined : sort[API_TYPE.CLOUD] === undefined ? defaultSort : sort[API_TYPE.CLOUD],
                    asc: !sort ? undefined : asc === undefined ? defaultAsc : asc,
                    pageSize,
                    pageIndex
                });
                if (result && result.hasOwnProperty("result") && !unmounted.current) {
                    const match = /\/Collection_(.*)\//.exec(path);
                    setData({
                        collection: {name: match ? match[1] : result.parent ? result.parent.name : ''},
                        list: result.result.map(m => {
                            return {
                                ...m,
                                pageIndex,
                                totalSize: result.totalSize,
                                sort: sort ? sort[apiType] !== undefined ? sort[apiType] : 'default' : 'default'
                            }
                        }) || [],
                        totalSize: result.totalSize,
                        timeStamp: new Date().getTime()
                    });
                }
            }
        }

        if (path && Boolean(pageIndex) && !sortWait) {
            fetchData();
        }
    }, [apiType, path, sort, asc, pageSize, pageIndex, deletePath, sortWait]);

    if (error) {
        return [error];
    }

    return [data.collection, data.list, data.totalSize, data.timeStamp];
};

export const useDetail = (params) => {
    const {path, mediaType, pageSize, pageIndex, deletePath} = params;
    const [data, setData] = useState(undefined);
    const [error, setError] = useState("");
    const [totalSize, setTotalSize] = useState(0);
    const apiType = useSelector(state => state.user.apiType);
    const pincode = sessionCacheService.get(getFolderPath(path));

    useEffect(() => {
        async function fetchData() {
            if (apiType === API_TYPE.MEDIA_SERVER) {
                const parent = await browseMedia({path, pincode, isSpecificFolder: 1});
                const result = await browseMedia({path: path, pageSize: pageSize, pincode, pageIndex: pageIndex});
                if (result && result.status === "success") {
                    setData({parent: parent.contents, data: result.contents});
                    setTotalSize(result.totalSize);
                }
            } else if (apiType === API_TYPE.CLOUD) {
                const result = await getDetail({
                    key: CLOUD_CONFIG[DATA_TYPE.DETAIL][mediaType].key,
                    path,
                    pageSize,
                    pageIndex
                });
                if (result) {
                    setData({data: result.result});
                }
            }
        }

        if (path) {
            fetchData();
        }
    }, [apiType, path, deletePath]);

    if (error) {
        return error;
    }

    return [data, totalSize];
};

export const usePlay = (params) => {
    const {path, mediaType, pageSize, pageIndex} = params;
    const apiType = useSelector(state => state.user.apiType);
    const playPath = getParentPath(apiType, path, mediaType);
    const pincode = sessionCacheService.get(getFolderPath(path));
    const [data, setData] = useState(undefined);
    const [error, setError] = useState("");

    useEffect(() => {
        async function fetchData() {
            if (apiType === API_TYPE.MEDIA_SERVER) {
                const parent = await browseMedia({path: playPath, pincode, isSpecificFolder: 1});
                const result = await browseMedia({path: playPath, pincode, pageSize, pageIndex});
                if (result && result.status === "success") {
                    setData({
                        parent: parent.contents,
                        data: result.contents,
                        totalSize: result.totalSize,
                        timestamp: new Date().getTime()
                    });
                }
            } else if (apiType === API_TYPE.CLOUD) {
                const parentPath = mediaType === MEDIA_TYPE.Video ? path.substring(0, path.lastIndexOf("/") + 1) : playPath;
                const result = await getDetail({
                    key: CLOUD_CONFIG[DATA_TYPE.DETAIL][mediaType].key,
                    path: playPath,
                    pageSize,
                    pageIndex
                });
                if (result) {
                    setData({
                        parent: {path: parentPath},
                        data: result.result,
                        totalSize: result.totalSize,
                        timestamp: new Date().getTime()
                    });
                }
            }
        }

        if (path) {
            fetchData();
        }
    }, [apiType, playPath, mediaType, pageIndex]);

    if (error) {
        return error;
    }

    return data;
};

export const useMusic = (params) => {
    const {path, artist, album, pageSize, pageIndex, deletePath} = params;
    const [data, setData] = useState({timestamp: undefined});
    const [error, setError] = useState("");
    const apiType = useSelector(state => state.user.apiType);
    const unmounted = useUnmounted();

    useEffect(() => {
        async function fetchData() {
            if (apiType === API_TYPE.MEDIA_SERVER) {
                const result = await browseMedia({path: path, pageSize, pageIndex});
                if (result && result.status === "success") {
                    setData({
                        parent: parent.contents,
                        data: result.contents ? result.contents.map(m => {return {...m, pageIndex, parent: path, totalSize: result.totalSize}}) : [],
                        totalSize: result.totalSize,
                        timestamp: new Date().getTime()
                    });
                }
            } else if (apiType === API_TYPE.CLOUD) {
                const result = await getMusicByTag({
                    tag: JSON.stringify({albumTitle: album, artist, folder: false}),
                    sort: "albumTitle,trackNumber",  // trackNumber
                    asc: true,
                    pageSize,
                    pageIndex
                });
                if (result && !unmounted.current) {
                    setData({
                        data: result.result ? result.result.map(m => {return {...m, pageIndex, parent: path, totalSize: result.totalSize}}) : [],
                        totalSize: result.totalSize,
                        timestamp: new Date().getTime()
                    });
                }
            }
        }

        if (path && pageIndex) {
            fetchData();
        }
    }, [apiType, pageSize, pageIndex, path, deletePath]);

    if (error) {
        return error;
    }

    return data;
};

function getDataType(folder, mediaType, path) {
    if(mediaType === MEDIA_TYPE.Music) {
        return DATA_TYPE.DETAIL;
    }
    if(mediaType === MEDIA_TYPE.TV && path.indexOf("collection") < 0) {
        return DATA_TYPE.DETAIL;
    }
    return folder ? DATA_TYPE.LIST : DATA_TYPE.DETAIL;
}

function generateTypeFromSharedInfo(sharedInfo, folder) {
    const mediaType = getMediaTypeFromPath(sharedInfo.path);
    const dataType = getDataType(folder, mediaType, sharedInfo.path);
    return {...sharedInfo, dataType, mediaType};
}

export const useShare = (params) => {
    const {sharedId, pincode, pageIndex, pageSize, sort, asc, revisionId, tag, isExpired} = params;
    const savedPincode = useSelector(state => state.pincode.map[sharedId]);
    const [data, setData] = useState({shareData: undefined, sharedInfo: undefined, totalSize: 0, timestamp: undefined});
    useEffect(() => {
        async function fetchData() {
            const result = await queryShare({
                sharedId,
                revisionId,
                sort,
                asc,
                pageSize,
                pageIndex,
                pincode: pincode || savedPincode,
                tag
            });
            if (!result) {
                return;
            }
            if (result.hasOwnProperty("result")) {
                let artistShare = {result: undefined};
                if(!tag && result.sharedInfo.path.indexOf("/Tag/Music/artist/") === 0) {
                    artistShare = await queryShare({
                        sharedId,
                        revisionId,
                        sort,
                        asc,
                        pageSize,
                        pageIndex,
                        pincode: pincode || savedPincode,
                        tag: `artist:${result.result[0].artist}`
                    });
                }
                setData({
                    shareData: artistShare.result || (Array.isArray(result.result) ? result.result.map( data => { return {...data, pageIndex} } ) : result.result),
                    sharedInfo: generateTypeFromSharedInfo(result.sharedInfo, Array.isArray(result.result)),
                    totalSize: artistShare.result ? artistShare.totalSize : result.totalSize,
                    timestamp: new Date().getTime(),
                    tag: artistShare.result ? `artist:${result.result[0].artist}` : undefined
                });
            } else if (result.errorCode === "202" || result.errorCode === "222") {
                setData({notFound: true, timestamp: new Date().getTime()});
            } else if (result.errorMessage === "expired") {
                setData({expired: true, timestamp: new Date().getTime()});
            } else if (result.errorMessage === "pin code not match") {
                setData({needPincode: true, sharer: result.info && result.info.sharer, timestamp: new Date().getTime()});
            }
        }

        if (sharedId) {
            fetchData();
        }
    }, [sharedId, revisionId, sort, asc, pincode, pageIndex, pageSize, isExpired]);
    return useMemo(() => {
        if (!data.timestamp) {
            return {timestamp: undefined};
        }
        if (data.notFound || data.expired || data.needPincode) {
            return data;
        }
        if (data.sharedInfo.dataType === DATA_TYPE.DETAIL) {
            const detail = DATA_PARSER[DATA_TYPE.DETAIL][API_TYPE.CLOUD][data.sharedInfo.mediaType]([data.sharedInfo], data.shareData, DATA_SOURCE.SHARE);
            if(data.sharedInfo.mediaType === MEDIA_TYPE.TV) {
                data.sharedInfo.name = detail.title;
            }
            return {
                data: detail,
                info: data.sharedInfo, totalSize: data.totalSize, timestamp: data.timestamp,
                pageSize, pageIndex, singleShare: !data.sharedInfo.isFolder, tag: data.tag,
                isDownload: data.sharedInfo.mediaType === MEDIA_TYPE.Template || data.sharedInfo.mediaType === MEDIA_TYPE.Project
            };
        }
        return {
            ...DATA_PARSER[data.sharedInfo.dataType][API_TYPE.CLOUD][data.sharedInfo.mediaType]([{
                ...data.sharedInfo,
                sharedId: sharedId
            }], data.shareData),
            info: data.sharedInfo, totalSize: data.totalSize, timestamp: data.timestamp,
            pageSize, pageIndex, tag: data.tag
        };
    }, [get(data, "timestamp")]);
};

function parseSearchData(contents) {
    const result = {
        [MEDIA_TYPE.Movie]: [], [MEDIA_TYPE.TV]: [], [MEDIA_TYPE.Video]: [], [MEDIA_TYPE.Photo]: [],
        [MEDIA_TYPE.Music]: [], count: contents.length
    };
    const fileResult = {
        [MEDIA_TYPE.Movie]: [], [MEDIA_TYPE.TV]: [], [MEDIA_TYPE.Video]: [], [MEDIA_TYPE.Photo]: [],
        [MEDIA_TYPE.Music]: []
    };
    contents.map(items => {
        if(!MEDIA_TYPE[items.mediaType]) {
            return;
        }
        if (items.tags.queryType === 'File') {
            result[items.mediaType].push(items);
        } else {
            fileResult[items.mediaType].push(items);
        }
    });
    Object.keys(MEDIA_TYPE).map(m => {
        result[m] = fileResult[m].concat(result[m]);
    });
    return result;
}

async function loadSearch(params, prevContents = []) {
    const {pageSize, keyword, isLocal} = params;
    let {pageIndex} = params, tempResult;

    const result = await searchMedia({pageSize, pageIndex, keyword, isLocal});
    prevContents = prevContents.concat(result.contents);

    if (!result.browseEnd) {
        ++pageIndex;
        tempResult = await loadSearch({pageSize, pageIndex, keyword, isLocal}, prevContents);
    } else {
        prevContents.pop();
        result.contents = prevContents;
        return result;
    }
    result.contents = tempResult.contents;
    return result;
}

export const useSearch = (params) => {
    const {pageSize, pageIndex, keyword, deletePath} = params;
    const [data, setData] = useState(undefined);
    const [error, setError] = useState("");
    const apiType = useSelector(state => state.user.apiType);
    useEffect(() => {
        async function fetchData() {
            if (apiType === API_TYPE.MEDIA_SERVER) {
                const searchResult = await Promise.all([
                    await browseMedia({
                        path: MEDIA_CONFIG[DATA_TYPE.COLLECTION][MEDIA_TYPE.Movie].path + "/",
                        pageSize,
                        pageIndex,
                        keyword,
                        searchFilter: 9
                    }),
                    await browseMedia({
                        path: MEDIA_CONFIG[DATA_TYPE.COLLECTION][MEDIA_TYPE.TV].path + "/",
                        pageSize,
                        pageIndex,
                        keyword,
                        searchFilter: 9
                    }),
                    await loadSearch({...params})
                ]);
                let result = [];
                searchResult.map(r => {
                    if(r && r.status === "success" && Array.isArray(r.contents)) {
                        result = result.concat(r.contents);
                    }
                });
                setData(parseSearchData(result));
            }
            else if (apiType === API_TYPE.CLOUD) {
                const searchResult = await Promise.all([
                    getSearch({
                        key: CLOUD_CONFIG[DATA_TYPE.COLLECTION][MEDIA_TYPE.Movie].key,
                        keyword,
                        pageSize,
                        pageIndex
                    }),
                    getSearch({key: "movie.search", keyword, pageSize, pageIndex}),
                    getSearch({
                        key: CLOUD_CONFIG[DATA_TYPE.COLLECTION][MEDIA_TYPE.TV].key,
                        keyword,
                        pageSize,
                        pageIndex
                    }),
                    getSearch({key: "tv.search", keyword, pageSize, pageIndex}),
                    getSearch({key: "video.search", keyword, sort: "isFolder:desc", pageSize, pageIndex}),
                    getSearch({key: "photo.search", keyword, sort: "isFolder:desc", pageSize, pageIndex}),
                    getSearch({
                        key: "music.albums",
                        keyword: JSON.stringify({"albumTitle": keyword, "artist": keyword}),
                        pageSize,
                        pageIndex
                    }),
                    getSearch({
                        key: "music.search",
                        keyword: JSON.stringify({"title": keyword}),
                        pageSize,
                        pageIndex
                    }),
                    getSearch({key: "template.search", keyword, sort: "isFolder:desc", pageSize, pageIndex}),
                    getSearch({key: "project.search", keyword, sort: "isFolder:desc", pageSize, pageIndex}),
                ]);

                // console.log("searchResult", searchResult)
                setData({
                    [MEDIA_TYPE.Movie]: searchResult[0].result.concat(searchResult[1].result),
                    [MEDIA_TYPE.TV]: searchResult[2].result.concat(searchResult[3].result),
                    [MEDIA_TYPE.Video]: searchResult[4].result,
                    [MEDIA_TYPE.Photo]: searchResult[5].result,
                    [MEDIA_TYPE.Music]: searchResult[6].result.concat(searchResult[7].result),
                    [MEDIA_TYPE.Template]: searchResult[8].result,
                    [MEDIA_TYPE.Project]: searchResult[9].result,
                    count: searchResult.reduce((acc, s)=> acc + s.result.length, 0)
                });
            }
        }

        if (keyword) {
            fetchData();
        }
    }, [apiType, keyword, deletePath]);

    if (error) {
        return error;
    }
    return data;
};

const currName = sessionService.getName() + sessionService.getUUID();

const order = (a,b) => {
    if(a.order < b.order || a.pageIndex < b.pageIndex) return -1;
    else return 1;
};

const listOrder = (a,b) => {
    if (a.pageIndex === b.pageIndex) {
        if (a.order < b.order) return -1;
        else return 1;
    }
    else if (a.pageIndex < b.pageIndex) return -1;
    else return 1;
};

export const evaluateArr = (arr1, arr2) => {
    return arr1.filter(function(obj) {
        return !arr2.some(function (obj2) {
            return obj.distinctPath === obj2.distinctPath &&
                obj.order === obj2.order &&
                obj.pageIndex === obj2.pageIndex &&
                obj.dataType === obj2.dataType &&
                obj.sort === obj2.sort &&
                obj.totalSize === obj2.totalSize &&
                obj.childCount === obj2.childCount;
        });
    });
};

const compareFetchArray = (existingArray, parsedDataArray, objectStore) =>{
    //Find values that are in existingArray but not in parsedDataArray
    let uniqueResultOne = evaluateArr(existingArray, parsedDataArray);

    //Find values that are in parsedDataArray but not in existingArray
    let uniqueResultTwo = evaluateArr(parsedDataArray, existingArray);

    if(JSON.stringify(existingArray) !== JSON.stringify(parsedDataArray)){
        if(Boolean(uniqueResultOne.length && uniqueResultTwo.length)){
            uniqueResultOne.map(async m => {
                await indexedDb.deleteItem(m.distinctPath, objectStore);
            });
            add2IndexDB({ array: uniqueResultTwo, objectStore });
        } else {
            add2IndexDB({ array: parsedDataArray, objectStore });
        }
        return true;
    } else {
        return false;
    }
};

export const useIndexDB = async (params) => {
    const { key, index, objectStore } = params;
    try {
        return await indexedDb.getByIndex(key, index, objectStore);
    } catch (e) {
        return [];
    }
};

export const add2IndexDB = (params) => {
    const { array, objectStore } = params;
    try {
        indexedDb.addItem(array, objectStore);
    } catch (e) {
        return null;
    }
};

export const useParsedCollection = (params) => {
    const { deletePath, mediaType, pageSize, pageIndex, sort } = params;
    const unmounted = useUnmounted();
    const apiType = useSelector(state => state.user.apiType);
    const [c, cSize, cTimestamp] = useCollections(params);
    const [parsedCollection, setParsedCollection] = useState({ data: [], size: 0, timestamp: 0 });
    const {
        objectStore,
        index,
        keyPath,
        dataSort
    } = DATA_HOOK_CONFIG.useParsedCollection({ apiType, mediaType, sort });
    const parsedDataArray =
        apiType !== API_TYPE.UNKNOWN && cTimestamp &&
        DATA_PARSER[DATA_TYPE.COLLECTION][apiType][mediaType](c).data.sort(order);
    let existingArray, key, result, values;
    let diffResult = false;
    // skip indexDB for templates and projects (temporary)
    const skipIndexDB = mediaType === MEDIA_TYPE.Template || mediaType === MEDIA_TYPE.Project;

    const setValues = params => {
        const { data, size } = params;
        if (!unmounted.current) {
            setParsedCollection({
                data,
                size,
                timestamp: new Date().getTime()
            });
        }
    };

    useEffect(() => {
        const fetchItem = async () => {
            key = [keyPath, pageIndex, dataSort, currName];
            result = await useIndexDB({key, index, objectStore});
            existingArray = result.sort(order);

            if (existingArray.length > 0) {
                logger.log(`${mediaType} collection exists`);
                values = {data: existingArray, size: existingArray[0].totalSize};
            } else {
                logger.log(`${mediaType} collection doesnt exist`);
                values = {collection: c, data: parsedDataArray, size: cSize};
                if (!skipIndexDB) {
                    add2IndexDB({array: parsedDataArray, objectStore});
                }
            }

            setValues(values);
        };
        if (apiType !== API_TYPE.UNKNOWN && Boolean(cTimestamp))
            fetchItem();
    }, [cTimestamp]);

    useEffect(()=>{
        const updateIDB = async () => {
            if (parsedDataArray.length) {
                key = [keyPath, parsedDataArray[0].pageIndex, parsedDataArray[0].sort, currName];
                result = await useIndexDB({key, index, objectStore});
                existingArray = result.sort(order);
                diffResult = compareFetchArray(existingArray, parsedDataArray, objectStore);

                if (diffResult) {
                    values = { data: parsedDataArray, size: parsedDataArray[0].totalSize };
                    setValues(values);
                }
            } else if (!Boolean(parsedDataArray.length || cSize)) {
                key = [keyPath, pageIndex, dataSort, currName];
                result = await useIndexDB({key, index, objectStore});
                result.map(async m => {
                    await indexedDb.deleteItem(m.distinctPath, objectStore);
                });
                values = {data: [], size: 0};
                setValues(values);
            }
        };
        if(apiType !== API_TYPE.UNKNOWN && Boolean(cTimestamp) && !skipIndexDB)
            updateIDB();
    },[cTimestamp, deletePath]);

    return useMemo(() => {
        const { data, size, timestamp } = parsedCollection;
        if (!timestamp) {
            return {timestamp: undefined};
        }
        return {
            data,
            timestamp,
            pageSize,
            pageIndex,
            totalSize: size,
        };
    }, [parsedCollection.timestamp]);
};

export const useParsedFlatten = (params) => {
    const {mediaType, deletePath} = params;
    const unmounted = useUnmounted();
    const apiType = useSelector(state => state.user.apiType);
    const [f, fSize, fTimestamp] = useFlatten(params);
    const [parsedFlatten, setParsedFlatten] = useState({data: [], size: 0, timestamp: 0});
    const {
        objectStore,
        index,
        keyPath
    } = DATA_HOOK_CONFIG.useParsedFlatten({ apiType, mediaType });
    const parsedDataArray =
        apiType !== API_TYPE.UNKNOWN && fTimestamp &&
        DATA_PARSER[DATA_TYPE.LIST][apiType][mediaType]([{}], f, fSize).data;
    const key = [keyPath, currName];
    let existingArray, result, values;
    let diffResult = false;
    // skip indexDB for templates and projects (temporary)
    const skipIndexDB = mediaType === MEDIA_TYPE.Template || mediaType === MEDIA_TYPE.Project;

    const setValues = params => {
        const { data, size } = params;
        if (!unmounted.current) {
            setParsedFlatten({
                data,
                size,
                timestamp: new Date().getTime()
            });
        }
    };

    useEffect(()=>{
        const fetchItem = async () => {
            result = await useIndexDB({key, index, objectStore});

            if (apiType === API_TYPE.MEDIA_SERVER) {
                existingArray = result.filter(r => r.distinctPath.match(`/PDVD/${mediaType}/Folder`));
            } else {
                existingArray = result.filter(r => !r.parent && !r.sort && !r.folder);
            }

            if (existingArray.length > 0) {
                logger.log(`${mediaType} flatten exists`);
                values = {data: existingArray, size: existingArray.length};
            } else {
                logger.log(`${mediaType} flatten doesnt exist`);
                values = {data: parsedDataArray, list: f, size: fSize};
                if (!skipIndexDB) {
                    add2IndexDB({array: parsedDataArray, objectStore});
                }
            }

            setValues(values);
        };

        if(apiType !== API_TYPE.UNKNOWN && Boolean(fTimestamp))
            fetchItem();
    }, [fTimestamp]);

    useEffect(()=>{
        const updateIDB = async () => {
            if (parsedDataArray.length) {
                result = await useIndexDB({key, index, objectStore});
                if (apiType === API_TYPE.MEDIA_SERVER) {
                    existingArray = result.filter(r => r.distinctPath.match(`/PDVD/${mediaType}/Folder`));
                } else {
                    existingArray = result.filter(r => !r.parent && !r.sort && !r.folder);
                }

                existingArray = existingArray.sort(order);
                diffResult = compareFetchArray(existingArray, parsedDataArray, objectStore);
                if (diffResult) {
                    values = {data: parsedDataArray, size: parsedDataArray[0].totalSize};
                    setValues(values);
                }
            }
        };
        if(apiType !== API_TYPE.UNKNOWN && Boolean(fTimestamp) && !skipIndexDB)
            updateIDB();
    },[fTimestamp, deletePath]);

    return useMemo(() => {
        const { data, size, timestamp } = parsedFlatten;
        if (!timestamp) {
            return {timestamp: undefined};
        }
        return {
            data,
            timestamp,
            totalSize: size
        };
    }, [parsedFlatten.timestamp]);
};

function determineItemArray(result, mediaType, apiType){
    let matchString = '';
    let existingArray = [];

    if (apiType === API_TYPE.MEDIA_SERVER) {
        if (mediaType === MEDIA_TYPE.Movie || mediaType === MEDIA_TYPE.TV)
            matchString = `/PDVD/${mediaType}/Collection`;
        else if (mediaType === MEDIA_TYPE.Music)
            matchString = `/PDVD/${mediaType}/Album`;
        else
            matchString = `/PDVD/${mediaType}/Folder`;
    } else if (apiType === API_TYPE.CLOUD){
        matchString = `/PDVD/${mediaType}`;
    }

    if (matchString && result) {
        existingArray = result.filter(r => r.distinctPath.match(matchString)).sort(order);
    }

    if (existingArray.length > 0) {
        logger.log(`${mediaType} list exists`);
        return [true, existingArray];
    } else {
        logger.log(`${mediaType} list doesnt exist`);
        return [false, []];
    }
}

export const useParsedList = (params) => {
    const {deletePath, mediaType, pageSize, pageIndex, parentFolder, sort} = params;
    const unmounted = useUnmounted();
    const apiType = useSelector(state => state.user.apiType);
    const [c, l, lSize, lTimestamp] = useList(params);
    const [parsedList, setParsedList] = useState({data: [], size: 0, timestamp: 0});
    const {
        objectStore,
        index,
        keyPath,
        dataSort
    } = DATA_HOOK_CONFIG.useParsedList({ apiType, mediaType, sort });
    const parsedDataArray =
        apiType !== API_TYPE.UNKNOWN && c && l &&
        DATA_PARSER[DATA_TYPE.LIST][apiType][mediaType]([c], l).data.sort(order);
    const parent = parentFolder || (c && c.name) || '';
    let existingArray, key, result, values;
    let exist = false;

    const setValues = params => {
        const { data, size } = params;
        if (!unmounted.current) {
            setParsedList({
                data,
                size,
                timestamp: new Date().getTime()
            });
        }
    };

    useEffect(()=>{
        const evaluateValues = (result) => {
            [exist, existingArray] = determineItemArray(result, mediaType, apiType);
            if (exist && existingArray.every(e => e.totalSize === lSize && e.pageIndex === pageIndex)) {
                values = {data: existingArray, size: existingArray[0].totalSize};
                setValues(values);
            } else {
                values = {data: parsedDataArray, size: lSize};
                setValues(values);
                add2IndexDB({array: parsedDataArray, objectStore});
            }
        };

        const fetchItem = async () => {
            if (mediaType !== MEDIA_TYPE.Music && (pageIndex <= Math.ceil(parsedList.size / pageSize) || parsedList.size === 0)) {
                if (parent) {
                    key = [keyPath.withParent, parent, pageIndex, dataSort, currName];
                    result = await useIndexDB({key, index: index.withParent, objectStore});
                    evaluateValues(result);
                }
                else {
                    key = [keyPath.withoutParent[apiType], pageIndex, dataSort, currName];
                    result = await useIndexDB({key, index: index.withoutParent, objectStore});
                    evaluateValues(result);
                }
            }
            else {
                key = [keyPath.forMusic, pageIndex, dataSort, currName];
                result = await useIndexDB({key, index: index.forMusic, objectStore});
                evaluateValues(result);
            }
        };

        if (apiType !== API_TYPE.UNKNOWN && pageIndex > 0 && c) {
            fetchItem();
        }
    }, [lTimestamp]);

    useEffect(() => {
        const updateValues = (params) => {
            const {parsedDataArray, result} = params;
            const evalArray = determineItemArray(result, mediaType, apiType)[1];
            const diffResult = compareFetchArray(evalArray, parsedDataArray, objectStore);
            if (diffResult) {
                values = {data: parsedDataArray, size: parsedDataArray[0].totalSize};
                setValues(values);
            }
        };

        const updateIDB = async () => {
            if (parsedDataArray.length) {
                if (mediaType !== MEDIA_TYPE.Music) {
                    if (parsedDataArray[0].parent) {
                        key = [keyPath.withParent, parent, pageIndex, dataSort, currName];
                        result = await useIndexDB({key, index: index.withParent, objectStore});
                        updateValues({parsedDataArray, result});
                    }
                    else {
                        key = [keyPath.withoutParent[apiType], pageIndex, dataSort, currName];
                        result = await useIndexDB({key, index: index.withoutParent, objectStore});
                        updateValues({parsedDataArray, result});
                    }
                }
                else {
                    key = [keyPath.forMusic, pageIndex, dataSort, currName];
                    result = await useIndexDB({key, index: index.forMusic, objectStore});
                    updateValues({parsedDataArray, result});
                }
            }
        };
        if(c && apiType !== API_TYPE.UNKNOWN && Boolean(lTimestamp))
            updateIDB();
    },[lTimestamp, deletePath]);

    return useMemo(() => {
        const { data, size, timestamp } = parsedList;
        if (!timestamp) {
            return {timestamp: undefined};
        }
        return {
            data,
            timestamp,
            pageSize,
            pageIndex,
            totalSize: size,
            name: c ? c.name : "None"
        };
    }, [parsedList.timestamp]);
};

export const useParsedDetail = (params) => {
    const {path, mediaType, pageSize, pageIndex, deletePath} = params;
    const [detail, detailSize] = useDetail({mediaType, path, pageSize, pageIndex, deletePath});
    const apiType = useSelector(state => state.user.apiType);
    return useMemo(() => {
        if (apiType === API_TYPE.UNKNOWN || !detail) {
            return undefined;
        }
        return DATA_PARSER[DATA_TYPE.DETAIL][apiType][mediaType](detail.parent, detail.data, detailSize);
    }, [apiType, detail]);
};

function fetchCollectionList(grandParent, sort, currName, objectStore, detail, index, keyPath){
    const [data, setData] = useState([]);
    useEffect(() => {
        async function fetchData(){
            const key = [keyPath, grandParent, sort, currName];
            const result = await useIndexDB({key, index, objectStore});
            if (result) setData(result.sort(listOrder));
            else setData(detail);
        }
        fetchData();
    },[grandParent, sort, currName]);

    return data;
}

export const useParsedPlay = (params) => {
    const {path, mediaType, sort, grandParent} = params;
    const {formatMessage} = useIntl();
    const detail = usePlay(params);
    const apiType = useSelector(state => state.user.apiType);
    const {
        objectStore,
        index,
        keyPath
    } = DATA_HOOK_CONFIG.useParsedPlay({ apiType });
    const collectionList = mediaType === MEDIA_TYPE.Video ? fetchCollectionList(grandParent, sort, currName, objectStore, detail, index, keyPath) : [];

    return useMemo(() => {
        if (!detail || !detail.timestamp) {
            return {timestamp: undefined};
        }
        if (apiType === API_TYPE.MEDIA_SERVER) {
            return {
                ...DATA_PARSER[DATA_TYPE.PLAY][apiType][mediaType](path, detail.parent, mediaType === MEDIA_TYPE.Video && grandParent && collectionList.length > 0 ? collectionList : detail.data, formatMessage),
                totalSize: detail.totalSize,
                timestamp: detail.timestamp
            };
        } else {
            return {
                ...DATA_PARSER[DATA_TYPE.PLAY][apiType][mediaType](path, detail.parent, detail.data, mediaType === MEDIA_TYPE.Video ? collectionList : formatMessage),
                totalSize: detail.totalSize,
                timestamp: detail.timestamp
            }
        }

    }, [get(detail, "timestamp"), path, collectionList]);
};

function checkMusicEnabled(arr) {
    const notSupportCloud = ['ac3', 'aob', 'dff', 'dsf', 'dts', 'mid', 'mp2', 'mpa', 'rmi'];
    const notSupportMedia = ['aob', 'dff', 'dsf', 'mpa',  'mid', 'mp2', 'rmi', 'ac3', 'dts', 'mid', 'ape', 'wma'];
    const supportTranMedia = ['ape', 'wma'];
    if (arr) {
        arr.map(m => {
            if (m.original.url.match("cloud")) {
                if (notSupportCloud.find(ele => ele === m.musicFormat.toLowerCase())) {
                    m.disabled = true;
                } else {
                    m.disabled = false;
                }
            } else {
                let judgeContinue = true;
                m.musicFormatArray.map(element => {
                    if (notSupportMedia.find(ele => ele === element.format.toLowerCase())) {
                        m.disabled = true;
                        m.musicFormat = element.format;
                        if (!supportTranMedia.find(ele => ele === element.format.toLowerCase())) {
                            judgeContinue = !judgeContinue;
                        }
                    } else if (judgeContinue) {
                        if (m.title.match('ALAC')) {
                            m.disabled = true;
                            m.musicFormat = 'm4a w ALAC';
                        } else {
                            m.disabled = false;
                            m.original.url = element.url;
                            judgeContinue = !judgeContinue;
                        }
                    }
                })
            }
        });
        return arr;
    } else {
        return arr;
    }
}

export const useParsedMusic = (params) => {
    const {path, pageIndex, pageSize, deletePath} = params;
    const unmounted = useUnmounted();
    const apiType = useSelector(state => state.user.apiType);
    const music = useMusic({...params});
    const [parsedMusic, setParsedMusic] = useState({data: [], size: 0, timestamp: 0});
    const {
        objectStore,
        index,
        keyPath
    } = DATA_HOOK_CONFIG.useParsedMusic({ apiType });
    const key = [keyPath, path, pageIndex, currName];
    let existingArray, diffResult, result, values;

    const setValues = params => {
        const { data, size } = params;
        if (!unmounted.current) {
            setParsedMusic({
                data,
                size,
                timestamp: new Date().getTime()
            });
        }
    };

    useEffect(() => {
        const fetchItem = async () => {
            const parsedDataArray = checkMusicEnabled(DATA_PARSER[DATA_TYPE.DETAIL][apiType][MEDIA_TYPE.Music](null, music.data, music.totalSize)).sort(order);

            result = await useIndexDB({key, index, objectStore});
            existingArray = result.sort(order);
            diffResult = compareFetchArray(existingArray, parsedDataArray, objectStore);

            if (!diffResult) {
                values = {data: existingArray, size: existingArray[0] ? existingArray[0].totalSize : 0};
                setValues(values);
                logger.log(`${MEDIA_TYPE.Music} detail exists`);
            } else {
                values = {data: parsedDataArray, detail: music.data, size: music.totalSize};
                setValues(values);
                logger.log(`${MEDIA_TYPE.Music} detail doesnt exist`);
            }
        };

        if (apiType !== API_TYPE.UNKNOWN && path && music.data) fetchItem();
    },[music.timestamp, deletePath]);

    return useMemo(() => {
        const { data, size, timestamp } = parsedMusic;
        if (apiType === API_TYPE.UNKNOWN || !timestamp) {
            return {timestamp: undefined};
        }
        return {
            data,
            pageSize,
            pageIndex,
            timestamp,
            totalSize: size
        };
    }, [apiType, parsedMusic.timestamp]);
};

export const useParsedSharePlay = (params) => {
    const {sharedId, revisionId, pincode, episodeNumber, isExpired} = params;
    const savedPincode = useSelector(state => state.pincode.map[sharedId]);
    const [data, setData] = useState({shareData: undefined, sharedInfo: undefined, totalSize: 0, timestamp: undefined});
    const {formatMessage} = useIntl();
    useEffect(() => {
        async function fetchData() {
            const result = await queryShare({sharedId, revisionId, pincode: pincode || savedPincode});
            if (!result) {
                return;
            }
            if (result.hasOwnProperty("result")) {
                setData({
                    shareData: result.result,
                    sharedInfo: generateTypeFromSharedInfo(result.sharedInfo),
                    totalSize: result.totalSize,
                    timestamp: new Date().getTime()
                });
            } else if (result.errorMessage === "pin code not match") {
                setData({needPincode: true, timestamp: new Date().getTime()});
            }
        }

        if (sharedId) {
            fetchData();
        }
    }, [sharedId, revisionId, episodeNumber, pincode, isExpired]);

    return useMemo(() => {
        if (!data.timestamp) {
            return {timestamp: undefined};
        }
        if (data.needPincode) {
            return data;
        }
        if (data.sharedInfo.mediaType === MEDIA_TYPE.TV) {
            logger.log(`SharePlay_${API_TYPE.CLOUD}_${data.sharedInfo.mediaType}`, data);
            data.shareData.episodes.sort((a, b) => parseInt(a.episodeNumber) - parseInt(b.episodeNumber));
            let num = data.shareData.episodes.findIndex(e => e.episodeNumber === episodeNumber);
            num = num < 0 ? (Number(localStorage.getItem(`${sharedId}_${revisionId}_episode`)) || 0) : num;
            const episode = data.shareData.episodes[num] ? data.shareData.episodes[num] : data.shareData;
            return {
                parentPath: `/pdvd/share/${sharedId}/${revisionId || 'browse'}`,
                title: `${data.shareData.title || data.shareData.name} ${!data.shareData.title && data.shareData.seasonNumber || ""} - ${formatMessage({id: "general.episode"}, {num: episode.episodeNumber})}`,
                playUrl: episode.original.url,
                duration: episode.duration,
                externalSubtitleInfos: episode.subtitles,
                resumeTime: localStorage.getItem(`${sharedId}_${revisionId}_${num}`) || 0,
                prev: data.shareData.episodes[num - 1] ? `/pdvd/share/${sharedId}/${revisionId || 'browse'}/${data.shareData.episodes[num - 1].episodeNumber}` : undefined,
                next: data.shareData.episodes[num + 1] ? `/pdvd/share/${sharedId}/${revisionId || 'browse'}/${data.shareData.episodes[num + 1].episodeNumber}` : undefined,
                mediaResolution: {x: episode.original.resolutionX, y: episode.original.resolutionY},
                resolutionInfo: [],
                mediaType: data.sharedInfo.mediaType,
                heartbeat: data.sharedInfo.heartbeat,
                revisionId: episode.revisionId,
                settings: {audio: 0, subtitle: undefined, quality: 0},
            };
        }
        logger.log(`SharePlay_${API_TYPE.CLOUD}_${data.sharedInfo.mediaType}`, data);
        return {
            parentPath: (data.sharedInfo.mediaType===MEDIA_TYPE.Video && !data.sharedInfo.isFolder)?null:`/pdvd/share/${sharedId}/${(data.sharedInfo.mediaType !== MEDIA_TYPE.Video && revisionId) || 'browse'}`,
            title: data.shareData.title,
            playUrl: data.shareData.original.url,
            duration: data.shareData.duration,
            resumeTime: localStorage.getItem(`${sharedId}_${revisionId}`) || 0,
            prev: undefined,
            next: undefined,
            mediaResolution: {x: data.shareData.original.resolutionX, y: data.shareData.original.resolutionY},
            resolutionInfo: [],
            externalSubtitleInfos: data.shareData.subtitles,
            mediaType: data.sharedInfo.mediaType,
            heartbeat: data.sharedInfo.heartbeat,
            revisionId: data.shareData.revisionId,
            settings: {audio: 0, subtitle: undefined, quality: 0},
        };
    }, [get(data, "timestamp")]);
};

export const useParsedSearch = (params) => {
    const searchResult = useSearch({...params});
    const apiType = useSelector(state => state.user.apiType);
    return useMemo(() => {
        if (apiType === API_TYPE.UNKNOWN || searchResult === undefined) {
            return undefined;
        } else if (searchResult.count === 0) {
            return 'NotFound';
        }
        const result = {};
        getMediaTypeArray().map(m => {
            if (searchResult[m].length > 0) {
                result[m] = DATA_PARSER[DATA_TYPE.LIST][apiType][m]([{}], searchResult[m]);
            }
        });
        return result;
    }, [apiType, searchResult]);
};

export const useCanPlay = (action, share) => {
    const [canPlay, setCanPlay] = useState(true);
    const apiType = useSelector(state => state.user.apiType);
    let ws, keepaliveTimer;
    const heartbeat = share ? share.heartbeat : api.getApiFromKey('misc.heartbeat');
    const connectData = share ? {action, revisionId: share.revisionId} : {action, token: api.token};
    const disconnectData = share ? {action: 'predisconnect', revisionId: share.revisionId} :
        {action: 'selfdisconnect', token: api.token};
    
    useEffect(() => {
            logger.log(apiType, action, share);
            if ((apiType === API_TYPE.CLOUD && action === 'selfconnect') || (share && share.heartbeat)) {
                window.addEventListener('beforeunload', disconnect);
                ws = new ReconnectingWebSocket(heartbeat);
                ws.onopen = () => {
                    logger.log(`ws onopen`, connectData, share);
                    ws.send(JSON.stringify(connectData))
                };
                ws.onmessage = (e) => {
                    const result = JSON.parse(e.data);
                    logger.log(`ws onmessage`, result);
                    if (result.hasOwnProperty("status")) {
                        setCanPlay(result.status === 'success');
                    }
                };
                ws.onclose = () => {
                    logger.log(`ws onclose`);
                };
                keepaliveTimer = setInterval(() => ws.send("ping"), 4 * 1000 * 60);
                return () => {
                    disconnect();
                    window.removeEventListener('beforeunload', disconnect);
                }
            }
            
            function disconnect() {
                clearInterval(keepaliveTimer);
                if (ws.readyState === 1) {
                    console.log("disconnect")
                    ws.send(JSON.stringify(disconnectData));
                }
                console.log(ws, ws.close);
                ws.close();
            }
        }
        , [share, apiType]);
    
    return canPlay;
};