import config from "config";

export const DATA_TYPE = {
    COLLECTION: "COLLECTION",
    FLATTEN: "FLATTEN",
    LIST: "LIST",
    DETAIL: "DETAIL",
    PLAY: "PLAY"
};

export const DATA_SOURCE = {
    SHARE: "SHARE",
    OWN: "OWN",
    FRIEND: "FRIEND"
};

export const MEDIA_TYPE = {
    Movie: "Movie",
    TV: "TV",
    Video: "Video",
    Photo: "Photo",
    Music: "Music",
    // For CyberLink Cloud
    Template: "Template",
    Project: "Project"
};

export const API_TYPE = {
    CLOUD: "API_TYPE_CLOUD",
    MEDIA_SERVER: "API_TYPE_MEDIA_SERVER",
    UNKNOWN: "API_TYPE_UNKNOWN"
};

export const MEDIA_CONFIG = {
    [DATA_TYPE.COLLECTION]: {
        [MEDIA_TYPE.Movie]: {path: "/PDVD/Movie/Collection"},
        [MEDIA_TYPE.TV]: {path: "/PDVD/TV/Collection"},
        [MEDIA_TYPE.Video]: {path: "/PDVD/Video/Folder"},
        [MEDIA_TYPE.Photo]: {path: "/PDVD/Photo/Folder"},
        [MEDIA_TYPE.Music]: {path: "/PDVD/Music/Album"},
    }
};

export const DELETE_FROM = {
    HOME: 'home',
    SONG: 'song',
    ALBUM: 'album',
    DETAIL: 'detail',
    PLAYER: 'player',
    SEARCH: 'search',
    EPISODE: 'episode',
    PHOTO_GRID: 'photo_grid',
    CONTENT_FOLDER: 'content_folder',
    CONTENT_COLLECTION: 'content_collection'
};

export const CLOUD_CONFIG = {
    [DATA_TYPE.COLLECTION]: {
        [MEDIA_TYPE.Movie]: {path: "/PDVD/Movie/Collection", key: "movie.collections"},
        [MEDIA_TYPE.TV]: {path: "/PDVD/TV/Collection", key: "tv.collections"},
        [MEDIA_TYPE.Video]: {path: "/PDVD/Video/Collection", key: "video.albums"},
        [MEDIA_TYPE.Photo]: {path: "/PDVD/Photo/Collection", key: "photo.albums"},
        [MEDIA_TYPE.Music]: {key: "music.albums"},
        [MEDIA_TYPE.Template]: {key: "template.flatten"},
        [MEDIA_TYPE.Project]: {key: "project.flatten"},
    },
    [DATA_TYPE.LIST]: {
        [MEDIA_TYPE.Movie]: {key: "movie.movies"},
        [MEDIA_TYPE.TV]: {key: "tv.series"},
        [MEDIA_TYPE.Video]: {key: "video.videos"},
        [MEDIA_TYPE.Photo]: {key: "photo.photos"},
        [MEDIA_TYPE.Music]: {key: "music.albums"},
    },
    [DATA_TYPE.DETAIL]: {
        [MEDIA_TYPE.Movie]: {key: "movie.movie"},
        [MEDIA_TYPE.TV]: {key: "tv.episodes"},
        [MEDIA_TYPE.Video]: {key: "video.video"},
        [MEDIA_TYPE.Photo]: {key: "photo.photos"},
        [MEDIA_TYPE.Music]: {key: "music.albumTitle"},
    },
    [DATA_TYPE.FLATTEN]: {
        [MEDIA_TYPE.Video]: {key: "video.flatten"},
        [MEDIA_TYPE.Photo]: {key: "photo.flatten"},
        [MEDIA_TYPE.Music]: {key: "music.albums"},
        [MEDIA_TYPE.Template]: {key: "template.flatten"},
        [MEDIA_TYPE.Project]: {key: "project.flatten"},
    },
};

export const CONTENT_CONFIG = {
    [MEDIA_TYPE.Movie]: {name: "movie", width: 142, height: 214, ratio: (3/2), config: {
        [DATA_TYPE.LIST]: {
            display: {count: false, duration: false},
            sort: [{name: "sort.option.recently-added", key: {[API_TYPE.CLOUD]: "createdTime", [API_TYPE.MEDIA_SERVER]: 1}, asc: false},
                {name: "sort.option.title", key: {[API_TYPE.CLOUD]: "title", [API_TYPE.MEDIA_SERVER]: 0}, asc: true},
                {name: "sort.option.release-date", key: {[API_TYPE.CLOUD]: "releaseDate", [API_TYPE.MEDIA_SERVER]: 3}, asc: false}]
        },
        [DATA_TYPE.DETAIL]: {playType: "PAGE", display: {count: false, duration: false}},
    }},
    [MEDIA_TYPE.TV]: {name: "TV", width: 142, height: 214, ratio: (3/2), config: {
        [DATA_TYPE.LIST]: {
            display: {count: false, duration: false},
            sort: [{name: "sort.option.recently-added", key: {[API_TYPE.CLOUD]: "createdTime", [API_TYPE.MEDIA_SERVER]: 1}, asc: false},
                {name: "sort.option.title", key: {[API_TYPE.CLOUD]: "title", [API_TYPE.MEDIA_SERVER]: 0}, asc: true},
                {name: "sort.option.premiere-date", key: {[API_TYPE.CLOUD]: "releaseDate", [API_TYPE.MEDIA_SERVER]: 3}, asc: false}]
        },
        [DATA_TYPE.DETAIL]: {playType: "PAGE", display: {count: false, duration: false}},
    }},
    [MEDIA_TYPE.Video]: {name: "video", width: 224, height: 126, ratio: (9/16), config: {
        [DATA_TYPE.LIST]: {
            display: {count: true, duration: false},
            sort: [{name: "sort.option.recently-added", key: {[API_TYPE.CLOUD]: "createdTime", [API_TYPE.MEDIA_SERVER]: 1}, asc: false},
                {name: "sort.option.alphabetical", key: {[API_TYPE.CLOUD]: "name", [API_TYPE.MEDIA_SERVER]: 0}, asc: true}]
        },
        [DATA_TYPE.DETAIL]: {playType: "PLAY", display: {count: false, duration: true}},
    }},
    [MEDIA_TYPE.Photo]: {name: "TV", width: 190, height: 126, ratio: (2/3), config: {
        [DATA_TYPE.LIST]: {
            display: {count: true, duration: false},
            sort: [{name: "sort.option.recently-added", key: {[API_TYPE.CLOUD]: "createdTime", [API_TYPE.MEDIA_SERVER]: 1}, asc: false},
                {name: "sort.option.alphabetical", key: {[API_TYPE.CLOUD]: "name", [API_TYPE.MEDIA_SERVER]: 0}, asc: true}]
        },
        [DATA_TYPE.DETAIL]: {playType: "PLAY", display: {count: false, duration: true}},
    }},
    [MEDIA_TYPE.Music]: {name: "music", width: 142, height: 142, ratio: (1), config: {
        [DATA_TYPE.LIST]: {
            display: {count: false, duration: false},
            sort: [{name: "sort.option.recently-added", key: {[API_TYPE.CLOUD]: "createdTime", [API_TYPE.MEDIA_SERVER]: 1}, asc: false},
                {name: "sort.option.alphabetical", key: {[API_TYPE.CLOUD]: "albumTitle", [API_TYPE.MEDIA_SERVER]: 0}, asc: true}]
        },
        [DATA_TYPE.DETAIL]: {playType: "PAGE", display: {count: false, duration: false}},
    }},
    [MEDIA_TYPE.Template]: {name: "movie", width: 190, height: 126, ratio: (2/3), config: {  // name: "template"
        [DATA_TYPE.LIST]: {
            download: true,
            display: {count: false, duration: false},
            sort: [{name: "sort.option.recently-added", key: {[API_TYPE.CLOUD]: "createdTime", [API_TYPE.MEDIA_SERVER]: 1}, asc: false},
                {name: "sort.option.alphabetical", key: {[API_TYPE.CLOUD]: "name", [API_TYPE.MEDIA_SERVER]: 0}, asc: true}]
        },
        [DATA_TYPE.DETAIL]: {playType: "DOWNLOAD", display: {count: false, duration: true}},
    }},
    [MEDIA_TYPE.Project]: {name: "movie", width: 190, height: 126, ratio: (2/3), config: {  // name: "project"
        [DATA_TYPE.LIST]: {
            download: true,
            display: {count: false, duration: false},
            sort: [{name: "sort.option.recently-added", key: {[API_TYPE.CLOUD]: "createdTime", [API_TYPE.MEDIA_SERVER]: 1}, asc: false},
                {name: "sort.option.alphabetical", key: {[API_TYPE.CLOUD]: "name", [API_TYPE.MEDIA_SERVER]: 0}, asc: true}],
            filter: [
                {name: "projects.all.product", key: {[API_TYPE.CLOUD]: undefined}},
                {name: "projects.power-director", key: {[API_TYPE.CLOUD]: "PDR"}},
                {name: "projects.photo-director", key: {[API_TYPE.CLOUD]: "PHD"}},
                {name: "projects.color-director", key: {[API_TYPE.CLOUD]: "CDR"}},
                {name: "projects.audio-director", key: {[API_TYPE.CLOUD]: "ADR"}},
            ]
        },
        [DATA_TYPE.DETAIL]: {playType: "DOWNLOAD", display: {count: false, duration: false}},
    }},
    Cast: {name: "cast", width: 122, height: 122, ratio: (1), config: {
        [DATA_TYPE.LIST]: {display: {count: false, duration: false}},
        [DATA_TYPE.DETAIL]: {playType: "NONE", display: {count: false, duration: false}},
    }},
    Episode: {name: "episode", width: 222, height: 125, config: {
        [DATA_TYPE.LIST]: {display: {count: false, duration: false}},
        [DATA_TYPE.DETAIL]: {playType: "PAGE", display: {count: false, duration: false}},
    }},
    Continue: {name: "continue", width: 432, height: 243, config: {
        [DATA_TYPE.LIST]: {display: {count: false, duration: false}},
        [DATA_TYPE.DETAIL]: {playType: "PAGE", display: {count: false, duration: false}},
    }},
    PhotoGrid: {name: "photoGrid", width: 150, height: 250, config: {
        [DATA_TYPE.LIST]: {display: {count: false, duration: false}},
        [DATA_TYPE.DETAIL]: {playType: "PAGE", display: {count: false, duration: false}},
    }},
    PhotoGallery: {name: "photoGallery", width: 118, height: 118, config: {
        [DATA_TYPE.LIST]: {display: {count: false, duration: false}},
        [DATA_TYPE.DETAIL]: {playType: "PAGE", display: {count: false, duration: false}},
    }},
};

export const MUSIC_PLAYER_TYPE = {
    DISABLE: "DISABLE",
    ENABLE: "ENABLE",
    BACKGROUND: "BACKGROUND"
};

export const RESOLUTION_TYPE = {
    "480p": {level: 2, name: "480p, 1.5Mbps", minHeight: 480, index: 5, bitrate: 868000},
    "720p": {level: 1, name: "720p, 2Mbps", minHeight: 720, index: 3, bitrate: 2962000},
    "1080p": {level: 0, name: "1080p, 5Mbps", minHeight: 1080, index: 2, bitrate: 5232000},
};

export const COLLECTION_URL_MAP = {
    [MEDIA_TYPE.Movie]: `${config.URL_PREFIX}/movies`,
    [MEDIA_TYPE.TV]: `${config.URL_PREFIX}/tvshows`,
    [MEDIA_TYPE.Video]: `${config.URL_PREFIX}/videos`,
    [MEDIA_TYPE.Photo]: `${config.URL_PREFIX}/photos`,
    [MEDIA_TYPE.Music]: `${config.URL_PREFIX}/music`,
    [MEDIA_TYPE.Template]: `${config.URL_PREFIX}/templates`,
    [MEDIA_TYPE.Project]: `${config.URL_PREFIX}/projects`,
};

export const LANGUAGE_MAP = {
    de_DE : "Deutsch",
    en_US : "English",
    es_ES : "español",
    fr_FR : "français",
    it_IT : "italiano",
    pt_BR : "Português (Brasil)",
    ru_RU : "русский",
    zh_CN : "中文(简体)",
    zh_TW : "中文(繁體)",
    ja_JP : "日本語",
    ko_KR : "한국어",
};

export const LANGUAGE_3_CODE = {
    en_US: "enu",
    de_DE: "deu",
    es_ES: "esp",
    fr_FR: "fra",
    it_IT: "ita",
    ja_JP: "jpn",
    zh_TW: "cht",
    zh_CN: "chs",
    ko_KR: "kor",
    pt_BR: "por",
    ru_RU: "rus",
};

export const CONTAINER_FORMAT = {
    0: "NONE",
    1: "MPEG1",
    2: "MPEG2",
    3: "VCD",
    4: "SVCD",
    5: "MPEG4",
    6: "VOB",
    7: "VRO",
    8: "TS",
    9: "AVI",
    10: "DV",
    11: "WM",
    12: "REAL",
    13: "QT",
    14: "3GPP",
    15: "DVRMS",
    16: "MOD",
    17: "ELEMENTARY",
    18: "TIVO",
    19: "AVCHD",
    20: "MKV",
    21: "WTV",
    22: "FLV",
    23: "OGG",
    24: "AGIF",
    25: "FLAC",
    26: "APE",
    27: "ADTS",
    28: "HEVC",
    29: "XAVC",
    30: "MIDI",
    31: "H264_RAW",
    32: "DSF",
    33: "DFF",
    34: "WEBM",
    255: "UNKNOWN",
};

export const VIDEO_CODEC = {
    0: "NONE",
    1: "MPEG1",
    2: "MPEG2",
    3: "MPEG4",
    4: "H264",
    5: "VCONE",
    6: "AVI",
    7: "DV",
    8: "DIVX",
    9: "WM",
    10: "REAL",
    11: "QT",
    12: "FLV",
    16: "MJPEG",
    17: "H263",
    18: "AGIF",
    19: "HEVC",
    20: "MP42",
    21: "PNG",
    22: "VP9",
    255: "UNKNOWN",
};

export const AUDIO_CODEC = {
    0: "NONE",
    1: "DUMMY",
    2: "WAV",
    3: "MPEG1",
    4: "MPEG2",
    5: "MP3",
    6: "WMA",
    7: "AC3",
    8: "PCM",
    9: "LPCM",
    10: "DTS",
    11: "AVI",
    12: "AMR",
    13: "AAC",
    14: "ADPCM",
    32: "REAL",
    16: "TRUEHD",
    48: "VORBIS",
    49: "FLAC",
    50: "SPEEX",
    51: "DDPLUS",
    52: "APE",
    53: "ALAC",
    54: "MIDI",
    55: "DSD",
    56: "OPUS",
    255: "UNKNOWN",
};

export const SAFARI_SUPPORT_CODEC = {
    containerFormat : ["MPEG4", "ADTS"],
    videoCodec : ["H264", "HEVC"],
    audioCodec : ["WAV", "MP3", "AAC", "FLAC"]
};

export const BROWSER_NOT_SUPPORT_CODEC = {
    chrome: {
        // containerFormat : [],
        videoCodec : ["MPEG4", "MP42"],
        audioCodec : ["AC3", "PCM"]
    },
    firefox: {
        // containerFormat : [],
        videoCodec : ["MPEG4", "MP42"],
        audioCodec : ["AC3", "PCM"]
    }
};

export const ORIENTATION_CODEC = {
    1: {rotate: 0, mirror: false},
    2: {rotate: 0, mirror: true},
    8: {rotate: -90, mirror: false},
    5: {rotate: -90, mirror: true},
    3: {rotate: 180, mirror: false},
    4: {rotate: 180, mirror: true},
    6: {rotate: -270, mirror: false},
    7: {rotate: -270, mirror: true},
};

export const BROWSER_SUPPORT_EXTENSION = [".mp4"];

export const IDB_MISC = {
    STORE_TYPE : 'storeType',
    INDEX : 'index',
    DATA_TYPE_SORT : 'dataTypeSort',
    MEDIA_TYPE_SORT : 'mediaTypeSort',
    DATA_TYPE : 'dataType',
    MEDIA_TYPE : 'mediaType',
    COLLECTION_LIST : 'collectionList',
};
export const IDB = {
    [IDB_MISC.STORE_TYPE] : {
        [API_TYPE.MEDIA_SERVER] : 'mediaServer',
        [API_TYPE.CLOUD] : 'cloud'
    },
    [IDB_MISC.INDEX] : {
        [IDB_MISC.DATA_TYPE] : { name: 'dataType', keyParams: ['dataType', '_pcn'] },
        [IDB_MISC.MEDIA_TYPE] : { name: 'mediaType', keyParams: ['mediaType', 'parent', 'pageIndex', '_pcn'] },
        [IDB_MISC.DATA_TYPE_SORT] : { name: 'dtSort', keyParams: ['dataType', 'pageIndex', 'sort', '_pcn'] },
        [IDB_MISC.MEDIA_TYPE_SORT] : { name: 'mtSort', keyParams: ['mediaType', 'parent', 'pageIndex', 'sort', '_pcn'] },
        [IDB_MISC.COLLECTION_LIST] : { name: 'collectionList', keyParams: ['mediaType', 'parent', 'sort', '_pcn'] }
    },
};

export const DATA_HOOK_CONFIG = {
    useParsedCollection: (params) => {
        const { apiType, mediaType, sort } = params;
        return {
            objectStore: IDB[IDB_MISC.STORE_TYPE][apiType],
            index: IDB[IDB_MISC.INDEX][IDB_MISC.DATA_TYPE_SORT].name,
            keyPath: `${DATA_TYPE.COLLECTION}/${mediaType}`.toLowerCase(),
            dataSort: sort ? sort[apiType] !== undefined ? sort[apiType] : "default" : "default"
        }
    },
    useParsedFlatten: (params) => {
        const { apiType, mediaType } = params;
        return {
            objectStore: IDB[IDB_MISC.STORE_TYPE][apiType],
            index: IDB[IDB_MISC.INDEX][IDB_MISC.DATA_TYPE].name,
            keyPath: `${DATA_TYPE.LIST}/${mediaType}`.toLowerCase(),
            dataSort: undefined
        }
    },
    useParsedList: (params) => {
        const { apiType, mediaType, sort } = params;
        return {
            objectStore: IDB[IDB_MISC.STORE_TYPE][apiType],
            index: {
                withParent: IDB[IDB_MISC.INDEX][IDB_MISC.MEDIA_TYPE_SORT].name,
                withoutParent: IDB[IDB_MISC.INDEX][IDB_MISC.DATA_TYPE_SORT].name,
                forMusic: IDB[IDB_MISC.INDEX][IDB_MISC.DATA_TYPE_SORT].name
            },
            keyPath: {
                withParent: mediaType,
                withoutParent: {
                    [API_TYPE.MEDIA_SERVER] : `${DATA_TYPE.COLLECTION}/${mediaType}`.toLowerCase(),
                    [API_TYPE.CLOUD] : `${DATA_TYPE.LIST}/${mediaType}`.toLowerCase()
                },
                forMusic: `${DATA_TYPE.LIST}/${mediaType}`.toLowerCase()
            },
            dataSort: sort ? sort[apiType] !== undefined ? sort[apiType] : "default" : "default"
        }
    },
    useParsedPlay: (params) => {
      const { apiType } = params;
      return {
          objectStore: IDB[IDB_MISC.STORE_TYPE][apiType],
          index: IDB[IDB_MISC.INDEX][IDB_MISC.COLLECTION_LIST].name,
          keyPath: MEDIA_TYPE.Video,
          dataSort: undefined
      }
    },
    useParsedMusic: (params) => {
        const { apiType } = params;
        return {
            objectStore: IDB[IDB_MISC.STORE_TYPE][apiType],
            index: IDB[IDB_MISC.INDEX][IDB_MISC.MEDIA_TYPE].name,
            keyPath: MEDIA_TYPE.Music,
            dataSort: undefined
        }
    }
};

export const DIALOG_CONFIG = {
    pinCodeName: {
        type: "input",
        title: {key: "light-box.pincode.title"},
        text: {key: "light-box.pincode.content-with-name", value: "name"},
        errorMsg: {
            "fail": {key: "light-box.pincode.error"}
        },
    },
    pinCode: {
        type: "input",
        title: {key: "light-box.pincode.title"},
        text: {key: "light-box.pincode.content"},
        errorMsg: {
            "fail": {key: "light-box.pincode.error"}
        },
    },
    expired: {
        type: "message",
        title: {key: "light-box.expire.title"},
        text: {key: "light-box.expire.content"},
    },
    notFound: {
        type: "message",
        title: {key: "light-box.notfound.title"},
        text: {key: "light-box.notfound.content"},
    },
    notSupport: {
        title: {key: "light-box.notsupport.title"},
        text: {key: "light-box.notsupport.content"}
    },
    connectionError: {
        title: {key: "error.internet.connection.title"},
        text: {key: "error.internet.connection.msg"}
    }
};

export const BUILD_TYPE = {
    cpp: "cpp",
    pms: "pms",
    clCloud: "clCloud"
}

export const BUILD = BUILD_TYPE[config_build_type] || BUILD_TYPE.cpp;

export const PAGE_LINKS = {
    CSE_PAGE: "/cse/page/oauth",
    SOCIAL_LOGIN_OPEN: "/prog/member/social-sign-in.do",
    SIGN_UP: "/prog/member/sign-up.do",
};

export const CL_DOMAIN = {
    en_US: "https://www.cyberlink.com",
    de_DE: "https://de.cyberlink.com",
    es_ES: "https://es.cyberlink.com",
    fr_FR: "https://fr.cyberlink.com",
    it_IT: "https://it.cyberlink.com",
    ja_JP: "https://jp.cyberlink.com",
    zh_TW: "https://tw.cyberlink.com",
    zh_CN: "https://cn.cyberlink.com",
    ko_KR: "https://kr.cyberlink.com",
    pt_BR: "https://www.cyberlink.com",
    ru_RU: "https://www.cyberlink.com",
};
