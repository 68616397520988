import api from "./memberApiService";
import dataApi from "../data/dataApiService";
import cseApi from "./cseApiService"
import sessionService from "./sessionService";
import sessionCacheService from "./sessionCacheService";
import {indexedDb} from '../../pages/IndexedDb';
import {init} from "../data/cloudDataService";

export const APIS = {
    LOGIN: "/api/auth/user",
    LINK_DEVICE: "/api/auth/device",
    LINK_DEVICE_BY_TOKEN: "/api/ssi/auth/device",
    LOGOUT: "/api/auth/logout",
    MEDIA_SERVERS: "/api/mediaservers",
    USER_INFO: "/api/users/me",
    USER_TERMS: "/api/users/me/terms",
    USER_TERMS_ACCEPT: "/api/users/me/terms/accepted-date",
    USER_PASSWORD_FORGOT: "/api/users/me/password/forgot",
    TERMS: "/api/terms",
    SSO_LOGIN: "/pdvd/web/sso/login.action",
    SSO_TOKEN: "/pdvd/web/sso/get-token.action",
    USER_PACKAGES: "/api/users/me/packages",
    GET_SOCIAL_LOGIN: "/cse/page/getSocialToken",
    SOCIAL_LOGIN: "/cse/page/isOauthSuccess/?socialToken={socialToken}",
    SOCIAL_LOGIN_INFO: "/cse/service/getMember",
};

export async function signin(username, password, optional = {}) {
    try {
        const result = await api.post(APIS.LOGIN, {...optional, "username": username.trim(), "password": password.trim()});
        if(!result.hasOwnProperty("data")) {
            return result.hasOwnProperty("response") ? result.response : result;
        }
        api.setToken(result.data.access_token);
        api.setTokenType(result.data.token_type);
        dataApi.setToken(result.data.access_token);
        dataApi.setTokenType(result.data.token_type);
        const user = await api.get(APIS.USER_INFO);
        if(!user.hasOwnProperty("data")) {
            return result.hasOwnProperty("response") ? result.response : result;
        }
        return {...result.data, user: user.data};
    }catch(e) {
        return e.response ? e.response.data : e;
    }
}

export async function signinBySSI(access_token, token_type) {
    try {
        api.setToken(access_token);
        api.setTokenType(token_type);
        dataApi.setToken(access_token);
        dataApi.setTokenType(token_type);
        const user = await api.get(APIS.USER_INFO);
        if(!user.hasOwnProperty("data")) {
            return {access_token, token_type};
        }
        return {access_token, token_type, user: user.data};
    }catch(e) {
        return e.response ? e.response.data : e;
    }
}

export async function ssoSignin(token, loginDomain) {
    try {
        if(!dataApi.apiList) {
            await init();  // without token
        }

        const result = await dataApi.post(loginDomain || dataApi.getApiFromKey("sso.login"), {token});
        if(!result.hasOwnProperty("data")) {
            return result.hasOwnProperty("response") ? result.response : result;
        }
        api.setToken(result.data.accessToken);
        api.setTokenType(result.data.tokenType);
        dataApi.setToken(result.data.accessToken);
        dataApi.setTokenType(result.data.tokenType);

        const user = await api.get(APIS.USER_INFO);
        if(!user.hasOwnProperty("data")) {
            return result.hasOwnProperty("response") ? result.response : result;
        }
        return {...result.data, user: user.data};
    }catch(e) {
        return e.response ? e.response.data : e;
    }
}

export async function getSSOToken() {
    try {
        if(!dataApi.apiList) {
            await init(); // with token
        }

        const result = await dataApi.get(dataApi.getApiFromKey("sso.getToken"));
        if(!result.hasOwnProperty("data")) {
            return result.hasOwnProperty("response") ? result.response : result;
        }
        return {...result.data, loginDomain: dataApi.getApiFromKey("sso.login")};
    }catch(e) {
        return e.response ? e.response.data : e;
    }
}

export async function signinApp(username, password, optional = {}) {
    try {
        const result = await api.post(APIS.LOGIN, {...optional, "username": username.trim(), "password": password.trim()});
        if(!result.hasOwnProperty("data")) {
            return result.hasOwnProperty("response") ? result.response : result;
        }

        // For calling init and package api
        api.setToken(result.data.access_token);
        api.setTokenType(result.data.token_type);
        dataApi.setToken(result.data.access_token);
        dataApi.setTokenType(result.data.token_type);
        const result2 = await init();
        return {...result.data, cloudStatus:result2.status};
    }catch(e) {
        return e.response ? e.response.data : e;
    }
}

export async function signinAppBySSI(access_token, token_type) {
    try {
        // For calling init and package api
        api.setToken(access_token);
        api.setTokenType(token_type);
        dataApi.setToken(access_token);
        dataApi.setTokenType(token_type);
        await init();
        return {access_token, token_type};
    }catch(e) {
        return e.response ? e.response.data : e;
    }
}

export async function linkDevice(username, password, code) {
    try {
        return await api.post(APIS.LINK_DEVICE, {"username": username.trim(), "password": password.trim(), "usercode": code});
    }catch(e) {
        return e.response ? e.response.data : e;
    }
}

export async function linkDeviceBySSI(access_token, code) {
    try {
        // sessionService.generateInfo({ access_token: access_token });
        return await api.post(APIS.LINK_DEVICE_BY_TOKEN, {"usercode": code});
    }catch(e) {
        return e.response ? e.response.data : e;
    }
}

export async function logout(options = {redirect: false}) {
    let result;
    if(sessionService.getToken() && sessionService.getTokenType()) {
        try {
            await api.put(APIS.LOGOUT);
            result = {result: "OK"};
        }catch(e) {
            result = e.response ? e.response.data : e;
        }
    }
    indexedDb.deleteDatabase();
    sessionService.destroy();
    sessionCacheService.destroy();
    api.setToken("");
    api.setTokenType("");
    dataApi.setToken("");
    dataApi.setTokenType("");
    if(options.redirect) {
        window.location.href = `${location.protocol === "http:" ? "https" : "http"}://${location.hostname}/signout`;
    }
    return result;
}

export async function getList() {
    try {
        const serverResult = await api.get(APIS.MEDIA_SERVERS);
        if(serverResult.hasOwnProperty("response") && serverResult.response.status !== 200) {
            return [];
        }
        const result = serverResult.data.results;
        await Promise.all(result.map(async r => {
            const deviceResult = await api.get(`${APIS.MEDIA_SERVERS}/${r.uuid}`);
            r.devices = deviceResult.data.results;
        }));
        return result;
    }catch(e) {
        return e.response ? e.response.data : e;
    }
}

export async function getUserTerms() {
    const hasSignIn = !!sessionService.getToken();
    try {
        const userTerms = await api.get(hasSignIn? APIS.USER_TERMS: APIS.TERMS);
        return userTerms.data;
    }catch(e) {
        return e.response ? e.response.data : e;
    }
}

export async function patchAcceptDate() {
    try {
        return await api.patch(APIS.USER_TERMS_ACCEPT);
    }catch(e) {
        return e.response ? e.response.data : e;
    }
}

export async function forgotPassword(email, lang) {
    try {
        return await api.post(APIS.USER_PASSWORD_FORGOT, {"email": email, "lang": lang});
    }catch(e) {
        return e.response ? e.response.data : e;
    }
}

export async function updateUserLocale(lang) {
    try {
        return await api.patch(APIS.USER_INFO, {locale: lang});
    }catch(e) {
        return e.response ? e.response.data : e;
    }
}

export async function getUserInfo() {
    try {
        return await api.get(APIS.USER_INFO);
    }catch(e) {
        return e.response ? e.response.data : e;
    }
}

export async function getUserPackages() {
    try {
        const result = await api.get(APIS.USER_PACKAGES);
        return result.data;
    }catch(e) {
        return e.response ? e.response.data : e;
    }
}

export async function getSocialToken(){
    try {
        const result = await cseApi.get(APIS.GET_SOCIAL_LOGIN);
        if(!result.hasOwnProperty("data")) {
            return result.hasOwnProperty("response") ? result.response : result;
        }
        return {...result.data};
    }catch(e) {
        return e.response ? e.response.data : e;
    }
}

export async function socialLogin(socialToken, ssiDomain){
    try {
        const result = await cseApi.get(APIS.SOCIAL_LOGIN.replace("{socialToken}", socialToken), {
            baseURL: ssiDomain,
        });
        if(!result.hasOwnProperty("data")) {
            return result.hasOwnProperty("response") ? result.response : result;
        }
        return {...result.data};
    }catch(e) {
        return e.response ? e.response.data : e;
    }
}