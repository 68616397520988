import "@babel/polyfill";
import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom'
import configureStore from './utils/redux/configureStore';
import {IntlProvider} from 'react-intl';
import 'intl';
import langsUtils from './utils/langsUtils';
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import themeJs, {FONT_LANG_MAP} from './utils/theme';
import App from './common/App';
import {CssBaseline} from "@material-ui/core";

document.addEventListener('DOMContentLoaded', () => {
    const locale = langsUtils.getLanguage();
    const localeTheme = themeJs;
    localeTheme.typography.fontFamily = FONT_LANG_MAP[locale];
    const theme = createMuiTheme(localeTheme);
    const store = configureStore;
    window.theme = theme;
    window.store = store;
    render((
        <Provider store={store}>
            <IntlProvider locale={locale} messages={langsUtils.loadMessages(locale)}>
                <BrowserRouter>
                    <ThemeProvider theme={theme}>
                        <CssBaseline/>
                        <App />
                    </ThemeProvider>
                </BrowserRouter>
            </IntlProvider>
        </Provider>
    ), document.getElementById('root'));
});
