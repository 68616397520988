import React, {useEffect, useMemo, useRef, useState} from 'react';
import {withRouter} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import logger from "../../utils/logger";
import {Fade, LinearProgress, Slider, Tooltip, withStyles} from "@material-ui/core";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ReactPlayerLite from "../../../react-player/src/play/ReactPlayerLite";
import {DIALOG_CONFIG, MEDIA_TYPE, MUSIC_PLAYER_TYPE} from "../../utils/constants";
import {MUSIC_ACTIONS, MUSIC_LOOP_SEQ, MUSIC_LOOP_TYPE} from "../../services/data/musicReducer";
import {get, htmlDecode} from "../../utils/dataUtils";
import {getConfigByUrl} from "pageConfig";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Duration from "../../../react-player/src/components/Duration";
import LightBox from "../../common/LightBox";
import ImgIconButton from "../../../react-player/src/components/ImgIconButton";

const MusicPlay = props => {
    const {classes} = props;
    const dispatch = useDispatch();
    const player = useRef(null);
    const [volume, setVolume] = useState(0.8);
    const [muted, setMuted] = useState(false);
    const [played, setPlayed] = useState(0);
    const [loaded, setLoaded] = useState(0);
    const [notSupportOpen, setNotSupportOpen] = useState(false);
    const [playDirection, setPlayDirection] = useState("next");
    const mobile = useMediaQuery('(max-width:1024px)');
    const [hideRight, setHideRight] = useState(mobile);
    
    const { current, playing, loop, shuffle, musicHistory, userClick, isNext } =
        useSelector(({ music }) => ({
            current: music.queue[0],
            musicHistory: music.history,
            isNext: music.queue.length !== 1 || (music.list.length !== 1 && (music.loop === 1 || !!music.shuffle)),
            ...music
        }))
    const playerConfig = useMemo(() => {
        return getConfigByUrl(window.location.pathname).musicPlayer || MUSIC_PLAYER_TYPE.DISABLE
    }, [props.location.pathname]);
    const loopType = MUSIC_LOOP_SEQ[loop];
    const repeatType = ["repeat", "repeatAll", "repeatOne"];

    useEffect(() => setHideRight(mobile), [mobile]);
    
    useEffect(() => {
        if (current) {
            const volumeLocal = JSON.parse(localStorage.getItem("volume"));
            const mutedLocal = JSON.parse(localStorage.getItem("muted"));
            setVolume((mutedLocal) ? 0 : (volumeLocal !== null) ? volumeLocal : 0.8);
            setMuted(mutedLocal || false);
            if (!volumeLocal) localStorage.setItem("volume", volume);
            if (!mutedLocal) localStorage.setItem("muted", muted);
        }
    }, [!!current]);

    useEffect(() => {
        if(playerConfig === MUSIC_PLAYER_TYPE.ENABLE) {
            window.addEventListener("keyup", handleHotKey);
        }else {
            window.removeEventListener("keyup", handleHotKey);
        }
        return () => {
            window.removeEventListener("keyup", handleHotKey);
        };
    }, [playerConfig, playing, played, current]);

    // ------------------- ReactPlayerLite Props Config (Properties & Functions) --------------------------
    const reactPlayerProps = {
        ref: player,
        url: current && current.original.url,
        playing: playing,
        loop: loop === MUSIC_LOOP_TYPE.LOOP_ONE,
        volume: volume,
        muted: muted,
        mediaType: MEDIA_TYPE.Music,
        // functions
        onReady, onPlay, onPause, onEnded, onProgress, onDuration, onError,
    };

    function onReady() {
        logger.log('onReady');
        dispatch({type: MUSIC_ACTIONS.READY});
    }

    function onPlay() {
        logger.log('onPlay');
        dispatch({type: MUSIC_ACTIONS.PLAY});
        mediaSession();
    }

    function onPause() {
        logger.log('onPause');
        dispatch({type: MUSIC_ACTIONS.STOP});
    }

    function onEnded() {
        logger.log('onEnded');
        if(loopType === MUSIC_LOOP_TYPE.LOOP_ONE) {
            dispatch({type: MUSIC_ACTIONS.PLAY});
        }else {
            dispatch({type: MUSIC_ACTIONS.NEXT});
            onSeekChange(null, 0);
        }
    }

    function onProgress(state) {
        setLoaded(state.loaded);
        setPlayed(state.played);
    }

    function onDuration(duration) {
        logger.log('onDuration', duration);
        setPlayed(0);
    }

    function onError(e) {
        logger.log('onError', e);
        if (e.constructor.name === "DOMException" && e.message.includes("interact")){
            dispatch({type: MUSIC_ACTIONS.STOP});
            return;
        }
        const errorName = e.target.error.constructor.name;
        const errorCode = e.target.error.code;
        const isNotSupport = errorName==="MediaError" && (errorCode===3 || errorCode===4);
        if (isNotSupport){
            // if user click not support music
            if(current === userClick) {
                setNotSupportOpen(true);
            } else {
                dispatch({type: MUSIC_ACTIONS[playDirection.toUpperCase()]});
                setPlayDirection("next");
            }
        }
    }

    // ------------------- ControlBar Buttons Function --------------------------
    function onSeekChange(e, newValue) {
        setPlayed(newValue);
        player.current.seekTo(newValue);
    }

    function playPrev() {
        // Check if history have song, if no, just back to begining
        if (current.duration * played >= 3 || !musicHistory.length) {
            onSeekChange(null, 0)
        } else {
            dispatch({type: MUSIC_ACTIONS.PREV});
            setPlayDirection("prev");
        }
    }
    function playNext() {
        dispatch({type: MUSIC_ACTIONS.NEXT});
        setPlayDirection("next");
        onSeekChange(null, 0);
    }
    function playPause() {
        dispatch({type: playing ? MUSIC_ACTIONS.STOP : MUSIC_ACTIONS.PLAY});
    }

    function toggleMuted() {
        setMuted(prevState => !prevState);
        localStorage.setItem("muted", !muted);
        // when mute: volume slider become 0
        if (!muted) setVolume(0);
        else setVolume(parseFloat(localStorage.getItem("volume")));
    }
    function setPlayerVolume(e, newValue) {
        setVolume(newValue);
        setMuted(!newValue); //if newValue=0 -> muted(true), if >0 -> un muted(false)
    }
    function storePlayerVolume(e, newValue) {
        // save volume in localStorage
        if (newValue) localStorage.setItem("volume", newValue);
        localStorage.setItem("muted", muted);
    }

    function toggleLoop() {
        dispatch({type: MUSIC_ACTIONS.TOGGLE_LOOP});
    }
    function toggleShuffle() {
        dispatch({type: MUSIC_ACTIONS.TOGGLE_SHUFFLE});
    }

    function exit() {
        dispatch({type: MUSIC_ACTIONS.EXIT});
    }

    // -------------------------- Player Handler -------------------------
    function handleHotKey(e) {
        if ((!playing && !current) || e.target.nodeName==="INPUT") return;

        if(!e.metaKey) {
            switch (e.keyCode) {
                case 32:
                    playPause();
                    break;
                case 37:
                    playPrev();
                    break;
                case 39:
                    playNext();
                    break;
            }
        }
    }

    function handleMouseWheel(e){
        let delta = 0.1;
        if(e.deltaY > 0 && volume >= 0.1){
            setVolume(prevState =>{
                if(prevState-delta < 0.1){
                    setMuted(true);
                    return 0;
                }
                else{
                    setMuted(false);
                    return prevState - delta;
                }
            });
        } else if(e.deltaY < 0 && volume <= 0.9){
            setVolume(prevState => prevState + delta);
            setMuted(false);
        }
    }

    // ------------------------- Ohter Functions --------------------------
    function mediaSession() {
        if ('mediaSession' in navigator) {
            navigator.mediaSession.metadata = new MediaMetadata({
                title: current.title,
                artist: current.artist,
                album: current.albumTitle,
                artwork: [
                    { src: (current.thumbnail && current.thumbnail.url) || "/assets/images/main/music_default_img@2x.png", sizes: '128x128', type: '' },
                ]
            });
            // navigator.mediaSession.setActionHandler('play', function() {});
            // navigator.mediaSession.setActionHandler('pause', function() {});
            // navigator.mediaSession.setActionHandler('seekbackward', function() {});
            // navigator.mediaSession.setActionHandler('seekforward', function() {});
            navigator.mediaSession.setActionHandler('previoustrack', playPrev);
            navigator.mediaSession.setActionHandler('nexttrack', playNext);
        }
    }

    if(!current || playerConfig === MUSIC_PLAYER_TYPE.DISABLE) {
        return null;
    }

    return (
        <Fade in={playerConfig === MUSIC_PLAYER_TYPE.ENABLE}>
            <div id='player-wrapper' data-playertype="Music"
                 className={classes.control} onMouseLeave={() => mobile && setHideRight(true)} >
                <ReactPlayerLite className={classes.player} {...reactPlayerProps} />

                <LinearProgress variant="determinate" value={loaded * 100} className={classes.loadingProgress}/>
                <Slider className={classes.videoSlider} value={played} min={0} max={1} step={0.0001}
                        onChange={onSeekChange} color={"secondary"} />
                <div className={classes.buttons}>
                    <div className={classes.buttonsLeft}>
                        <img src={get(current, "thumbnail.url") || require("../../assets/images/main/music_default_img@2x.png")}
                             className={classes.thumbnail} alt=""/>
                        <div>
                            <Duration seconds={current.duration * played}/> &nbsp;/&nbsp; <Duration seconds={current.duration}/>
                            <div className={`${classes.songTitle} songTitle`}>{htmlDecode(current.title)}</div>
                        </div>
                    </div>
                    <div className={classes.buttonsMiddle}>
                        <ImgIconButton name={repeatType[loop]} onClick={toggleLoop} style={{marginTop:8}} selected={loop} />
                        <ImgIconButton name="prev" onClick={playPrev} />
                        <ImgIconButton name={playing? "pause": "play"} onClick={playPause} style={{width: 70, height: 70}} />
                        <ImgIconButton name="next" onClick={playNext} disabled={!isNext} />
                        <ImgIconButton name={shuffle?"shuffle":"unShuffle"} onClick={toggleShuffle} style={{marginTop:8}} selected={shuffle} />
                    </div>
                    <div className={classes.buttonsRight}>
                        <Fade in={!hideRight}>
                            <div className={classNames(classes.buttonsRightExpand, {[classes.darkBg]: mobile})} >
                                <ImgIconButton name={muted ? "mute" : "unmute"} onClick={toggleMuted} disableHover disablePress />
                                <Slider className={classes.volumeSlider} value={volume} min={0} max={1} step={0.0001}
                                        onChange={setPlayerVolume} onChangeCommitted={storePlayerVolume} onWheel={handleMouseWheel} color={"secondary"} />
                            </div>
                        </Fade>
                        <Fade in={hideRight}>
                            <div className={classes.arrowButton} onMouseEnter={()=>setHideRight(false)}>
                                <ArrowLeftIcon />
                            </div>
                        </Fade>
                        <ImgIconButton name="close" onClick={exit} style={{flexShrink: 0}} />
                    </div>
                </div>
                <LightBox open={notSupportOpen} {...DIALOG_CONFIG.notSupport} handleClose={e => {
                    setNotSupportOpen(false);
                    exit();
                }} />
            </div>
        </Fade>

    )
};

export default withRouter(withStyles(theme => ({
    player: {
        display: 'none'
    },
    control: {
        bottom: 0,
        width: "100%",
        background: "rgba(72,72,72,0.88)",
        // difference
        zIndex: 1200,
        position: "fixed",
    },
    // music: thumbnail, songTitle
    thumbnail: {
        width: "4.6875em",
        height: "4.6875em",
        objectFit: "cover",
        borderRadius: 6,
        margin: "0 1vw",
    },
    songTitle: {
        marginTop: "0.625em",
        fontSize: "1em",  //16
        color: "#A6A6A6",
        ...theme.custom.textLineClamp,
    },
    buttons: {
        color: "#eeeeee",
        display: "flex",
        height: "6.625em",  //106
    },
    buttonsLeft: {
        width: "25%",
        display: "flex",
        alignItems: "center",
        fontSize: "1.125em",  //18
    },
    buttonsMiddle: {
        width: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    buttonsRight: {
        width: "25%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        '& i': {
            width: 44, //2.75em
            height: 44, //2.75em
        }
    },
    buttonsRightExpand: {
        display: "flex",
        alignItems: "center",
        height: "100%",
        paddingLeft: 50,
    },
    darkBg: {
        background: "-webkit-linear-gradient(left, rgba(63, 63, 63, 0) 0%, rgba(63, 63, 63, 0.97) 10%, rgba(63, 63, 63, 0.97) 100%)"
    },
    loadingProgress: {
        backgroundColor: "transparent",
        '& .MuiLinearProgress-bar': {
            backgroundColor: '#7b7b7b',
        }
    },
    videoSlider: {
        position: "absolute",
        top: "-11px",
        height: "6px",
    },
    volumeSlider: {
        width: "11.25em",
        height: "6px",
        marginRight: 10,
    },
    arrowButton: {
        cursor: "pointer",
        padding: "0.3125em",
        "& svg": {
            fontSize: "1.75em",
            display: "block"
        }
    }
}))(MusicPlay));
