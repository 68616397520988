export const SHARE_ACTIONS = {
    SET: `SHARE_ACTIONS_SET`,
    ADD: `SHARE_ACTIONS_ADD`,
    UPDATE: `SHARE_ACTIONS_UPDATE`,
    DELETE: `SHARE_ACTIONS_DELETE`,
};

const initialState = {
    sharedList: [],
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SHARE_ACTIONS.SET:
            return {
                ...state,
                sharedList: action.sharedList
            };
        case SHARE_ACTIONS.ADD:
            return {
                ...state,
                sharedList: [...state.sharedList, action.sharedInfo]
            };
        case SHARE_ACTIONS.UPDATE:
            return {
                ...state,
                sharedList: state.sharedList.map(e => e.filePath === action.path ? action.sharedInfo : e)
            };
        case SHARE_ACTIONS.DELETE:
            return {
                ...state,
                sharedList: state.sharedList.filter(e => e.filePath !== action.path)
            };
        default:
            return state;
    }
}