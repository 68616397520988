import sessionService from "../services/user/sessionService";
import { updateUserLocale } from "../services/user/userService";
import List from "@material-ui/core/List";
import {BUILD, BUILD_TYPE, LANGUAGE_MAP} from "../utils/constants";
import { MenuItem } from "@material-ui/core";
import LightBox from "../common/LightBox";
import React, {useMemo} from "react";
import { useIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";

const LangDialog = props => {
  const { open, handleClose, classes } = props;
  const langCode = useMemo(() => sessionService.getLang(), []);
  const { formatMessage: f } = useIntl();

  async function chooseLanguage(item) {
    sessionService.generateInfo({ lang: item })
    handleClose(false);
    if (BUILD !== BUILD_TYPE.pms) {
      await updateUserLocale(item);
    }
    setTimeout(()=> location.reload(), 200);
  }

  const dialogConfig = {
    type: "message",
    title: f({ id: "header.language-light-box.title" }),
    customContent: (
      <List component="nav" disablePadding>
        {Object.keys(LANGUAGE_MAP).map((item, i) => (
          <MenuItem
            key={item}
            id={`language_${i}`}
            data-item={item}
            button
            disableGutters
            className={classes.langMenuItem}
            selected={langCode === item}
            onClick={() => chooseLanguage(item)}
          >
            {LANGUAGE_MAP[item]}
          </MenuItem>
        ))}
      </List>
    )
  };

  return (
    <LightBox
      open={open}
      handleClose={handleClose}
      {...dialogConfig}
      dialogStyle={{ width: "100%", maxWidth: 500 }}
    />
  );
};

const styles = theme => ({
  langMenuItem: {
    justifyContent: "center",
    borderRadius: 15
  }
});

export default withStyles(styles)(LangDialog);
