import api from "./dataApiService";
import {get} from "../../utils/dataUtils";
const APIS = {
    BROWSE_MEDIA: "/BrowseMedia",
    UPDATE_MEDIA: "/UpdateMedia",
    VERIFY_PINCODE: "/VerifyPincode",
    CLOSE_TRANSCODE: "/CloseTranscode",
    SEARCH_MEDIA: "/SearchMedia",
    SERVER_INFO: "/GetServerInfo",
    UPLOAD_CONNECT_PLATFORM: "/UploadConnectPlatform",
};

export async function ping(serverUrl) {
    try {
        const result = await api.post(`${serverUrl}${APIS.SERVER_INFO}`);
        if(result.hasOwnProperty("data")) {
            return result.data;
        }
        return {};
    }catch(e) {
        return get(e, "response.data") || e;
    }
}

export async function browseMedia(params) {
    try {
        const result = await api.post(APIS.BROWSE_MEDIA, params);
        if(result.hasOwnProperty("data")) {
            return result.data;
        }
        return undefined;
    }catch(e) {
        return get(e, "response.data") || e;
    }
}

export async function verifyPincode(params) {
    try {
        const result = await api.post(APIS.VERIFY_PINCODE, params);
        if(result.hasOwnProperty("data")) {
            return result.data;
        }
        return undefined;
    }catch(e) {
        return get(e, "response.data") || e;
    }
}

export async function updateMedia(params) {
    try {
        const result = await api.post(APIS.UPDATE_MEDIA, params);
        if(result.hasOwnProperty("data")) {
            return result.data;
        }
        return undefined;
    }catch(e) {
        return get(e, "response.data") || e;
    }
}

export async function closeTranscode(params) {
    try {
        const result = await api.post(APIS.CLOSE_TRANSCODE, params);
        if (result.hasOwnProperty("data")) {
            return result.data;
        }
        return undefined;
    } catch (e) {
        return get(e, "response.data") || e;
    }
}

export async function searchMedia(params) {
    try {
        const result = await api.post(APIS.SEARCH_MEDIA, params);
        if (result.hasOwnProperty("data")) {
            return result.data;
        }
    } catch(e) {
        return get(e, "response.data") || e;
    }
}

export async function uploadConnectPlatform() {
    try {
        const result = await api.post(APIS.UPLOAD_CONNECT_PLATFORM, {platfromInfo: "Web"});
        if (result.hasOwnProperty("data")) {
            return result.data;
        }
    } catch(e) {
        return get(e, "response.data") || e;
    }
}
