import React, {useState} from 'react';
import {Redirect} from 'react-router';
import {injectIntl, FormattedMessage} from "react-intl";
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline/index';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    container: {
        height: "100vh"
    },
    flex: {
        display: "flex",
        flexDirection: "column",
        background: "#292929",
        padding: "16px"
    },
    content: {
        margin: "16px 0"
    },
    buttonGroup: {
        alignSelf: "flex-end",
        marginTop: theme.spacing(1),
    },
    firstButton: {
        color: "white",
        marginRight: theme.spacing(2),
        padding: theme.spacing(1),
        backgroundColor: "#585858",
        "&:hover" : {
            backgroundColor: "#404040",
        },
    },
    secondButton: {
        color: "white",
        padding: theme.spacing(1),
    },
});

// @injectIntl
class Error extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            goSignIn: false
        };
    }

    goSignIn = () => {
        this.props.clientLogout();
        this.setState({goSignIn: true});
    };

    setClientId = ()=>{
        const tracker = ga.getAll()[0];
        const clientId = tracker.get('clientId');
        this.setState({clientId});
    };

    componentDidMount() {
        try {
            this.setClientId()
        } catch (e) {
            console.log(e);
            window.addEventListener('load', this.setClientId);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('load', this.setClientId);
    }

    render() {
        const {classes} = this.props;
        if(this.state.goSignIn) {
            return <Redirect to={{pathname: '/signin', state: { from: this.props.location }}} />
        }
        if (!this.state.clientId) return <div />;
        return (
            <div>
                <CssBaseline/>
                <Container maxWidth="xl">
                    <Grid container className={classes.container} spacing={3} alignItems={"center"} justify={"center"}>
                        <Grid item xs={12} sm={8} md={8}>
                            <Paper className={classes.flex}>
                                <Typography variant="h5" component="h3">
                                    <FormattedMessage id={"error.oops"}/>
                                </Typography>
                                <Typography component="p" className={classes.content}>
                                    {<FormattedMessage id={"error.msg-front"}/>}<br/>{<FormattedMessage id={"error.msg-back"}/>}
                                </Typography>
                                <Typography component="p" style={{height: 24}}>
                                    series: {this.state.clientId}
                                </Typography>
                                <div className={classes.buttonGroup}>
                                    <Button className={classes.firstButton} color="primary" variant="contained" type="button"
                                            onClick={() => window.location.reload()}><FormattedMessage id={"error.refresh"}/></Button>
                                    <Button className={classes.secondButton} color="secondary" variant="contained" type="button"
                                            onClick={() => window.location.href = "https://www.cyberlink.com/support/contact-support.jsp"}><FormattedMessage id={"error.contact"}/></Button>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
    }
}
export default withStyles(styles)(Error);
