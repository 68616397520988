import config from "config";
import logger from "logger";
import sessionService from "./sessionService";
import axios from 'axios';
import {APIS, logout} from "./userService";
import qs from "query-string";

const singleton = Symbol();
const singletonEnforcer = Symbol();

class MemberApiService {
    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) {
            throw new Error('Cannot construct singleton');
        }
        this.token = sessionService.getToken();
        this.tokenType = sessionService.getTokenType();
        this.session = axios.create({
            baseURL: config.MEMBER_API_HOST
        });
        this.session.interceptors.request.use(request => this.requestHandler(request), error => this.errorHandler(error));
        this.session.interceptors.response.use(response => this.responseHandler(response),error => this.errorHandler(error));
    }

    requestHandler(request) {
        if(!this.token && sessionService.getToken()) {
            this.setToken(sessionService.getToken());
            this.setTokenType(sessionService.getTokenType());
        }
        if(this.token) {
            request.headers['authorization'] = `${this.tokenType} ${this.token}`;
        }
        if(request.url === APIS.LINK_DEVICE_BY_TOKEN){
            request.headers["Content-Type"] = "application/x-www-form-urlencoded";
            request.data = qs.stringify(request.data);
        }
        return request;
    }

    responseHandler(response) {
        return response;
    }

    errorHandler(error) {
        if(error.hasOwnProperty("response")) {
            const filteredResponse = {...error.response.data};
            delete filteredResponse.timestamp;
            // filter out "incorrect username and password" cases? (error message)
            dataLayer.push({"event": "apiError", "errorPath": error.config.url, "errorData": JSON.stringify(filteredResponse)});
            if(error.response.config.url.indexOf("terms") >= 0) {
                return error;
            }
            if(error.response.config.url.indexOf("/api/auth/user") >= 0) {
                return error;
            }
            if(error.response.status === 401 || error.response.status === 403) {
                logout({redirect: true});
            }
        }
        return error;
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new MemberApiService(singletonEnforcer);
        }
        return this[singleton];
    }
    setBaseUrl = url => this.session.defaults.baseURL = url;
    setToken = token => this.token = token;
    setTokenType = tokenType => this.tokenType = tokenType;
    get = (...request) => this.session.get(...request);
    post = (...request) => this.session.post(...request);
    put = (...request) => this.session.put(...request);
    patch = (...request) => this.session.patch(...request);
}

export default MemberApiService.instance;
