import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {withStyles} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import SubmitButton from "../components/SubmitButton";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import PincodeInput from "../components/PincodeInput";

export const UNLOCK_STATUS = {INIT: "UNLOCK_STATUS_INIT", UNLOCKING: "UNLOCK_STATUS_UNLOCKING", SUCCESS: "UNLOCK_STATUS_SUCCESS", FAIL: "UNLOCK_STATUS_FAIL"};

const LightBox = props => {
    const {formatMessage: f} = useIntl();
    const {classes, dialogStyle, open, checkPinCode, handleClose, unlockStatus, type, title, text, customContent, actions} = props;
    const [pincode, setPinCode] = useState(undefined);
    const [message, setMessage] = useState(null);
    const hasInput = (type==="input");

    useEffect(() => {
        if (pincode && pincode.length === 4) checkPinCode(pincode);
    }, [pincode]);

    useEffect(() => {
        if(unlockStatus === UNLOCK_STATUS.FAIL) {
            setPinCode(undefined);
            setMessage(getWording(props.errorMsg.fail));
        }
    }, [unlockStatus]);

    useEffect(() => {
        if(open) {
            setPinCode(undefined);
            setMessage(null);
        }
    }, [open]);

    function handleSubmit(e) {
        if (!hasInput || !pincode) return;
        e.preventDefault();
        checkPinCode(pincode);
    }

    const getWording = (config) => {
        if (!config) return ;
        const value = props.values ? {[config.value]: props.values[config.value]}: undefined;

        return config.hasOwnProperty("key") ? f({id: config.key}, value) : config.string || config;  //  || config
    };

    return(
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" PaperProps={{style:dialogStyle}}>
            <form onSubmit={handleSubmit} className={classes.root} id="form">
                <DialogTitle id="form-dialog-title" className={classes.title} disableTypography>
                    {getWording(title)}
                </DialogTitle>
                <DialogContent className={`${classes.content} input`}>
                    {text && <DialogContentText> {getWording(text)} </DialogContentText>}

                    {hasInput && <PincodeInput num={4} pincode={pincode} setPinCode={setPinCode} />}
                    {message && <div className={classes.errorMsg}> {message} </div>}
                    {customContent}
                </DialogContent>
                <DialogActions className={classes.action}>
                    { (type !=="message" && !hasInput && handleClose) &&
                        <Button variant="contained" className={classes.button} onClick={handleClose} >{f({id: "light-box.btn-close"})}</Button>
                    }

                    {actions && actions.map((action, i) => (
                        (action.type === "submit")?
                            <SubmitButton key={action.name} title={{key: action.display.key}} timerShouldHide={true} className={classes[action.className]} />:
                            <Button key={action.name} {...action.buttonProps} onClick={action.onClick} className={classes[action.className]}> {getWording(action.display)} </Button>
                    ))}

                    {/*{hasInput?*/}
                    {/*    <SubmitButton title={{string: f({id: "public.btn-unlock"})}} timerShouldHide={!(unlockStatus === UNLOCK_STATUS.UNLOCKING)} className={`${classes.button} unlock`}/>:*/}
                    {/*    (type!=="message")?<Button variant="contained" className={classes.button} onClick={handleClose} style={{margin: "auto"}}>{f({id: "light-box.btn-close"})}</Button>: null*/}
                    {/*}*/}
                </DialogActions>

                {handleClose &&
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                }
            </form>
        </Dialog>
    );
};

export default withStyles(theme => ({
    title: {
        backgroundColor: "#848383",
        padding: "40px 32px 18px",
        borderBottom: "1px solid #989898",
        fontSize: "18px",
        textAlign: "center",
    },
    content: {
        padding: "28px 32px",
    },
    errorMsg: {
        fontSize: "12px",
        color: "#ffff80",
        // height: 17,
    },
    action: {
        padding: "0 32px 24px",
        justifyContent: "center",
        "& button": {
            fontSize: "14px",
            boxShadow: "none",
            minWidth: 100,
        }
    },
    button: {
        border: "1px solid #FFF",
        backgroundColor: "#848383",
        '&:hover': {
            color: "black",
        },
        whiteSpace: "nowrap"
    },
    signinBtn: {
        fontSize: "14px",
        minWidth: 100,
        boxShadow: "none",
        paddingTop: 5,
        paddingBottom: 5,
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}))(LightBox);
