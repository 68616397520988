import React from 'react';
import Loadable from "react-loadable";
import Loading from "../../common/Loading"
import config from "config";
import {MUSIC_PLAYER_TYPE, MEDIA_TYPE} from "../../utils/constants";

// 1. Remove un use path / 2. https: false / 3. requireSignin: false
const pageConfig = [
    {
        key: "home",
        link: `${config.URL_PREFIX}/home`,
        regex: `${config.URL_PREFIX}/home`,
        config: {
            requireSignin: false,
            protocal: {https: false, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {
                title: {string: "Home"},
            },
            sidebar: {
                // hide: true
                title: {key: "general.home-pre"},
                icon: "btn_home",
                activatePattern: "home",
                mobile: true,
            }
        },
        component: Loadable({
            loader: () => import('../home/Home'),
            loading: Loading,
        }),
    },
    {
        key: "movie",
        link: `${config.URL_PREFIX}/movies`,
        regex: `${config.URL_PREFIX}/movies`,
        config: {
            requireSignin: false,
            protocal: {https: false, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {},
            sidebar: {
                title: {key: "general.movies"},
                icon: "icon_movies",
                activatePattern: MEDIA_TYPE.Movie
            }
        },
        component: Loadable({
            // loader: () => import('./movies/MovieCollection'),
            loader: () => import('../../components/ContentCollection'),
            loading: Loading,
        }),
    },
    {
        key: "tvshows",
        link: `${config.URL_PREFIX}/tvshows`,
        regex: `${config.URL_PREFIX}/tvshows`,
        config: {
            requireSignin: false,
            protocal: {https: false, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {},
            sidebar: {
                title: {key: "general.tv-shows"},
                icon: "icon_TV",
                activatePattern: MEDIA_TYPE.TV
            }
        },
        component: Loadable({
            // loader: () => import('./tvshows/TvCollection'),
            loader: () => import('../../components/ContentCollection'),
            loading: Loading
        }),
    },
    {
        key: "video",
        link: `${config.URL_PREFIX}/videos`,
        regex: `${config.URL_PREFIX}/videos`,
        config: {
            requireSignin: false,
            protocal: {https: false, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {
                title: {string: "Video"},
            },
            sidebar: {
                title: {key: "general.videos"},
                icon: "icon_video",
                activatePattern: MEDIA_TYPE.Video
            }
        },
        component: Loadable({
            // loader: () => import('./videos/VideoCollection'),
            loader: () => import('../../components/ContentCollection'),
            loading: Loading,
        }),
    },
    {
        key: "photo",
        link: `${config.URL_PREFIX}/photos`,
        regex: `${config.URL_PREFIX}/photos`,
        config: {
            requireSignin: false,
            protocal: {https: false, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {
                title: {string: "Photo"},
            },
            sidebar: {
                title: {key: "general.photos"},
                icon: "icon_photo",
                activatePattern: MEDIA_TYPE.Photo
            }
        },
        component: Loadable({
            // loader: () => import('./photos/PhotoCollection'),
            loader: () => import('../../components/ContentCollection'),
            loading: Loading,
        }),
    },
    {
        key: "photoPlay",
        link: `${config.URL_PREFIX}/play/PDVD/Photo/`,
        regex: `${config.URL_PREFIX}/play/PDVD/Photo/`,
        config: {
            requireSignin: false,
            protocal: {https: false, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.BACKGROUND,
        },
        component: Loadable({
            loader: () => import('../photos/PhotoGallery'),
            loading: Loading,
        }),
    },
    {
        key: "photoList",
        link: `${config.URL_PREFIX}/PDVD/Photo/`,
        regex: `${config.URL_PREFIX}/PDVD/Photo/`,
        config: {
            requireSignin: false,
            protocal: {https: false, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {
                title: {string: "Photo"},
            },
            sidebar: {
                hide: true,
            }
        },
        component: Loadable({
            loader: () => import('../photos/PhotoGrid'),
            loading: Loading,
        }),
    },
    {
        key: "music",
        link: `${config.URL_PREFIX}/music`,
        regex: `${config.URL_PREFIX}/music`,
        config: {
            requireSignin: false,
            protocal: {https: false, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {
                title: {string: "Music"},
            },
            sidebar: {
                title: {key: "general.music"},
                icon: "icon_music",
                activatePattern: MEDIA_TYPE.Music
            }
        },
        component: Loadable({
            // loader: () => import('./musics/MusicCollection'),
            loader: () => import('../../components/ContentCollection'),
            loading: Loading,
        }),
    },
    {
        key: "musicAlbumMedia",
        link: [`${config.URL_PREFIX}/PDVD/Music/Album/`, `${config.URL_PREFIX}/PDVD/Music/All/`],
        regex: [`${config.URL_PREFIX}/PDVD/Music/Album/`, `${config.URL_PREFIX}/PDVD/Music/All/`],
        config: {
            requireSignin: false,
            protocal: {https: false, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {
                title: {string: "Music"},
            },
            sidebar: {
                hide: true
            }
        },
        component: Loadable({
            loader: () => import('../musics/MusicDetail'),
            loading: Loading,
        }),
    },
    {
        key: "musicAlbumCloud",
        link: [`${config.URL_PREFIX}/PDVD/Music/:artist/:album/:song`, `${config.URL_PREFIX}/PDVD/Music/:artist/:album`],
        regex: [`${config.URL_PREFIX}/PDVD/Music/.*/.*/.*`, `${config.URL_PREFIX}/PDVD/Music/.*/.*`],
        config: {
            requireSignin: false,
            protocal: {https: false, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {
                title: {string: "Music"},
            },
            sidebar: {
                hide: true
            }
        },
        component: Loadable({
            loader: () => import('../musics/MusicDetail'),
            loading: Loading,
        }),
    },
    {
        key: "play",
        link: `${config.URL_PREFIX}/play/PDVD/:mediaType/`,
        regex: `${config.URL_PREFIX}/play/PDVD/.*/.*`,
        config: {
            requireSignin: false,
            protocal: {https: false, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.DISABLE,
        },
        component: Loadable({
            loader: () => import('../play/Play'),
            loading: Loading,
        }),
    },
    {
        key: "search",
        link: `${config.URL_PREFIX}/PDVD/Search/:items`,
        regex: `${config.URL_PREFIX}/PDVD/Search/.*`,
        config: {
            requireSignin: false,
            protocal: {https: false, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {
                title: {string: "Search"},
            },
            sidebar: {
                hide: true
            }
        },
        component: Loadable({
            loader: () => import('../media/Search'),
            loading: Loading,
        }),
    },
    {
        key: "PDVD",
        link: [`${config.URL_PREFIX}/PDVD/.collection/:mediaType/`, `${config.URL_PREFIX}/PDVD/:mediaType/`],
        regex: `${config.URL_PREFIX}/PDVD/.*`,
        config: {
            requireSignin: false,
            protocal: {https: false, http: true},
            musicPlayer: MUSIC_PLAYER_TYPE.ENABLE,
            header: {
                title: {string: "browse"},
            },
            sidebar: {
                hide: true
            }
        },
        component: Loadable({
            loader: () => import('../media/BrowseMedia'),
            loading: Loading,
        }),
    }
];

export default pageConfig;
